import { directoryFilter } from '../types'

const initialState = {
  contractors: [],
  filters: {
    search: '',
  },
  params: {
    page: 1,
    perPage: 10,
  },
  total: 0,
  loading: {
    getHistory: false,
  },
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case directoryFilter.SET_DROPDOWN_OPTIONS: {
      const { key, data, page } = action.payload
      if (!key) return state
      return {
        ...state,
        [key]: page === 1 ? data : [...state[key], ...data],
      }
    }

    case directoryFilter.SET_FILTERS: {
      const { key, data } = action.payload
      if (key) {
        return {
          ...state,
          filters: { ...state.filters, [key]: data },
          params: { ...state.params, page: 1 },
        }
      } else {
        return {
          ...state,
          filters: { ...state.filters, ...data },
          params: { ...state.params, page: 1 },
        }
      }
    }

    case directoryFilter.APPLY_SAVED_FILTER: {
      return {
        ...state,
        filters: action.payload,
        params: { ...state.params, page: 1 },
      }
    }

    case directoryFilter.RESET_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      }
    }

    case directoryFilter.SET_TABLE_PARAMS: {
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      }
    }

    case directoryFilter.RESET: {
      return initialState
    }

    case directoryFilter.RESET_ALL: {
      return initialState
    }

    default: {
      return state
    }
  }
}
export default reducer
