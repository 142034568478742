import { eventTypes } from '../types'
import { CONSTANTS } from 'helpers/const'

const initialAuthState = {
  events: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  filters: {
    type: 'all',
    created_by: '',
    speciality_ids: [],
    event_type: '',
    start_date_time: '',
    end_date_time: '',
    mine: false,
  },
  specialities: [],
  searchDropDownUsers: [],
  modal: {
    createEvent: false,
    shareEvent: false,
    inviteEvent: false,
    groupSelect: false,
  },
  users: {
    count: 0,
    rows: [],
    totalPages: 0,
    totalRequests: 0,
  },
  attendees: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  inviteUsers: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  inviteSuggestion: [],
}

const getPayload = (payload, value) => {
  return {
    count: payload?.count || 0,
    rows:
      payload.page === 1
        ? [...payload?.rows]
        : [...value.rows, ...payload?.rows],
    totalPages: payload?.totalPages || 0,
  }
}

const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case eventTypes.SET_EVENTS: {
      return { ...state, events: getPayload(action?.payload, state.events) }
    }
    case eventTypes.SET_FILTERS: {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    }
    case eventTypes.RESET_EVENTS: {
      return { ...state, events: initialAuthState.events }
    }
    case eventTypes.SET_MODAL: {
      const modal = {}
      modal[action.payload.modal] = JSON.parse(
        JSON.stringify(action.payload.value)
      )
      return { ...state, modal: { ...state.modal, ...modal } }
    }
    case eventTypes.SET_ATTENDEE: {
      return {
        ...state,
        attendees: getPayload(action?.payload, state.attendees),
      }
    }
    case eventTypes.SET_INVITE_USERS: {
      return {
        ...state,
        inviteUsers: getPayload(action?.payload, state.inviteUsers),
      }
    }
    case eventTypes.RESET_INVITE_USERS: {
      return {
        ...state,
        inviteUsers: initialAuthState.inviteUsers,
      }
    }
    case eventTypes.INVITATION_SENT: {
      return {
        ...state,
        inviteSuggestion: state.inviteSuggestion.map((v) => {
          if (action.payload.includes(v?.id)) {
            return { ...v, invitationSent: true }
          }
          return v
        }),
      }
    }
    case eventTypes.TOGGLE_ATTENDING: {
      const {
        events: { rows },
      } = state
      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.map((v) =>
            v?.id === action.payload.event_id
              ? {
                  ...v,
                  is_attending:
                    action.payload.status === CONSTANTS.EVENT_STATUS.ATTENDING
                      ? !v?.is_attending
                      : v?.is_attending,
                  attendees_count:
                    action.payload.status === CONSTANTS.EVENT_STATUS.ATTENDING
                      ? +v?.attendees_count + 1
                      : v?.attendees_count,
                  attending_status: action.payload.status,
                  attendees:
                    action.payload.status === CONSTANTS.EVENT_STATUS.ATTENDING
                      ? [...v?.attendees, action.payload.user]
                      : v?.attendees,
                }
              : v
          ),
        },
      }
    }
    case eventTypes.LEAVE_EVENT: {
      const {
        events: { rows },
      } = state
      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.map((v) =>
            v?.id === action.payload.event_id
              ? {
                  ...v,
                  is_attending: false,
                  attending_status: null,
                  attendees_count:
                    v?.visibility === CONSTANTS.EVENT_VISIBLITY.PUBLIC
                      ? v?.attendees_count - 1
                      : v?.attendees_count,
                  attendees: v?.attendees.filter(
                    (val) => val?.id !== action?.payload?.user?.id
                  ),
                }
              : v
          ),
        },
      }
    }
    case eventTypes.EDIT_EVENT: {
      return {
        ...state,
        events: {
          ...state.events,
          rows: state.events.rows.map((v) =>
            v?.id === action?.payload?.id ? action?.payload : v
          ),
        },
      }
    }
    case eventTypes.DELETE_EVENT: {
      const {
        events: { rows },
      } = state

      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.filter((e) => e.id !== action.payload.id),
        },
      }
    }
    case eventTypes.CANCEL_EVENT: {
      const {
        events: { rows },
      } = state
      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.map((v) =>
            v?.id === action.payload ? { ...v, status: 'cancelled' } : v
          ),
        },
      }
    }
    case eventTypes.TOGGLE_INTRESTED: {
      const {
        events: { rows },
      } = state
      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.map((v) =>
            v?.id === action.payload
              ? {
                  ...v,
                  is_interested: !v?.is_interested,
                  intrested_count: v?.is_interested
                    ? v?.intrested_count - 1
                    : v?.intrested_count + 1,
                }
              : v
          ),
        },
      }
    }
    case eventTypes.SET_USERS: {
      return {
        ...state,
        users: {
          ...getPayload(action?.payload, state?.users),
          totalRequests: action?.payload?.totalRequests
            ? action?.payload?.totalRequests
            : state?.users?.totalRequests,
        },
      }
    }
    case eventTypes.RESET_USERS: {
      return { ...state, users: initialAuthState.users }
    }
    case eventTypes.REMOVE_USER: {
      return {
        ...state,
        users: {
          ...state.users,
          count: state.users.count - 1,
          rows: state.users.rows.filter(
            (e) => e.attendee.id !== action.payload.id
          ),
          totalRequests:
            action.payload.tab === CONSTANTS.EVENT_STATUS.REQUESTED
              ? state.users.totalRequests - 1
              : state.users.totalRequests,
        },
      }
    }
    case eventTypes.TOGGLE_FOLLOWER: {
      const p = action.payload
      const {
        events: { rows },
      } = state
      return {
        ...state,
        events: {
          ...state.events,
          rows: rows.map((v) => {
            return {
              ...v,
              speakers: v?.speakers.map((i) =>
                i.id === p.id ? { ...i, ...{ is_following: p.status } } : i
              ),
            }
          }),
        },
        attendees: {
          ...state.attendees,
          rows: state.attendees.rows.map((i) =>
            i.attendee.id === p.id ? { ...i, ...{ is_following: p.status } } : i
          ),
        },
        users: {
          ...state.users,
          rows: state.users.rows.map((i) =>
            i.attendee.id === p.id ? { ...i, ...{ is_following: p.status } } : i
          ),
        },
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
