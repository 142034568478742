import { forgotpass } from '../types'

const initialState = {
  email: '',
  code: '',
  otpMessage: '',
  loading: {
    sendCode: false,
    verifyCode: false,
    changePass: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case forgotpass.SETEMAIL: {
      const { email } = action.payload
      return { ...state, email }
    }
    case forgotpass.SETCODE: {
      const { code } = action.payload
      return { ...state, code, otpMessage: '' }
    }
    case forgotpass.SETERROR: {
      const { otpMessage } = action.payload
      return { ...state, otpMessage }
    }
    case forgotpass.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case forgotpass.RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
