import { get, post } from 'api'
import { globalSearch, postType } from "store/types";
// import { search, speciality, company, network, feed } from "helpers/api";
import { removeEmpty } from "helpers/auth";

export const setFilter = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: globalSearch.SET_FILTER,
      payload: filter,
    });
  } catch (e) {
    throw e;
  }
};

export const resetFilter = (onlyFilter) => async (dispatch) => {
  try {
    dispatch({
      type: globalSearch.RESET_FILTER,
      payload: onlyFilter,
    });
  } catch (e) {
    throw e;
  }
};

export const setTabs = (tab) => async (dispatch) => {
  dispatch({
    type: globalSearch.SET_TAB,
    payload: tab,
  });
};

export const getLastSearch = () => async (dispatch) => {
  const {
    data: { data },
  } = await get({
    subUrl: '/search/recent-search'
  });
  dispatch({
    type: globalSearch.SET_SEARCH_DROP_DOWN_RECENT_SEARCH,
    payload: data?.search || [],
  });
};

export const clearLastSearch = () => async (dispatch) => {
  await post({
    subUrl: '/search/remove-recent-search',
    data: { deleteAll: true }
  });

  dispatch({
    type: globalSearch.CLEAR_SEARCH_DROP_DOWN_RECENT_SEARCH,
  });
};

export const getSuggestion = (searchKey) => async (dispatch) => {
  const {
    data: { data },
  } = await post({
    subUrl: '/search/people',
    data: {
      search: searchKey,
      page: 1,
      globalSearch: true,
    }
  });

  dispatch({
    type: globalSearch.SET_SEARCH_DROP_DOWN_USER,
    payload: data?.rows || [],
  });
  return data;
};

export const getSpeciality = () => async (dispatch) => {
  // try {
  //   const {
  //     data: { data },
  //   } = await get({
  //     subUrl: '/speciality.GET_ALL'
  //   });
  //   dispatch({
  //     type: globalSearch.SET_SPECIALITY,
  //     payload: {
  //       specialities: data,
  //     },
  //   });
  //   return true;
  // } catch (e) {
  //   throw e;
  // }
};

// export const getCompanyType = () => async (dispatch) => {
//   try {
//     const {
//       data: { data },
//     } = await get(company.GET_ALL_COMPANY_TYPES);
//     dispatch({
//       type: globalSearch.SET_COMPANY_TYPE,
//       payload: {
//         companyType: data,
//       },
//     });
//     return true;
//   } catch (e) {
//     throw e;
//   }
// };

export const getPeople =
  (page = 1, filter) =>
  async (dispatch) => {
    try {
      const newFilter = removeEmpty({ ...filter });
      const {
        data: { data },
      } = await post({
        subUrl: '/search/people',
        data: {
          ...{ page, globalSearch: true },
          ...newFilter,
        }
      });
      dispatch({
        type: globalSearch.SET_PEOPLE,
        payload: { ...data, ...{ page } },
      });
      return true;
    } catch (e) {
      throw e;
    }
  };

export const toggleFollower = (followerId, status) => async (dispatch) => {
  try {
    if (status === true) await post({
      subUrl: '/network/add-follower',
      data: { followerId }
    });
    else await post({
      subUrl: '/network/unfollow',
      data: { userId: followerId }
    });

    dispatch({
      type: globalSearch.TOGGLE_FOLLOWER,
      payload: {
        id: followerId,
        status,
      },
    });
    return true;
  } catch (e) {
    throw e;
  }
};

export const getMutualConnection =
  (page = 1, userId) =>
  async (dispatch) => {
    try {
      const {
        data: { data },
      } = await get({
        subUrl: '/search/mutual-connection',
        data: {
          params: { page, userId },
        }
      });
      dispatch({
        type: globalSearch.SET_MUTUAL_CONNECTION,
        payload: { ...data, ...{ page } },
      });
      return true;
    } catch (e) {
      throw e;
    }
  };

export const getContent =
  (page = 1, filters) =>
  async (dispatch) => {
    try {
      let newFilter = removeEmpty({ ...filters });

      const {
        data: { data },
      } = await post({
        subUrl: '/search/content',
        data: {
          ...{ page, globalSearch: true },
          ...newFilter,
        }
      });
      dispatch({
        type: postType.SET_POSTS,
        payload: { ...data, ...{ page } },
      });
      return true;
    } catch (e) {
      throw e;
    }
  };

export const getPostType = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await get({
      subUrl: '/post/post-type'
    });
    dispatch({
      type: globalSearch.SET_POST_TYPES,
      payload: data,
    });
  } catch (e) {
    throw e;
  }
};
