import { Modal } from 'antd'
import { connect } from 'react-redux'
import warning from '../../assets/images/warning.svg'
import { isAdmin } from 'helpers/common'
import { setSubscriptionPrompt } from 'store/action/company'
import './ModalSubscriptionPrompt.scss'
import { history, routes } from 'routes/config'

const ModalSubscriptionPrompt = ({ modal, setSubscriptionPrompt, user }) => {
  const onOk = () => {
    setSubscriptionPrompt({
      visible: false,
    })
    history.push({
      pathname: routes.pricing.path,
      search: '?upgrade=true',
    })
  }
  return (
    <Modal
      width='540px'
      className='ModalSubscriptionPrompt'
      visible={modal.visible}
      onOk={onOk}
      okText='Upgrade subscription'
      okButtonProps={{
        style: {
          display: isAdmin(user) ? 'block' : 'none'
        }
      }}
      onCancel={() => {
        setSubscriptionPrompt({
          visible: false,
        })
      }}
      cancelText='Go back'
      closable={false}
    >
      <div className='modalpopup'>
        <div className='modalimage'>
          <img src={warning} alt='' title='' />
        </div>
        <h2>{modal.title}</h2>
        <p>{modal.message}</p>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ company, auth }) => ({
  modal: company.subscriptionPrompt,
  user: auth.user
})

export default connect(mapStateToProps, {
  setSubscriptionPrompt,
})(ModalSubscriptionPrompt)
