import React from 'react'
import { Modal, Form, Select, Input } from 'antd'
import { connect } from 'react-redux'
import { MODULE_NAMES } from 'helpers/const'
import { openSupportModal, submitSupportRequest } from 'store/action/support'

const ModalSupportRequest = ({
  visible,
  openSupportModal,
  submitSupportRequest,
  loading
}) => {
  const [form] = Form.useForm()
  form.setFieldsValue({
    moduleName: 'homepage'
  })
  const submitForm = async (values) => {
    await submitSupportRequest(values)
    openSupportModal(false)
  }
  return (
    <Modal
      visible={visible}
      confirmLoading={loading}
      title="Open Support Ticket"
      onCancel={() => {
        openSupportModal(false)
        form.resetFields()
      }}
      onOk={form.submit}
      okText="Submit"
      okType="submit">
      <Form form={form} onFinish={submitForm}>
        <Form.Item
          label='Support Topic'
          name='moduleName'>
          <Select>
            {
              Object.keys(MODULE_NAMES || {}).map(key => (
                <Select.Option value={key} key={key}>{MODULE_NAMES[key]}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          label='Comment'
          name='comment'
          rules={[
            {
              required: true,
              message: 'Please add a comment',
            },
          ]}>
          <Input.TextArea rows={4}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapStateToProps = ({ support }) => ({
  visible: support.modalIsVisible,
  loading: support.loading
})

export default connect(mapStateToProps, {
  openSupportModal,
  submitSupportRequest
})(ModalSupportRequest)
