import React from 'react'

import { images } from 'config/images'
import { routes } from 'routes/config'
import { Link } from 'react-router-dom'
import { getProfileImageUrl } from 'helpers/common'

import './Followerrow.scss'

function UserRow({ id, name, text, profile_image, noImage, onClick }) {
  const getUser = () => (
    <div className='user-info'>
      {!noImage && (
        <div className='user-image'>
          <img
            src={getProfileImageUrl(profile_image) || images.avatar}
            alt='user'
          />
        </div>
      )}
      <div className='userinfo-row'>
        <div className='user-content'>
          <div className='user-name'>{name}</div>
          <span className='user-text'>{text}</span>
        </div>
      </div>
    </div>
  )
  return (
    <div className='UserrowStyles' onClick={onClick}>
      {id ? (
        <Link to={routes.userProfile.path_string(id)}>{getUser()}</Link>
      ) : (
        getUser()
      )}
    </div>
  )
}

export default UserRow
