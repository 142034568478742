import React from 'react'

import './Icontooltipinfo.scss'

function Icontooltipinfo() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#53C3BD'>
          <g>
            <g>
              <path
                d='M12 4c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 2c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm1.004 4.998v4h-2.001v-4h2zm-1.004-3c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-.552.448-1 1-1z'
                transform='translate(-789 -254) translate(75 229) translate(714 25)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Icontooltipinfo
