import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import moment from 'moment'
import copyToClipboard from 'copy-to-clipboard'
import imageCompression from 'browser-image-compression'

import { message } from './message'
import { routes } from 'routes/config'
import { Listview, Boardview, TabularviewIcon } from 'config/Icons'


import {
  apiDateFormat,
  bidDatesTimeFormat,
  bidDatesFormat,
  eventAWSBucket,
  commonFileTypes,
  communityAWSBucket,
} from './const'
import {
  crmBucketUrl,
  resourcesBucketUrl,
  attachmentBucketUrl,
  profileImageAWSBucketUrl,
} from 'config/env'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

dayjs.extend(utc);
dayjs.extend(timezone);

export const copyText = (text) => {
  return copyToClipboard(text)
}

export const truncateText = (text, char = 20) => {
  if (!text) {
    return text
  }
  let _truncated = text.slice(0, char)
  if (text.length > _truncated.length) {
    _truncated += '...'
  }
  return _truncated
}

export const notAvailableFilter = (value) => {
  if (!value || !value.length) {
    return 'Not Available'
  }
  return value
}

export const findYoutubeOrVimeoVideoUrl = (text) => {
  let type = null
  let embedUrl = null
  text.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/ // eslint-disable-line
  ) // eslint-disable-line
  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube'
    embedUrl = `https://www.youtube.com/embed/${RegExp.$6}?autoplay=0`
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo'
    embedUrl = `https://player.vimeo.com/video/${RegExp.$6}?color=ef0000`
  }
  if (!type) {
    return {}
  }
  return {
    type: type,
    link: text,
    id: RegExp.$6,
    embedUrl,
  }
}

export const stateCodes = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const primaryIndustries = [
  'Aerospace',
  'Agribusiness',
  'Construction',
  'Defense',
  'Finance',
  'Information Technology',
  'Logistics',
  'Manufacturing',
  'R&D',
  'Retail',
  'Service',
  'Surplus Dealer',
  'Telecommunications',
  'Wholesale',
  'Other',
]

export const companyCertifications = [
  'Total Small Business',
  'Competitive 8(a)',
  'Veteran-Owned Small Business',
  'Service-Disabled Veteran-Owned Small Business',
  'Woman Owned Small Business',
  'Economically Disadvantaged Woman Owned Small Business',
  'Indian Small Business Economic Enterprises',
  'Indian Economic Enterprises',
  'Emerging Small Business',
  'Partial Small Business',
  'Very Small Business',
  'Partial HBCU / MI',
  'Total HBCU / MI',
  'Hubzone',
]

export const convertEmail = (email) => {
  if (!email) return ''
  const atIndex = email.lastIndexOf('@')
  const stars = new Array(atIndex - 3).join('*')
  return `${email.substr(0, 3)}${stars}${email.substr(atIndex)}`
}

export const getRoleInfo = [
  {},
  { id: 1, name: 'Super Admin' },
  { id: 2, name: 'Admin' },
  { id: 3, name: 'Member' },
]

export const getRole = (user) => {
  return getRoleInfo.find((u) => u.id === user.role.id)?.name
}

export const isAdmin = (user) => {
  if (!user) return false
  return user.role.id === 2
}
export const isMember = (user) => {
  if (!user) return false
  return user.role.id === 1
}

/**
 *
 * @param {Object} communityDetail
 * @param {Object} currentUser
 * @returns {Bool}
 */
export const checkCommunityAccess = (communityDetail, currentUser) => {
  return (
    communityDetail?.isMember ||
    currentUser?.id === communityDetail?.adminUserId
  )
}

/**
 * check if user can post
 * @param {Object} communityDetail
 * @param {Object} currentUser
 * @returns {Bool}
 */
export const checkCommunityPostingAccess = (communityDetail, currentUser) => {
  return (
    (communityDetail?.isMember && communityDetail?.isPublicPosting) ||
    currentUser?.id === communityDetail?.adminUserId
  )
}

/**
 * check if user can create event
 * @param {Object} communityDetail
 * @param {Object} currentUser
 * @returns {Bool}
 */
export const checkCommunityEventAccess = (communityDetail, currentUser) => {
  return (
    (communityDetail?.isMember && communityDetail?.isPublicEventPosting) ||
    currentUser?.id === communityDetail?.adminUserId
  )
}

/**
 *
 * @param {string} word
 */
export const capitalise = (word) => {
  return word
    .split(' ')
    .map((i) => `${i.charAt(0)?.toUpperCase()}${i.substr(1)?.toLowerCase()}`)
    .join(' ')
}

/**
 *
 * @param {{firstname:string; lastname:string;}} user
 * @returns Full name of user
 */
export const getName = (user) => {
  if (!user) {
    return ''
  }
  return capitalise(`${user.firstname} ${user.lastname}`)
}

/**
 * @param {File} imageFile Image file
 * @returns {File} Compressed File
 */
export const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 25,
    useWebWorker: true,
  }
  const compressedFile = await imageCompression(imageFile, options)
  return compressedFile
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '')

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return null
}

export const cleanPhoneNumber = (n = '') => n.replace(/\D/g, '')

export const getOppInfoFromSolicitation = (solicitation = {}) => {
  return {
    opportunityName: solicitation.solicitationname,
    description: solicitation.synopsis,
    agency: solicitation.agency,
    contractingOfficer: solicitation.primarypointofcontact,
    responseDate: solicitation.responsedate,
    setAside: solicitation.setaside,
    contractingOfficerPhoneNumber:
      solicitation?.primarypointofcontactphonenum?.length &&
      cleanPhoneNumber(solicitation.primarypointofcontactphonenum),
  }
}

export const BID_PRIORITY = {
  URGENT: 'Urgent',
  IMPORTANT: 'Important',
  MEDIUM: 'Medium',
  LOW: 'Low',
}

export const BID_STATUS = {
  EVALUATING: 'Evaluating',
  WATCH_LIST: 'Watch List',
  SCOPING: 'Scoping',
  DRAFTING: 'Drafting',
  SUBMITTED: 'Submitted',
  WON: 'Won',
}

// export const BID_SWIMLANE = {
//   EVALUATING: 'Evaluating',
//   WATCH_LIST: 'Watch List',
//   SCOPING: 'Scoping',
//   DRAFTING: 'Drafting',
//   SUBMITTED: 'Submitted',
//   WON: 'Won',
//   LOST: 'Lost',
//   DID_NOT_BID: 'Did Not Bid',
// }

export const BID_SWIMLANE = {
  purplepanel: 'Evaluating',
  pinkpanel: 'Watch List',
  greenpanel: 'Scoping',
  darkpinkpanel: 'Drafting',
  redpanel: 'Submitted',
  bluepanel: 'Won',
  orangepanel: 'Lost',
  yellowpanel: 'Did Not Bid',
}

export const SWIMLANE_COLOR = {
  //Bid Board status
  Evaluating: 'purpledot',
  Scoping: 'greendot',
  Drafting: 'darkpinkdot',
  Submitted: 'reddot',
  Won: 'bluedot',

  //Common
  'Watch List': 'pinkdot',
  Lost: 'orangedot',
  'Did Not Bid': 'yellowdot',

  //CRM Status
  Leads: 'purpledot',
  Prospecting: 'greendot',
  Meeting: 'darkpinkdot',
  Nurturing: 'reddot',
}

export const SET_ASIDE = {
  '8(a)': '8(a)',
  'Economically Disadvantaged Women-Owned Small Business':
    'Economically Disadvantaged Women-Owned Small Business',
  HUBZone: 'HUBZone',
  'Indian Economic Enterprise': 'Indian Economic Enterprise',
  'Indian Small Business Economic Enterprise':
    'Indian Small Business Economic Enterprise',
  'Local Area Set-Aside': 'Local Area Set-Aside',
  'Partial Small Business': 'Partial Small Business',
  'Service-Disabled Veteran-Owned Small Business':
    'Service-Disabled Veteran-Owned Small Business',
  'Total HBCU / MI': 'Total HBCU / MI',
  'Total Small Business': 'Total Small Business',
  'Veteran-Owned Small Business': 'Veteran-Owned Small Business',
  'Women-Owned Small Business': 'Women-Owned Small Business',
}

export const SOLICITATION_TYPE = {
  COMBINED_SYNOPSIS: 'Combined Synopsis/Solicitation',
  FUNDING_OPPORTUNITY: 'Funding Opportunity',
  INTENT_TO_BUNDLE_REQUIREMENTS: 'Intent to Bundle Requirements',
  MODIFICATION: 'Modification',
  PRESOLICITATION: 'Presolicitation',
  SOURCES_SOUGHT: 'Sources Sought',
  SPECIAL_NOTICE: 'Special Notice',
}

/**
 * @param {string} key Key of users profile image
 * @returns {string} Returns aws URL of provided key
 */
export const getProfileImageUrl = (key) => {
  if (!key) {
    return null
  }
  return profileImageAWSBucketUrl + key
}

/**
 * @param {string} key Key of company file
 * @returns {string} Returns aws URL of provided key
 */
export const getCompanyFileUrl = (key) => {
  if (!key) {
    return null
  }
  return profileImageAWSBucketUrl + key
}

/**
 * @param {string} key Key of users profile image
 * @returns {string} Returns aws URL of provided key
 */
export const getCommunityImageUrl = (key) => {
  if (!key) {
    return null
  }
  return encodeURI(communityAWSBucket + key)
}

/**
 * @param {string} key Key of users profile image
 * @returns {string} Returns aws URL of provided key
 */
export const getEventImageUrl = (key) => {
  if (!key) {
    return null
  }
  return eventAWSBucket + key
}

export const getAttachmentUrl = (key) => {
  if (!key) {
    return null
  }
  return attachmentBucketUrl + key
}

export const getCrmAttachmentUrl = (key) => {
  if (!key) {
    return null
  }
  return crmBucketUrl + key
}

export const getResourceUrl = (key) => {
  if (!key) {
    return null
  }
  return resourcesBucketUrl + key
}

export const bidViews = {
  list: {
    path: `${routes.bidboard.path}/list`,
    Icon: Listview,
    name: 'List View',
  },
  table: {
    path: `${routes.bidboard.path}/table`,
    Icon: TabularviewIcon,
    name: 'Tabular View',
  },
  board: {
    path: `${routes.bidboard.path}/board`,
    Icon: Boardview,
    name: 'Board View',
  },
  // calendar: {
  //   path: `${routes.bidboard.path}/calendar`,
  //   Icon: Calendarview,
  //   name: 'Calendar View',
  // },
}

export const crmViews = {
  list: {
    path: `${routes.crm.path}/list`,
    Icon: Listview,
    name: 'List View',
  },
  table: {
    path: `${routes.crm.path}/table`,
    Icon: TabularviewIcon,
    name: 'Tabular View',
  },
  board: {
    path: `${routes.crm.path}/board`,
    Icon: Boardview,
    name: 'Board View',
  },
}

export const getFirstLetters = (name = '') => {
  const words = name.split(' ')
  if (words.length <= 1) {
    return `${getFirstLetterForImage(words[0])}${getFirstLetterForImage(
      words[0].substr(1)
    )}`
  } else {
    return `${getFirstLetterForImage(words[0])}${getFirstLetterForImage(
      words[1]
    )}`
  }
}

/**
 *
 * @param {string} word A word
 * @returns {string} first alphabetic letter
 */
export const getFirstLetterForImage = (word = '') => {
  const numbers = [48, 57, 65, 90, 97, 122]
  let output = null
  let i = 0
  while (output === null && i < word.length) {
    const charCode = word.charCodeAt(i)
    if (
      (charCode >= numbers[0] && charCode <= numbers[1]) ||
      (charCode >= numbers[2] && charCode <= numbers[3]) ||
      (charCode >= numbers[4] && charCode <= numbers[5])
    ) {
      output = word[i]
    }
    i += 1
  }

  if (!output) {
    return ''
  }
  return output.toString().toUpperCase()
}

export const ORDER = {
  descend: 'DESC',
  ascend: 'ASC',
}

export const formatNumber = (num, currency = '') => {
  if (Number(num) <= 0) {
    return currency + 0
  }
  return (
    currency +
    Number(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  )
}

export const formatBigNumber = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + 'K'
    : Math.abs(Number(labelValue))
}

export const imageArrayFromTeam = (teams = []) =>
  teams?.map((m) => ({
    url: null,
    alt: `${m.name}`,
    id: m.id,
  }))

export const imageArrayFromMembers = (members = []) =>
  members?.map((m) => ({
    url: getProfileImageUrl(m?.userImage),
    alt: `${m?.firstname} ${m?.lastname}`,
    id: m?.id,
  }))

/**
 * @param {string} dateISO parse date in utc
 * @returns {string} date time in readable format
 */
export const getDate = (dateISO, getRelative) => {
  const date = dayjs(dateISO)
  const diff = dayjs().diff(date, 'hours')
  let formatedDate
  let isRelative = false
  if (diff > 24) {
    formatedDate = date.format('MM-DD-YYYY HH:mm')
  } else {
    formatedDate = `${date.fromNow(true)} ago`
    isRelative = true
  }
  if (getRelative) {
    return { isRelative, date: formatedDate }
  }
  return formatedDate
}
/**
 *
 * @param {{createdAt:string updatedAt:string}} param0
 * @returns {{edited:boolean date: string}} is entry edited and formatted date
 */
export const formatDate = ({ createdAt, updatedAt }) => {
  if (!createdAt) {
    return { edited: false, date: '' }
  }
  if (updatedAt && updatedAt !== createdAt) {
    return { edited: true, date: getDate(updatedAt) }
  } else {
    return { edited: false, date: getDate(createdAt) }
  }
}

export const formatTableDate = (date) => {
  if (!date) return ''
  return moment(date).format('MM/DD/YYYY')
}

export const formatResponseDate = (date, format = 'MM/DD/YYYY') => {
  if (!date || !date?.length) return ''
  return dayjs(date, 'YYYY-MM-DD').format(format)
}

export const formatCrmActivityDate = (date, inputFormat) => {
  return dayjs(date).format('MMM DD, YYYY, hh:mm a')
}

export const compareBeforeEdit = (newValues, originalValue) => {
  const keys = Object.keys(cleanUpObject(newValues))
  return keys.every((key) => {
    if (typeof newValues[key] === 'object') {
      return (
        JSON.stringify(newValues[key]) === JSON.stringify(originalValue[key])
      )
    } else {
      return newValues[key] === originalValue[key]
    }
  })
}

/**
 *
 * @param {string} key
 * @param {object} bidInfo
 */
export const isBidEditable = (key, bidInfo) => {
  const editTableKeys = [
    'agency',
    'solicitationLink',
  ]
  const alwaysEditable = [
    'opportunityName',
    'description',
    'responseDate',
    'preProposalConference',
    'swimlane',
    'priority',
    'potentialValue',
    'contractingOfficerEmail',
    'contractingOfficer',
    'expectedAwardDate',
    'contractingOfficerPhoneNumber',
    'setAside',
    'questionDueDate',
    'siteVisitDate',
    'placeOfPerformance',
  ]
  if (alwaysEditable.includes(key)) {
    return true
  } else if (
    (!bidInfo.solicitationId || !bidInfo[key]) &&
    editTableKeys.includes(key)
  ) {
    return true
  }
  return false
}

export const getBidDate = (date) => {
  if (!date) {
    return undefined
  }
  return date.format(bidDatesFormat)
}

export const getBidDateTime = (date) => {
  if (!date) {
    return undefined
  }
  return date.format(bidDatesTimeFormat)
}

export const parseBidDate = (date) => {
  if (!date) return moment()
  return moment(date, bidDatesFormat)
}

export const parseBidDateTime = (date) => {
  if (!date) return undefined
  return moment(date).format(bidDatesTimeFormat)
}

export const cleanUpObject = (obj) => {
  const keys = Object.keys(obj)
  const output = {}
  keys.forEach((k) => {
    if (obj[k] !== undefined && obj[k] !== null) {
      output[k] = obj[k]
    }
  })
  return output
}

export const getDiffValuesFromObj = (newObj, oldObj) => {
  const outputObj = {}
  const keys = Object.keys(newObj)
  keys.forEach((k) => {
    let newItem = newObj[k]
    let oldItem = oldObj[k]
    newItem = typeof newItem === 'object' ? JSON.stringify(newItem) : newItem
    oldItem = typeof oldItem === 'object' ? JSON.stringify(oldItem) : oldItem

    if (newItem !== oldItem) {
      outputObj[k] = newObj[k]
    }
  })
  return outputObj
}

export const convertBidInfoForForm = (bidInfo = {}) => {
  const dateKeys = [
    'responseDate',
    'preProposalConference',
    'expectedAwardDate',
    'questionDueDate',
    'siteVisitDate',
  ]

  const dateTimeKeys = ['responseDate']

  const keys = Object.keys(bidInfo)
  const bidFormObj = {}
  keys.forEach((k) => {
    if (bidInfo[k] !== undefined && bidInfo[k] !== null) {
      if (dateKeys.includes(k)) {
        bidFormObj[k] = parseBidDate(bidInfo[k])
      } else if (dateTimeKeys.includes(k)) {
        bidFormObj[k] = parseBidDateTime(bidInfo[k])
        if (k === 'responseDate') {
          if (!bidInfo.responseDateTime) {
            const defaultTime = '08:00:00'
            bidFormObj[k] = moment(
              `${bidInfo[k]} ${defaultTime}`,
              bidDatesTimeFormat
            )
          } else {
            bidFormObj[k] = moment
              .utc(bidInfo.responseDateTime, bidDatesTimeFormat)
              .local()
          }
        }
      } else {
        bidFormObj[k] = bidInfo[k]
      }
    }
  })
  return bidFormObj
}

export const arrayToObj = (ary, key) => {
  const resultObject = {}
  ary.forEach((item) => {
    const k = item[key]
    resultObject[k] = item
  })
  return resultObject
}

export const groupByInArray = (ary, key) => {
  const resultObject = {}
  ary.forEach((item) => {
    const k = item[key]
    if (resultObject.hasOwnProperty(k)) {
      resultObject[k].push(item)
    } else {
      resultObject[k] = [item]
    }
  })
  return resultObject
}

export const convertBidResToKanban = (data) => {
  return data.map((i) => ({
    id: i.swimlaneName,
    title: `${i.swimlaneName} (${i.count})`,
    count: i.count,
    cards: i.bids?.map((bid) => ({
      id: bid.id.toString(),
      title: bid.opportunityName,
      responseDate: bid.responseDate,
      priority: bid.priority,
      agency: bid.agency,
      team: bid.assignedTeams,
    })),
  }))
}

export const getPositions = (
  kanbanView,
  sourceLaneId,
  targetLaneId,
  position,
  cardId,
  laneIndexKey = 'bidIndex'
) => {
  let finalIndex
  const getIndex = (lane, index) => {
    return parseFloat(kanbanView[lane]?.cards[index]?.[laneIndexKey] || 0)
  }

  const computeIndexSameLane = (lane, bidIndex) => {
    let newInd
    if (bidIndex === kanbanView[lane].cards.length - 1) {
      newInd = getIndex(lane, bidIndex) + 1
    } else if (bidIndex < 0) {
      newInd = getIndex(lane, bidIndex + 1) - 1
    } else {
      newInd = (getIndex(lane, bidIndex) + getIndex(lane, bidIndex + 1)) / 2
    }
    return newInd
  }

  const computeIndex = (lane, bidIndex) => {
    let newInd
    if (bidIndex > kanbanView[lane].cards.length) {
      newInd = getIndex(lane, bidIndex - 1) + 1
    } else if (bidIndex === kanbanView[lane].cards.length - 1) {
      newInd = getIndex(lane, bidIndex) + 1
    } else if (bidIndex < 0) {
      newInd = getIndex(lane, bidIndex + 1) - 1
    } else {
      newInd = (getIndex(lane, bidIndex) + getIndex(lane, bidIndex + 1)) / 2
    }

    return newInd
  }
  if (sourceLaneId === targetLaneId) {
    const lane = kanbanView[targetLaneId].cards
    const oldIndex = lane.findIndex(
      (i) => cardId.toString() === i.id.toString()
    )
    if (position === oldIndex) {
      finalIndex = getIndex(targetLaneId, position)
    } else if (position > oldIndex) {
      finalIndex = computeIndexSameLane(targetLaneId, position)
    } else {
      finalIndex = computeIndexSameLane(targetLaneId, position - 1)
    }
  } else {
    finalIndex = computeIndex(targetLaneId, position - 1)
  }
  return finalIndex
}

export const normalizeTeamResopnse = (teams = []) => {
  return teams?.map((team) => {
    team.teamedOrganizationsInfo = team.teamedOrganizationsInfo.map((org) => ({
      id: org.organizationId,
      ...org.organizationInfo,
    }))
    return team
  })
}

export const teamingPortalDocumentsType = {
  CAPABILITIES_STATEMENT: 'Capabilities Statement',
  NDA: 'NDA',
  OTHER_DOCUMENTS: 'Other Documents',
  PAST_PERFORMANCE: 'Past Performance',
  SUB_CONTRACTOR_AGREEMENTS: 'SubContractor Agreements',
  TEAMING_AGREEMENTS: 'Teaming Agreements',
}

export const isMoreEntries = ({ page, perPage, count }) => {
  return page * perPage <= count
}

export const normalizeNote = (note) => {
  return {
    comment: note.note,
    userInfo: note.noteAddedByInfo,
    reactionsInfo:
      note?.noteReactionsInfo?.map((r) => ({
        ...r,
        reactorInfo: r.reactedByInfo,
      })) || [],
    postedById: note.noteAddedBy,
    attachmentsInfo: note?.teamNotesDocumentsInfo?.map((a) => ({
      attachmentName: a.name,
      attachedImage: a.attachedDocument,
      id: a.id,
    })),

    ...note,
  }
}
// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

export function formatBytes(a = 0, b = 2) {
  if (a === 0) return ''
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024))
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  )
}

export const isJSON = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const IsFileSizeOk = (file, size = 50 * 1048576, noToast = false) => {
  const isOk = file?.size <= size
  if (!noToast && !isOk) {
    message.error(
      `File ${file?.name} size must be less then ${formatBytes(size)}`
    )
  }
  return isOk
}

export const isFileTypeOk = (
  file,
  fileTypes = commonFileTypes,
  noToast = false
) => {
  const isOk = fileTypes.includes(file?.type)
  if (!noToast && !isOk) {
    message.error(`File ${file?.name} type is not valid`)
  }
  return isOk
}

export const normalizeParams = (params) => {
  const newParams = {}
  Object.keys(params).forEach((k) => {
    const v = params[k]
    if (typeof v === 'object') {
      if(v.data) {
        newParams[k] = JSON.stringify(v.data)
      } else {
        newParams[k] = JSON.stringify(v?.map((a) => String(a)))
      }
    } else {
      newParams[k] = v
    }
  })
  return newParams
}

export const CRM_CONTACT_TYPE = {
  PRIMES: 'Primes',
  SMALL_BIZ: 'Small Biz',
  PROFESSIONALS: 'Professionals',
  AGENCIES: 'Agencies',
  CO_KO: 'CO/KO',
  OSDBU: 'OSDBU',
  OTHER: 'Other',
}

export const CRM_RELATIONSHIP_STAGE = {
  LEADS: 'Leads',
  WATCH_LIST: 'Watch List',
  PROSPECTING: 'Prospecting',
  MEETING: 'Meeting',
  NURTURING: 'Nurturing',
  WON: 'Won',
  LOST: 'Lost',
}

export const LANE_COLORS = {
  Leads: 'purplepanel',
  'Watch List': 'pinkpanel',
  Prospecting: 'greenpanel',
  Meeting: 'darkpinkpanel',
  Nurturing: 'redpanel',
  Won: 'bluepanel',
  Lost: 'orangepanel',
}

export const checkFilterApplied = (allFilters) => {
  return Object.values(allFilters).some((f) => {
    if (typeof f === 'boolean') {
      return f
    } else {
      return f && f.length
    }
  })
}

export const onTableChange = (tableConfigs, cb) => {
  const [{ current, pageSize }, _, { order, columnKey }] = tableConfigs
  const sortBy = ORDER[order]
  if (!sortBy || !columnKey) {
    cb({
      page: current,
      perPage: pageSize,
      sortField: '',
      sortBy: '',
    })
  } else {
    cb({
      page: current,
      perPage: pageSize,
      sortField: columnKey,
      sortBy,
    })
  }
}

export const sortItems = (arr = [], sortKey) => {
  if (!sortKey) throw new Error('Sort key is required to sort array')
  return arr.sort((a, b) => {
    if (a[sortKey] < b[sortKey]) {
      return -1
    }
    if (a[sortKey] > b[sortKey]) {
      return 1
    }
    return 0
  })
}

export const renderTotalPagination = (total, [start, end]) => (
  <span>{`${start} - ${end} of ${total} items`}</span>
)
export const pageSizeOptions = [10, 20, 50, 100]

export const SBA_CERTIFICATIONS = [
  'Woman Owned Small Business',
  'Economically Disadvantaged Woman Owned Small Business',
  'Local Area Set-Aside',
  'Hubzone',
  'Partial Small Business',
  'Total Small Business',
  'Veteran Owned',
  'Service Disabled Veteran Owned Small Business',
  'Total HBCU / MI',
  'Certified 8a',
  'Indian Small Business Economic Enterprise',
  'Indian Economic Eterprise',
].sort()

export const NON_FEDERAL_CERTIFICATIONS = [
  'Minority Business Enterprise (MBE)',
  'Women Business Enterprise (WBE)',
  'Small Business Emterprise (SBE)',
  'Disabled/Veteran Business Enterprise (D/VBE)',
  'Leadership in Energy and Environmental Design (LEED) ',
  'ISO 50001',
  'ISO 9000',
  'ISO 9001',
  'ISO 9002',
  'ISO 9003',
  'ISO 9004',
  'ISO/TS 16949',
].sort()

export const onSelectPopupScroll = (
  e,
  { loading = false, fetchMore = false },
  cb
) => {
  if (
    e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 200 &&
    !loading &&
    fetchMore
  ) {
    cb()
  }
}

export const getCRMActivityCursor = (ary = []) => {
  const { displayInSectionDate, id } = ary[ary.length - 1] || {}
  return { cursor: displayInSectionDate, last: id }
}

export const timeArray = (() => {
  const ary = []
  let t = dayjs().startOf('day')

  for (let index = 0; index < 24 * 4; index++) {
    ary.push({ label: t.format('hh:mm A'), value: t.format('HH:mm:ss') })
    t = t.add(15, 'minutes')
  }
  return ary
})()

export const getDisplayInSectionDate = (t) => {
  if (!t) {
    return t
  } else if (t.activityType === 'task') {
    return t?.crmTaskDetails?.dueDate
  } else if (t.activityType === 'note') {
    return t?.crmNoteDetails?.isTaskCreated
      ? t?.crmNoteDetails?.followUpDate
      : t.displayInSectionDate
  } else {
    return t.displayInSectionDate
  }
}

export const isUpcomingTask = (t) => {
  if (t.activityType === 'history') {
    return false
  }
  const allowInUpcoming = t.allowInUpcoming
  if (!allowInUpcoming) {
    return false
  }
  const displayInSectionDate = dayjs(getDisplayInSectionDate(t), apiDateFormat)
  const today = dayjs().startOf('day')
  const isCompleted = t?.crmTaskDetails?.isCompleted

  return (
    (displayInSectionDate.isAfter(today) ||
      displayInSectionDate.isSame(today)) &&
    allowInUpcoming &&
    !isCompleted &&
    t.activityType !== 'history'
  )
}

export const disablePastDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day')
}

export const jsonParse = (str) => {
  try {
    let json = JSON.parse(str)
    if (typeof json === 'string') {
      json = JSON.parse(json)
    }

    return json
  } catch (error) {
    return []
  }
}

export const checkContact = (value) => {
  if (isNaN(value)) {
    return false
  } else if (!value || value.toString()?.length !== 10) {
    return false
  } else {
    return true
  }
}

export const timeStampGenerator = (unit, amount, type = 'add') => {
  const date = amount ? moment()[type](amount, `${unit}`) : moment()
  const start = date.startOf(unit).unix()
  const end = date.endOf(unit).unix()
  return start + '-' + end
}

export const removeHTMLTags = (str) => {
  if (str === null || str === '') return false
  else str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}

export const validateVideoUrl = (text) => {
  var type = null
  var embedUrl = null
  text.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  ) // eslint-disable-line

  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube'
    embedUrl = `https://www.youtube.com/embed/${RegExp.$6}?autoplay=0`
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo'
    embedUrl = `https://player.vimeo.com/video/${RegExp.$6}?color=ef0000`
  }

  if (!type) {
    return undefined
  }
  return {
    type: type,
    link: text,
    id: RegExp.$6,
    embedUrl,
  }
}

export const groupDetailUrl = '/group-details/:id'

export const CONSTANTS = {
  REGEX: '^(?=.*[!@#$%^&*()_+.])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).+$',
  NO_CHAR: '^[a-zA-Z]+$',
  LINK_VALIDATION:
    '^(?!mailto:)(?:(?:http|https|ftp)://|//|www.)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
  LINK_VALIDATION_2:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  EMAIL_REGEX:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_OR_PHONE_REGEX:
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/,
  WHITSPACE_REGEX: /^[A-Za-z0-9]+$/gm,
  NOTIFICATION_TYPES: {
    follow: 'follow',
    like: 'like',
    comment: 'comment',
    share: 'share',
    livePost: 'livePost',
    pollVoted: 'pollVoted',
    joinedGroup: 'joinedGroup',
    eventInviteAccept: 'eventInviteAccept',
    eventInviteReject: 'eventInviteReject',
    attendEvent: 'attendEvent',
    groupJoinRequest: 'groupJoinRequest',
    groupInvite: 'groupInvite',
    eventJoinRequest: 'eventJoinRequest',
    eventInvite: 'eventInvite',
    eventRequestAccept: 'eventRequestAccept',
    eventRequestReject: 'eventRequestReject',
    acceptGroupInvite: 'acceptGroupInvite',
    rejectGroupInvite: 'rejectGroupInvite',
    acceptGroupRequest: 'acceptGroupRequest',
    rejectGroupRequest: 'rejectGroupRequest',
  },
  PERSIST_KEY: 'GOVGENIE_CLI',
  PERSIST_KEY_GLOBAL_SEARCH: 'SearchParams',
  NETWORK_FROM: {
    suggestion: 'suggestion',
    follower: 'follower',
    following: 'following',
  },
  GLOBAL_SEARCH_MENU: {
    people: 'people',
    group: 'group',
    content: 'content',
    event: 'event',
    pages: 'pages',
  },
  EVENT_TIMEZONES: [
    '(UTC-6) Central Standard Time',
    '(UTC-8) Pacific Standard Time',
    '(UTC-5) Eastern Standard Time',
    '(UTC-7) Mountian Standard Time',
    '(UTC-10) Hawaii Standard Time',
    '(UTC-9) Alaska Standard Time',
  ],
  POPULARITY: ['Trending', 'Most Viewed', 'Recommended'],
  RECENT_ACTIVITY: {
    commented: 'commented on this',
    reacted: 'liked this',
    created: 'created this',
    shared: 'shared this',
  },
  ALL_MIME_TYPES: {
    doc: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    excel: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    pdf: ['application/pdf'],
  },
  POLL_ALLOWED_MIME: 'video/*, image/png, image/jpeg',
  LIBRARIES: ['places'],
  POLL_DURATION: ['1 day', '3 days', '1 week', '3 weeks'],
  DATE_TYPES: ['Today', 'This Week', 'Last Week', 'Last Month'],
  RESOURCE_DATE_TYPES: [
    { name: 'Today', id: timeStampGenerator('day') },
    { name: 'This Week', id: timeStampGenerator('week') },
    { name: 'Last Week', id: timeStampGenerator('week', 1, 'subtract') },
    { name: 'Last Month', id: timeStampGenerator('month', 1, 'subtract') },
  ],
  EVENT_DATE_TYPES: [
    { name: 'Today', id: timeStampGenerator('day') },
    { name: 'This Week', id: timeStampGenerator('week') },
    { name: 'Next week', id: timeStampGenerator('week', 1) },
    { name: 'Next Month', id: timeStampGenerator('month', 1) },
  ],
  EVENT_STATUS: {
    REQUESTED: 'requested',
    CANCELLED: 'cancelled',
    ATTENDING: 'attending',
  },
  EVENT_VISIBLITY: {
    PUBLIC: 'public',
    PRIVATE: 'private',
  },
  EVENT_ATTENDEE_TAB: {
    ATTENDING: 'attending',
    INVITED: 'invited',
    REQUESTED: 'requested',
  },
  EVENT_INDUSTRY_TYPES: [
    'Aerospace',
    'Agribusiness',
    'Construction',
    'Defense',
    'Finance',
    'Information Technology',
    'Logistics',
    'Manufacturing',
    'R&D',
    'Retail',
    'Service',
    'Surplus Dealer',
    'Telecommunications',
    'Wholesale',
    'Other',
  ],
  CREATE: 'create',
  RESET: 'reset',
  POST_PRIVACY: {
    PUBLIC: 'public',
    DIRECT: 'direct',
    GROUP: 'group',
    COMMUNITY: 'community',
  },
  USER_TYPES: {
    DIRECT: 'direct',
    INDIRECT: 'indirect',
  },
  ROUTE_SIDEBAR: {
    NONE: 'none',
    GLOBAL_SEARCH: 'global-search',
    SETTINGS: 'settings',
  },
  ROUTE_HEADER: {
    AUTH: 'auth',
    BLANK: 'blank',
    PROFILE: 'profile',
  },
  UNDEFINED: 'undefined',
  TERMS: 'terms',
  FOLLOW: 'follow',
  ORG_UPLOAD_TYPES: {
    LOGO: 'logo',
    COVER: 'cover',
  },
  SUBSCRIPTION_TYPE: {
    COMPANY: 'company',
    INDIVIDUAL: 'individual',
  },
  ORG_USER_TYPES: {
    ADMIN: 'admin',
    MEMBER: 'member',
  },
  LIVE_STREAM_STATUS: {
    UPLOADED: 'uploaded',
    PAUSED: 'paused',
    STOPEED: 'stopped',
    STARTED: 'started',
  },
  UPLOAD_TYPE:
    '.mp4, .m4a, .fmp4, .webm, .mkv, .mp3, .ogg, .wav, , .ts, .ps, .flv, .mov , .fmpeg, .aac, .png, .jpg, .jpeg, .bmp, .gif, .webp, .txt, .csv, .odt, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .rtf, .pages, .ods, image/*, video/*',
}

export const liveStreamURI = `data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcxIDQ2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSIwIiB4Mj0iMCIgeTE9IjAiIHkyPSIxIj48c3RvcCBvZmZzZXQ9IjY2LjY2JSIgc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNNzkgMzA4YzE0LjI1LTYuNSA1NC4yNS0xOS43NSA3MS0yOSA5LTMuMjUgMjUtMjEgMjUtMjFzMy43NS0xMyAzLTIyYy0xLjc1LTYuNzUtMTUtNDMtMTUtNDMtMi41IDMtNC43NDEgMy4yNTktNyAxLTMuMjUtNy41LTIwLjUtNDQuNS0xNi01NyAxLjI1LTcuNSAxMC02IDEwLTYtMTEuMjUtMzMuNzUtOC02Ny04LTY3cy4wNzMtNy4zNDYgNi0xNWMtMy40OC42MzctOSA0LTkgNCAyLjU2My0xMS43MjcgMTUtMjEgMTUtMjEgLjE0OC0uMzEyLTEuMzIxLTEuNDU0LTEwIDEgMS41LTIuNzggMTYuNjc1LTguNjU0IDMwLTExIDMuNzg3LTkuMzYxIDEyLjc4Mi0xNy4zOTggMjItMjItMi4zNjUgMy4xMzMtMyA2LTMgNnMxNS42NDctOC4wODggNDEtNmMtMTkuNzUgMi0yNCA2LTI0IDZzNzQuNS0xMC43NSAxMDQgMzdjNy41IDkuNSAyNC43NSA1NS43NSAxMCA4OSAzLjc1LTEuNSA0LjUtNC41IDkgMSAuMjUgMTQuNzUtMTEuNSA2My0xOSA2Mi0yLjc1IDEtNC0zLTQtMy0xMC43NSAyOS41LTE0IDM4LTE0IDM4LTIgNC4yNS0zLjc1IDE4LjUtMSAyMiAxLjI1IDQuNSAyMyAyMyAyMyAyM2wxMjcgNTNjMzcgMzUgMjMgMTM1IDIzIDEzNUwwIDQ2NHMtMy05Ni43NSAxNC0xMjBjNS4yNS02LjI1IDIxLjc1LTE5Ljc1IDY1LTM2eiIvPjwvc3ZnPg==`

export const GET_USER_INFO = (user) => {
  return user?.company ? user?.company?.companylegalname : user?.jobTitle ?? ''
}

/**
 *
 * @param {Object} obj
 * @returns {Object}
 */
export const removeEmpty = (obj) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || val === undefined || val === '') && delete obj[key])
  )
  if (obj?.speciality_ids && obj?.speciality_ids?.length === 0)
    delete obj.speciality_ids
  if (obj?.postType && obj?.postType?.length === 0) delete obj.postType
  if (obj?.companyTypeIds && obj?.companyTypeIds?.length === 0)
    delete obj.companyTypeIds
  if (obj?.specialityIds && obj?.specialityIds?.length === 0)
    delete obj.specialityIds
  return obj
}


export const formatDateToEDT = (dateString, format='YYYY-MM-DD') => {
  // Convert the input date string to a Day.js object in UTC

  if(!dateString) return ''

  const date = dayjs.utc(dateString);
  
  if (date.format('HH:mm:ss') === '00:00:00') {
    return date.format(format);
  }
  
  // Convert the date to the specified time zone (EDT)
  const zonedDate = date.tz('America/New_York');

  // Return the full date and time in the EDT time zone
  return zonedDate.format(format);
};
