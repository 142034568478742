import { saved } from '../types'
import awardColumns from 'helpers/awardColumns.json'

const initialState = {
  savedIds: {},
  list: {
    award: [],
    directory: [],
    event: []
  },
  tableColumns: {
    award: awardColumns,
  },
  count: {
    award: 0,
    directory: 0,
    event: 0
  },
  loading: {
    loadingSaved: false,
    loadingSaveStatus: false,
    getAwards: false
  },
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case saved.SET: {
      return {
        ...state,
        savedIds: action.payload
      }
    }
    case saved.SET_AWARDS: {
      return {
        ...state,
        list: {
          ...state.list,
          award: action.payload.awards
        },
        count: {
          ...state.count,
          award: action.payload.count
        }
      }
    }
    case saved.SETLOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload
        }
      }
    }
    case saved.RESET: {
      return initialState
    }
    case saved.SET_TABLE_COLUMNS: {
      const { tableType, value } = action.payload
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          [tableType]: value,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
