import { message as antdMessage } from 'antd'
const checkMessage = (msg) => {
  return msg && typeof msg !== 'object'
}

export const message = {
  /**
   * @param {React.ReactNode} msg
   * @param {{ key?: string }} config
   */
  error: (msg, config = {}) => {
    checkMessage(msg) &&
      antdMessage.error({ content: msg, key: msg.toString(), ...config })
  },

  /**
   * @param {React.ReactNode} msg
   * @param {{ key?: string }} config
   */
  success: (msg, config = {}) => {
    checkMessage(msg) &&
      antdMessage.success({ content: msg, key: msg.toString(), ...config })
  },
}
