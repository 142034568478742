import React from "react";

function TwitterIcon() {
  return (
    <span className="anticon ant-twitter">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 22 18"
      >
        <path
          fill="#0096dc"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth="0.4"
          d="M1.324 15.814a11.876 11.876 0 006.38 1.84c3.337 0 6.395-1.282 8.611-3.61 2.12-2.227 3.287-5.22 3.229-8.256.804-.688 1.75-2.001 1.75-3.281a.641.641 0 00-.965-.552c-.755.443-1.444.56-2.152.36-1.446-1.41-3.6-1.706-5.414-.728-1.585.853-2.467 2.415-2.387 4.121a10.603 10.603 0 01-6.879-3.757.641.641 0 00-1.046.085C1.62 3.46 1.628 5.11 2.35 6.441a.666.666 0 00-.556.663c0 1.34.602 2.57 1.572 3.41a.64.64 0 00-.163.663 4.593 4.593 0 002.6 2.785 7.453 7.453 0 01-4.059.677c-.669-.09-1 .81-.421 1.175zm6.556-1.633c.479-.368.224-1.134-.377-1.147a3.337 3.337 0 01-2.621-1.363c.29-.02.589-.064.874-.14.65-.176.619-1.113-.041-1.245a3.288 3.288 0 01-2.49-2.236c.322.08.65.124.977.13.648.003.893-.827.364-1.175-1.19-.784-1.698-2.169-1.362-3.47a11.905 11.905 0 007.883 3.517.638.638 0 00.654-.782c-.405-1.757.576-2.989 1.63-3.556 1.043-.563 2.717-.739 4 .608.382.402 1.669.417 2.323.264-.293.553-.745 1.078-1.167 1.373a.64.64 0 00-.272.556c.137 2.803-.907 5.59-2.866 7.646-1.973 2.07-4.7 3.211-7.683 3.211-1.186 0-2.35-.192-3.449-.564a8.648 8.648 0 003.623-1.627z"
        ></path>
      </svg>
    </span>
  );
}

export default TwitterIcon;
