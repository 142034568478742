import React from 'react'
import TopOpportunities from 'components/TopOpportunities/TopOpportunities'
import CompleteAccountAlert from 'components/CompleteAccountAlert/CompleteAccountAlert'
import { CONSTANTS } from 'helpers/const'
import './Rightsidebar.scss'

const RightSidebar = ({ type }) => {
  return (
    <div className='RightsidebarStyles'>
      {
        type === CONSTANTS.ROUTE_SIDEBAR.FEED && <CompleteAccountAlert/>
      }
      {
        type === CONSTANTS.ROUTE_SIDEBAR.FEED && <TopOpportunities/>
      }
    </div>
  )
}
export default RightSidebar;
