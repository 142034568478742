import { teamMemberDropdown } from '../types'

const initialState = {
  teams: [],
  teamParams: { search: '' },
  memberParams: { companyIds: [], search: '' },
  members: [],
  allMemberIds: [],
  loading: {
    getTeamsLoading: false,
    getTeamMembers: false,
  },
}

/**
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case teamMemberDropdown.SET_TEAMS: {
      return { ...state, teams: action.payload }
    }
    case teamMemberDropdown.SET_MEMBERS: {
      const { members, allMemberIds } = action.payload
      return { ...state, members, allMemberIds }
    }
    case teamMemberDropdown.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case teamMemberDropdown.RESET: {
      return initialState
    }
    case teamMemberDropdown.RESET_ALL: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
