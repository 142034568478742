import React from 'react'
import './ChromeBar.scss'
import { useDispatch, useSelector } from 'react-redux'
import { triggerChromeExtensionModal } from 'store/action/auth'

const ChromeBar = (props) => {
  const { isChromeExtensionBar, user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  if (!isChromeExtensionBar || !user || user?.id === 'anonymous') {
    return null
  }

  const onClickClose = () => {
    dispatch(triggerChromeExtensionModal())
  }

  return (
    <div
      className='chromeBar'
      style={{
        width: '100%',
        marginTop: '64px',
        marginBottom: '-20px',
      }}
    >
      <div className='leftImage'>
        <img
          src='/chrome-browser-design-bar.png'
          alt='chrome-browser-design-bar'
        />
      </div>
      <div className='content'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='19'
          height='19'
          viewBox='0 0 19 19'
          fill='none'
        >
          <g clip-path='url(#clip0_9554_156815)'>
            <path
              d='M14 9.20508V10.7051H17V9.20508H14ZM12.5 14.1626C13.22 14.6951 14.1575 15.4001 14.9 15.9551C15.2 15.5576 15.5 15.1526 15.8 14.7551C15.0575 14.2001 14.12 13.4951 13.4 12.9551C13.1 13.3601 12.8 13.7651 12.5 14.1626ZM15.8 5.15508C15.5 4.75758 15.2 4.35258 14.9 3.95508C14.1575 4.51008 13.22 5.21508 12.5 5.75508C12.8 6.15258 13.1 6.55758 13.4 6.95508C14.12 6.41508 15.0575 5.71758 15.8 5.15508ZM3.5 7.70508C2.675 7.70508 2 8.38008 2 9.20508V10.7051C2 11.5301 2.675 12.2051 3.5 12.2051H4.25V15.2051H5.75V12.2051H6.5L10.25 14.4551V5.45508L6.5 7.70508H3.5ZM12.125 9.95508C12.125 8.95758 11.69 8.05758 11 7.44258V12.4601C11.69 11.8526 12.125 10.9526 12.125 9.95508Z'
              fill='#E8EAED'
            />
          </g>
          <defs>
            <clipPath id='clip0_9554_156815'>
              <rect
                width='18'
                height='18'
                fill='white'
                transform='translate(0.5 0.955078)'
              />
            </clipPath>
          </defs>
        </svg>
        <span className='text'>
          <a
            className='chromeLink'
            target='blank'
            href='https://chromewebstore.google.com/detail/govgenie/gaopobpcfjldjeheimajfhkpbbbgncbh'
          >
            Download
          </a>{' '}
          our Chrome Extension and add new opportunities directly to your bid
          board!
        </span>
        <svg
          onClick={onClickClose}
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='25'
          viewBox='0 0 25 25'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M10.3536 9.7371C10.1583 9.54184 9.84171 9.54184 9.64645 9.7371C9.45118 9.93236 9.45118 10.2489 9.64645 10.4442L12.0928 12.8906L9.35343 15.63C9.15817 15.8252 9.15817 16.1418 9.35343 16.3371C9.54869 16.5323 9.86528 16.5323 10.0605 16.3371L12.7999 13.5977L15.539 16.3368C15.7343 16.532 16.0508 16.532 16.2461 16.3368C16.4414 16.1415 16.4414 15.8249 16.2461 15.6297L13.507 12.8906L15.9531 10.4445C16.1484 10.2493 16.1484 9.93267 15.9531 9.73741C15.7578 9.54215 15.4413 9.54215 15.246 9.73741L12.7999 12.1835L10.3536 9.7371Z'
            fill='white'
          />
          <path
            d='M12.0928 12.8906L12.4464 13.2441L12.7999 12.8906L12.4464 12.537L12.0928 12.8906ZM10.0605 16.3371L9.70698 15.9835L10.0605 16.3371ZM12.7999 13.5977L13.1535 13.2441L12.7999 12.8906L12.4464 13.2441L12.7999 13.5977ZM13.507 12.8906L13.1535 12.537L12.7999 12.8906L13.1535 13.2441L13.507 12.8906ZM12.7999 12.1835L12.4464 12.537L12.7999 12.8906L13.1535 12.537L12.7999 12.1835ZM10 10.0907L10.7071 9.38354C10.3166 8.99302 9.68342 8.99302 9.29289 9.38354L10 10.0907ZM10 10.0907L10 10.0907L9.29289 9.38354C8.90237 9.77407 8.90237 10.4072 9.29289 10.7978L10 10.0907ZM12.4464 12.537L10 10.0907L9.29289 10.7978L11.7393 13.2441L12.4464 12.537ZM9.70698 15.9835L12.4464 13.2441L11.7393 12.537L8.99988 15.2764L9.70698 15.9835ZM9.70698 15.9835L8.99988 15.2764C8.60935 15.6669 8.60935 16.3001 8.99988 16.6906L9.70698 15.9835ZM9.70698 15.9835L9.70698 15.9835L8.99988 16.6906C9.3904 17.0812 10.0236 17.0812 10.4141 16.6906L9.70698 15.9835ZM12.4464 13.2441L9.70698 15.9835L10.4141 16.6906L13.1535 13.9512L12.4464 13.2441ZM15.8926 15.9832L13.1535 13.2441L12.4464 13.9512L15.1854 16.6903L15.8926 15.9832ZM15.8926 15.9832L15.1854 16.6903C15.576 17.0808 16.2091 17.0808 16.5997 16.6903L15.8926 15.9832ZM15.8926 15.9832L15.8926 15.9832L16.5997 16.6903C16.9902 16.2998 16.9902 15.6666 16.5997 15.2761L15.8926 15.9832ZM13.1535 13.2441L15.8926 15.9832L16.5997 15.2761L13.8606 12.537L13.1535 13.2441ZM15.5995 10.091L13.1535 12.537L13.8606 13.2441L16.3066 10.7981L15.5995 10.091ZM15.5995 10.091L16.3066 10.7981C16.6972 10.4075 16.6972 9.77438 16.3066 9.38386L15.5995 10.091ZM15.5995 10.091L15.5995 10.091L16.3066 9.38386C15.9161 8.99333 15.283 8.99333 14.8924 9.38386L15.5995 10.091ZM13.1535 12.537L15.5995 10.091L14.8924 9.38386L12.4464 11.8299L13.1535 12.537ZM10 10.0907L12.4464 12.537L13.1535 11.8299L10.7071 9.38354L10 10.0907Z'
            fill='white'
          />
        </svg>
      </div>
      <div className='rightImage'>
        <img
          src='/chrome-browser-design-bar.png'
          alt='chrome-browser-design-bar'
        />
      </div>
    </div>
  )
}

export default ChromeBar
