import Loader from 'components/Loader/Loader'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from 'store'
import { setPusherClient } from 'react-pusher'
import Pusher from 'pusher-js'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import './App.less'
import './common.scss'
// import { SetupComet } from 'helpers/comet'
import { QueryClient, QueryClientProvider } from 'react-query'
import AppRouter from './routes/index'
function App() {
  const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  })
  setPusherClient(pusherClient)

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

  // SetupComet()

  const queryClient = new QueryClient()

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* <Elements stripe={stripePromise}> */}
          <Suspense
            fallback={
              <Loader
                className='center'
                size='large'
                wrapperClass='fullpage-loader'
              />
            }
          > 
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </Suspense>
        {/* </Elements> */}
      </PersistGate>
    </Provider>
  )
}

export default App
