import React, { useState, useEffect } from 'react'
import { Button, Space, Popover, Badge, Avatar, message, Tooltip } from 'antd'
// import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import ModalSupportRequest from 'components/ModalSupportRequest/ModalSupportRequest'
import MenuDrawer from 'components/MenuDrawer/MenuDrawer'
import Websitelogo from 'assets/images/Websitelogo.svg'
import avatarUrl from '../../assets/images/userprofile.svg'
import SearchBox from 'components/GlobalSearch/SearchBox/SearchBox'
import { COMETCHAT_CONSTANTS } from 'config/cometChat'
import './Topheader.scss'
import { connect } from 'react-redux'
import { history, routes } from 'routes/config'
import { getName, getProfileImageUrl, getRole } from 'helpers/common'
import { logout, switchCompany } from 'store/action/auth'
import { updateCount } from 'store/action/myMessages'
import { IconNotification, IconMenu, IconBack, Iconsearch } from 'config/Icons'
import { openSupportModal } from 'store/action/support'

function Topheader({
  user,
  unreadCount,
  logout,
  switchCompany,
  openSupportModal,
  topNavLinks,
  subscription,
  searchPage,
}) {
  const [sideMenuVisible, setSideMenuVisibility] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)

  const onClickSwitchCompany = (userOrganizationId) => {
    switchCompany(userOrganizationId)
  }

  return (
    <div className='TopheaderStyles'>
      <div className='topheadermainblock'>
        <div className='sitelogoblock'>
          <Link to=''>
            <img src={Websitelogo} alt='' title='' className='websitelogo' />
          </Link>
        </div>
        {user && user.id !== 'anonymous' ? (
          <div
            className={`site_search ${openSearch ? 'open' : ''}`}
            id='searchContainer'
          >
            <Button
              type='black'
              className='ant-btn-link btn-back'
              onClick={() => setOpenSearch((ts) => !ts)}
              icon={<IconBack />}
            />
            <SearchBox searchPage={searchPage} />
          </div>
        ) : null}
        <div className='headerrightblock'>
          {user && user.id !== 'anonymous' ? (
            <Button
              type='black'
              className='ant-btn-link btn-search'
              onClick={() => setOpenSearch((ts) => !ts)}
              icon={<Iconsearch />}
            />
          ) : null}
          <div className='header_navigation'>
            {user && user.id === 'anonymous' ? (
              <div className='header_auth'>
                <Button href='/login'>Login</Button>
                <Button href='/signup' type='primary'>
                  Signup
                </Button>
              </div>
            ) : null}
            {user && user.id !== 'anonymous' ? (
              <ul>
                {topNavLinks.map((link, key) => (
                  <li key={key}>
                    {link.type === 'external' ? (
                      <a href={link.link} rel='noreferrer'>
                        <link.Icon />
                        <span>{link.name}</span>
                      </a>
                    ) : (
                      <NavLink
                        exact={link.route.exact}
                        to={link?.route?.path}
                        activeClassName='active'
                      >
                        <link.Icon />
                        <span>{link.route.name}</span>
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          {user && user.id !== 'anonymous' ? (
            <div className='headeruserblock'>
              <div className='userprofileblock'>
                <Space>
                  <Popover
                    title={false}
                    trigger='click'
                    placement='bottomRight'
                    content={
                      <div className='logoutblock'>
                        <div className='memberblock flexblock '>
                          <div className='memmberprofile'>
                            <img
                              src={
                                user?.userImage
                                  ? getProfileImageUrl(user?.userImage)
                                  : avatarUrl
                              }
                              alt='profile'
                              title=''
                              className='UserImage user-profile'
                            />
                          </div>
                          <div className='membername'>
                            <h4>{getName(user)}</h4>
                            <span className='mailid'>
                              {getRole(user)} |{' '}
                              {user?.company.companylegalname.length > 26 ? (
                                <Tooltip
                                  placement='topLeft'
                                  title={user?.company.companylegalname}
                                >
                                  {user?.company.companylegalname.substring(
                                    0,
                                    26 - 3
                                  ) + '...'}
                                </Tooltip>
                              ) : (
                                user?.company.companylegalname
                              )}
                            </span>
                          </div>
                        </div>
                        <Button
                          className='secondarybtn fullWidth'
                          size='small'
                          onClick={() => {
                            history.push(routes.myprofilepage.path)
                          }}
                        >
                          My Profile
                        </Button>

                        {user.userOrganizations &&
                          user.userOrganizations.length > 1 && (
                            <div className='signupblock'>
                              <small>Switch companies</small>
                              <div className='org-group'>
                                {user.userOrganizations.map((org, index) => {
                                  return (
                                    <Button
                                      key={`company-${index}`}
                                      type='link'
                                      className={classNames(`orgCompany`, {
                                        'selected-company':
                                          user.companyId === org.company.id,
                                      })}
                                      onClick={() => {
                                        if (user.companyId !== org.company.id) {
                                          onClickSwitchCompany(org.id)
                                        } else {
                                          message.info('Currently selected')
                                        }
                                      }}
                                    >
                                      {org.company.companylogo ? (
                                        <Avatar
                                          className='orgCompanyAvatar'
                                          size={30}
                                          src={getProfileImageUrl(
                                            org.company.companylogo
                                          )}
                                        />
                                      ) : (
                                        <Avatar
                                          className='orgCompanyAvatar'
                                          size={30}
                                        >
                                          {org.company.companylegalname[0]}
                                        </Avatar>
                                      )}

                                      {org.company.companylegalname.length >
                                      26 ? (
                                        <Tooltip
                                          placement='topLeft'
                                          title={org.company.companylegalname}
                                        >
                                          {' '}
                                          {org.company.companylegalname.substring(
                                            0,
                                            26 - 3
                                          ) + '...'}{' '}
                                        </Tooltip>
                                      ) : (
                                        org.company.companylegalname
                                      )}
                                    </Button>
                                  )
                                })}
                              </div>
                            </div>
                          )}

                        <div className='signupblock'>
                          <Button
                            type='link'
                            className='fullWidth'
                            onClick={() => openSupportModal(true)}
                          >
                            Send Feedback
                          </Button>
                          <Button
                            href={'https://govgenie.freshdesk.com'}
                            target='_blank'
                            rel={'noreferrer'}
                            type='link'
                            className='fullWidth'
                          >
                            Help
                          </Button>
                          <Button
                            type='link'
                            className='fullWidth'
                            onClick={logout}
                          >
                            Sign Out
                          </Button>
                        </div>
                      </div>
                    }
                  >
                    <div className='profileicon'>
                      <Button type='ghost' className='userprofile noPad'>
                        <img
                          src={
                            user.userImage
                              ? getProfileImageUrl(user?.userImage)
                              : avatarUrl
                          }
                          alt='profile'
                          title=''
                          className='UserImage user-profile'
                        />
                      </Button>
                    </div>
                  </Popover>
                </Space>
              </div>
            </div>
          ) : null}
          {user && user.id !== 'anonymous' ? (
            <div className='headermenutrigger'>
              <div
                className='menu_trigger'
                onClick={() => setSideMenuVisibility(true)}
              >
                <IconMenu />
                <span>Menu</span>
              </div>
              <MenuDrawer
                visible={sideMenuVisible}
                onClose={() => setSideMenuVisibility(false)}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ModalSupportRequest />
    </div>
  )
}
const mapStateToProps = ({ auth, config, myMessages, company }, ownProps) => ({
  user: auth.user,
  subscription: company.companySubscription,
  topNavLinks: config.topNavLinks,
  unreadCount: myMessages.messageCount,
  ...ownProps,
})
export default connect(mapStateToProps, {
  logout,
  openSupportModal,
  switchCompany,
})(Topheader)
