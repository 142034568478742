import { postType } from '../types'

const initialAuthState = {
  feeds: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  comments: {
    count: 0,
    totalPages: 0,
  },
  likes: {
    rows: [],
    totalPages: 0,
  },
  postType: [],
  filters: {
    postType: [],
  },
  votes: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  modal: {
    createPost: false,
    createPoll: false,
    editEvent: false,
    postPreview: false,
    limitModal: false,
  },
  appendShare: true,
  suggestions: [],
  metadata: undefined,
  groups: [],
  is_preview_link: true,
}

const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case postType.SET_POSTS: {
      const feeds = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.feeds.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      }
      return { ...state, feeds }
    }
    case postType.SET_COMMENT: {
      return { ...state, comments: action?.payload }
    }
    case postType.RESET_POSTS: {
      return { ...state, feeds: initialAuthState.feeds }
    }
    case postType.TOGGLE_LIKE: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) => {
            return v.id === action.payload.post_id
              ? {
                  ...v,
                  is_liked: !v.is_liked,
                  like_count: v.is_liked ? v.like_count - 1 : v.like_count + 1,
                }
              : v?.shared_post_id?.id === action.payload.post_id
              ? {
                  ...v,
                  shared_post_id: {
                    ...v?.shared_post_id,
                    like_count: action.payload.is_like
                      ? v?.shared_post_id?.like_count + 1
                      : v?.shared_post_id?.like_count - 1,
                  },
                }
              : v
          }),
        },
      }
    }
    case postType.TOGGLE_LIKE_COMMENT: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) => ({
            ...v,
            comments: v.comments.map((t) =>
              t.id === action.payload
                ? {
                    ...t,
                    is_liked: !t.is_liked,
                    likes: t.is_liked ? t.likes - 1 : t.likes + 1 || 1,
                  }
                : t
            ),
          })),
        },
      }
    }
    case postType.COMMENT_POST: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.id === action.payload.post_id
              ? {
                  ...v,
                  comment_count: v.comment_count + 1,
                  comments: Array.isArray(v.comments)
                    ? [action.payload.data, ...v.comments]
                    : [action.payload.data],
                }
              : v?.shared_post_id?.id === action.payload.post_id
              ? {
                  ...v,
                  shared_post_id: {
                    ...v?.shared_post_id,
                    comment_count: v?.shared_post_id?.comment_count + 1,
                    comments: Array.isArray(v.comments)
                      ? [action.payload.data, ...v.comments]
                      : [action.payload.data],
                  },
                }
              : v
          ),
        },
      }
    }
    case postType.TOGGLE_FOLLOWER: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.created_by.id === action.payload.id
              ? {
                  ...v,
                  created_by: {
                    ...v.created_by,
                    is_following: action.payload.status,
                  },
                }
              : v
          ),
        },
        likes: {
          ...state.likes,
          rows: state.likes.rows.map((v) =>
            v.liked_by.id === action.payload.id
              ? {
                  ...v,
                  liked_by: {
                    ...v.liked_by,
                    is_following: action.payload.status,
                  },
                }
              : v
          ),
        },
      }
    }
    case postType.DELETE_COMMENT: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) => ({
            ...v,
            comment_count: v.comment_count - 1,
            comments: v.comments.filter((e) => e.id !== action.payload.id),
          })),
        },
      }
    }
    case postType.EDIT_COMMENT: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) => ({
            ...v,
            comments: v.comments.map((e) =>
              e.id === action.payload.id
                ? {
                    ...e,
                    comment: action.payload.comment,
                    ...(action.payload.updatedAt
                      ? { updatedAt: action.payload.updatedAt }
                      : {}),
                  }
                : e
            ),
          })),
        },
      }
    }
    case postType.ALLOW_COMMENT: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) => ({
            ...v,
            allow_comments:
              v.id === action.payload.id
                ? action.payload.allow_comments
                : v.allow_comments,
            text: v.id === action.payload.id ? action.payload.text : v.text,
            live_stream_id: {
              ...v.live_stream_id,
              title:
                v.id === action.payload.id
                  ? action.payload.title
                  : v?.live_stream_id?.title,
            },
          })),
        },
      }
    }
    case postType.DELETE_POST: {
      const {
        feeds: { rows },
      } = state

      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.filter((e) => e.id !== action.payload.id),
        },
      }
    }
    case postType.SET_LIKES: {
      const likes = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.likes.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      }

      return {
        ...state,
        likes,
      }
    }
    case postType.SET_POST_TYPES: {
      return {
        ...state,
        postType: action?.payload,
      }
    }
    case postType.APPEND_POST: {
      if (state.appendShare) {
        return {
          ...state,
          feeds: {
            ...state.feeds,
            rows: [...[action?.payload], ...state.feeds.rows],
          },
        }
      }
      return state
    }
    case postType.EDIT_POST: {
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: state.feeds.rows.map((v) =>
            v.id === action?.payload?.id
              ? { ...action?.payload, comments: v.comments }
              : v
          ),
        },
      }
    }
    case postType.LIVE_STREAM_END: {
      const {
        feeds: { rows },
      } = state

      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: state.feeds.rows.map((v) =>
            v.id === action?.payload?.id
              ? {
                  ...v,
                  status:
                    v.id === action.payload.id
                      ? action?.payload?.status
                      : v?.status,
                }
              : v
          ),
        },
      }
    }
    case postType.SET_FILTERS: {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    }
    case postType.TOGGLE_POLL_RESULT: {
      const {
        feeds: { rows },
      } = state
      const { post_id, option, status } = action.payload
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.id === post_id || v?.shared_post_id?.id === post_id
              ? {
                  ...v,
                  poll_id:
                    v.id === post_id
                      ? {
                          ...v.poll_id,
                          selected_option_id: status ? option : null,
                          poll_options: v.poll_id.poll_options.map((o_v) =>
                            o_v.id === option
                              ? {
                                  ...o_v,
                                  votes: status ? o_v.votes + 1 : o_v.votes - 1,
                                }
                              : o_v
                          ),
                        }
                      : v?.poll_id,
                  shared_post_id:
                    v?.shared_post_id?.id === post_id
                      ? {
                          ...v?.shared_post_id,
                          poll_id: {
                            ...v.shared_post_id.poll_id,
                            selected_option_id: status ? option : null,
                            poll_options:
                              v.shared_post_id.poll_id.poll_options.map((o_v) =>
                                o_v.id === option
                                  ? {
                                      ...o_v,
                                      votes: status
                                        ? o_v.votes + 1
                                        : o_v.votes - 1,
                                    }
                                  : o_v
                              ),
                          },
                        }
                      : v?.shared_post_id,
                }
              : v
          ),
        },
      }
    }
    case postType.SET_VOTES: {
      const votes = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.votes.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      }
      return { ...state, votes }
    }
    case postType.RESET_VOTES: {
      return { ...state, votes: initialAuthState.votes }
    }
    case postType.SET_MODAL: {
      const modal = {}
      modal[action.payload.modal] = action.payload.value
      return { ...state, modal: { ...state.modal, ...modal } }
    }
    case postType.SET_COMMENTS: {
      const rows =
        action.payload.page === 1
          ? [...action?.payload?.rows]
          : [
              ...state.feeds.rows.find((v) => v.id === action?.payload?.post_id)
                ?.comments,
              ...action?.payload?.rows,
            ]
      const comments = {
        count: action?.payload?.count || 0,
        totalPages: action?.payload?.totalPages || 0,
      }
      return {
        ...state,
        comments,
        feeds: {
          ...state.feeds,
          rows: state.feeds.rows.map((v) =>
            v.id === action?.payload?.post_id ? { ...v, comments: rows } : v
          ),
        },
      }
    }
    case postType.SET_APPEND: {
      return { ...state, appendShare: action.payload }
    }
    case postType.SEARCH_HASHTAGS: {
      return { ...state, suggestions: action?.payload }
    }
    case postType.GET_URL_METADATA: {
      return { ...state, metadata: action?.payload }
    }
    case postType.PUSH_SET_LIKE_POST: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.id === action.payload.post_id
              ? {
                  ...v,
                  like_count: action.payload.count,
                }
              : v?.shared_post_id?.id === action.payload.post_id
              ? {
                  ...v,
                  shared_post_id: {
                    ...v?.shared_post_id,
                    like_count: action.payload.count,
                  },
                }
              : v
          ),
        },
      }
    }
    case postType.PUSH_SET_LIKE_COMMENT: {
      const {
        feeds: { rows },
      } = state
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.id === action.payload.post_id
              ? {
                  ...v,
                  comments: v.comments.map((t) =>
                    t.id === action.payload.comment_id
                      ? {
                          ...t,
                          likes: action.payload.count,
                        }
                      : t
                  ),
                }
              : v
          ),
        },
      }
    }
    case postType.PUSH_SET_POLL_RESULT: {
      const {
        feeds: { rows },
      } = state
      const { post_id, option, count } = action.payload
      return {
        ...state,
        feeds: {
          ...state.feeds,
          rows: rows.map((v) =>
            v.id === post_id || v?.shared_post_id?.id === post_id
              ? {
                  ...v,
                  poll_id:
                    v.id === post_id
                      ? {
                          ...v?.poll_id,
                          poll_options: v?.poll_id?.poll_options?.map((o_v) =>
                            o_v.id === option
                              ? {
                                  ...o_v,
                                  votes: count,
                                }
                              : o_v
                          ),
                        }
                      : v?.poll_id,
                  shared_post_id:
                    v?.shared_post_id?.id === post_id
                      ? {
                          ...v?.shared_post_id,
                          poll_id: {
                            ...v?.shared_post_id?.poll_id,
                            poll_options:
                              v?.shared_post_id?.poll_id?.poll_options?.map(
                                (o_v) =>
                                  o_v.id === option
                                    ? {
                                        ...o_v,
                                        votes: count,
                                      }
                                    : o_v
                              ),
                          },
                        }
                      : v?.shared_post_id,
                }
              : v
          ),
        },
      }
    }
    case postType.SET_GROUPS: {
      return { ...state, groups: action.payload }
    }
    case postType.SET_PREVIEW_LINK: {
      return { ...state, is_preview_link: action.payload }
    }
    default: {
      return state
    }
  }
}

export default reducer
