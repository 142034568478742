import { Progress } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { CheckCircleFilled } from '@ant-design/icons'
import './CompleteAccountAlert.scss'
import { routes } from 'routes/config'
import { getCompany } from 'store/action/company'
import { isAdmin } from 'helpers/common'
import { useCallback, useEffect, useState } from 'react'

const requiredCompanyFields = [
  'companylegalname',
  'lastname',
  'firstname',
  'addressline1',
  'city',
  'state',
  'zip',
  'country',
  'businesswebsite',
  'companyprimaryindustry',
  'companyheadquarterstate',
  'emailaddress',
  'pointofcontactphonenumber',
]

const CompleteAccountAlert = ({ getCompany, user }) => {
  const [companyInfo, setCompanyInfo] = useState({})
  const [percentCompleted, setPercentCompleted] = useState(0)
  const companyProfileCompleted = (companyInfo = {}) => {
    const checkFields = requiredCompanyFields.map((field) => {
      return !!companyInfo[field]
    })
    return !checkFields.includes(false)
  }
  const fetchCompany = useCallback(async () => {
    const data = await getCompany()
    let totalCompleted = 1
    setCompanyInfo(data)
    if (data.capabilitiesstatement) totalCompleted += 1
    if (companyProfileCompleted(data)) totalCompleted += 1
    setPercentCompleted(Math.round((totalCompleted / 3) * 100))
    
  }, [getCompany])
  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])
  return (
    <>
      {companyInfo?.id && isAdmin(user) ? (
        <>
          {!companyProfileCompleted(companyInfo) || !companyInfo.capabilitiesstatement ? (
            <div class='completeAccountAlertStyles'>
              <h4>Complete your account</h4>
              <p>Welcome to Govgenie! Let's get you started.</p>
              <Progress
                percent={percentCompleted}
                status='active'
                strokeColor='#fff'
                trailColor='#ffffff40'
              />
              <ul className='account-actions'>
                <li className='active'>
                  <CheckCircleFilled /> <span>Complete personal profile</span>
                </li>
                <li
                  className={
                    companyProfileCompleted(companyInfo) ? 'active' : 'inactive'
                  }
                >
                  {companyProfileCompleted(companyInfo) ? (
                    <>
                      <CheckCircleFilled />{' '}
                      <span>Complete company profile</span>
                    </>
                  ) : (
                    <Link to={routes.myprofilepage.path_string('company')}>
                      <CheckCircleFilled />{' '}
                      <span>Complete company profile</span>
                    </Link>
                  )}
                </li>
                <li
                  className={
                    companyInfo.capabilitiesstatement ? 'active' : 'inactive'
                  }
                >
                  {companyInfo.capabilitiesstatement ? (
                    <>
                      <CheckCircleFilled />{' '}
                      <span>Upload capabilities statement</span>
                    </>
                  ) : (
                    <Link to={`${routes.myprofilepage.path_string('company')}#upload_capabilities_statement`}>
                      <CheckCircleFilled />{' '}
                      <span>Upload capabilities statement</span>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
})

export default connect(mapStateToProps, {
  getCompany,
})(CompleteAccountAlert)
