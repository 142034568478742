export const images = {
  Linkedinicon: require('./../assets/images/linkedin.png').default,
  avatar: require('./../assets/images/avatar.png').default,
  surgeonicon: require('./../assets/images/surgeon.svg').default,
  pattern: require('./../assets/images/pattern.png').default,
  patternlarge: require('./../assets/images/pattternlarge.png').default,
  profileThumb: require('../assets/images/profile-thumb.png').default,
  verifiedIcon: require('../assets/images/verified-icon.svg').default,
  Indirectuser: require('../assets/images/indirectuser.png').default,
  group: require('../assets/images/group.png').default,
  feedLikeIcon: require('../assets/images/like-icon.svg').default,
  user: require('../assets/images/user.jpg').default,
  warningIcon: require('../assets/images/warning-icon.svg').default,
  placeholderImage: require('../assets/images/placeholder-image.svg').default,
  closeicon: require('../assets/images/close-icon.svg').default,
  nodata: require('../assets/images/no-data.png').default,
  noconnection: require('../assets/images/no-connection.png').default,
  noactivity: require('../assets/images/no-activity.svg').default,
  arrowright: require('../assets/images/right-arrow.png').default,
  noresult: require('../assets/images/no-result.png').default,
  videoSample: require('../assets/images/video-sample.mp4').default,
  docThumbIcon: require('../assets/images/doc-thumb-icon.svg').default,
  wordThumbIcon: require('../assets/images/word-thumb-icon.svg').default,
  excelThumbIcon: require('../assets/images/excel-thumb-icon.svg').default,
  cardPlaceholder: require('../assets/images/card-placeholder.svg').default,
  fireIcon: require('../assets/images/fire-icon.svg').default,
  serverError: require('../assets/images/server-error.png').default,
  NotFoundImage: require('../assets/images/404-image.png').default,
  brokenLink: require('../assets/images/broken-link.png').default,
  somethingWrong: require('../assets/images/something-wrong.png').default,
  cannotaccess: require('../assets/images/cannotaccess.png').default,
  nopendinginvite: require('../assets/images/nopendinginvite.png').default,
  nonotification: require('../assets/images/no-notification.png').default,
  noMessage: require('../assets/images/messages-pana.svg').default,
  orgIcon: require('../assets/images/org-icon.png').default,
  rectangleOrg: require('../assets/images/rectangle-org.png').default,
  companyInactive: require('../assets/images/company-inactive.png').default,
  unmute: require('../assets/images/volume-on.png').default,
  mute: require('../assets/images/volume-off.png').default,
  subscriptionPending: require('../assets/images/subscription-pending.png')
    .default,
  subscriptionExpired: require('../assets/images/subscription-expired.png')
    .default,
  accountDeactived: require('../assets/images/account-deactived.png').default,
  accountLimitExceeded: require('../assets/images/account-limit-exceeded.png')
    .default,
  noPost: require('../assets/images/no-post.png').default,
  contactIcon: require('../assets/images/contactIcon.png').default,
  order: require('../assets/images/order.svg').default,
  payment: require('../assets/images/payment.svg').default,
  plusSubscription: require('../assets/images/plus-subscription.svg').default,
  visa: require('../assets/images/visa.svg').default,
  mastercard: require('../assets/images/master.svg').default,
  amex: require('../assets/images/american-express.png').default,
  cartes_bancaires: require('../assets/images/ob.png').default,
  dinersClub: require('../assets/images/diners-club.png').default,
  interac: require('../assets/images/interac.png').default,
  jcb: require('../assets/images/jcb.png').default,
  discover: require('../assets/images/discover.png').default,
  unionpay: require('../assets/images/union-pay.png').default,
  micUnmute: require('../assets/images/icons-mic.svg').default,
  micMute: require('../assets/images/icons-mute.svg').default,
  communityimage: require('../assets/images/communityimage.svg').default,
}
