import React from "react";

function VideoIcon() {
  return (
    <span className="anticon anticon-video">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 15"
      >
        <g fill="none" fillRule="evenodd">
          <path
            stroke="currentColor"
            strokeWidth="1.5"
            d="M10.834.75c.659 0 1.221.238 1.689.712.471.478.707 1.054.707 1.727h0V6.59l5.624-4.565-.031 9.519-5.593-3.976v3.416a2.37 2.37 0 01-.707 1.726 2.287 2.287 0 01-1.689.712h0-7.689c-.658 0-1.22-.238-1.688-.712a2.366 2.366 0 01-.707-1.726h0V3.189c0-.673.235-1.249.707-1.727A2.285 2.285 0 013.145.75h0z"
          ></path>
          <path
            fill="currentColor"
            d="M7.038 5.5a1.05 1.05 0 00-.07.375C6.969 6.496 7.512 7 8.18 7c.353 0 .671-.14.892-.365.203.329.319.71.319 1.115C9.39 8.993 8.306 10 6.969 10 5.63 10 4.547 8.993 4.547 7.75c0-1.198 1.008-2.178 2.28-2.246l.142-.004h.069zM6.968 4c1.683 0 3.195.878 3.948 2.235l.084.159-.733.315C9.677 5.524 8.4 4.75 6.97 4.75c-1.328 0-2.526.667-3.164 1.716l-.084.145L3 6.274C3.748 4.89 5.273 4 6.969 4z"
          ></path>
        </g>
      </svg>
    </span>
  );
}

export default VideoIcon;
