import { connect } from "react-redux";
import { NavLink } from 'react-router-dom'
import './QuickMenu.scss';

const QuickMenu = ({ leftSidebarLinks }) => {
  return (
    <div className='QuickMenuStyles'>
      <ul>
        {leftSidebarLinks.map((link, index) => (
          <li>
            <NavLink
              exact={link.route.exact}
              to={link.usePathString ? link.route.path_string : link.route.path}
              activeClassName='active'>
              <link.Icon />
              <span>{link.route.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

const mapStateToProps = ({ config }) => ({
  leftSidebarLinks: config.leftSidebarLinks
})

export default connect(mapStateToProps)(QuickMenu);
