import { communityResourceTypes } from '../types'
import { CONSTANTS } from 'helpers/const'

const initialAuthState = {
  events: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  constants: {},
  loading: {}
}


const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case communityResourceTypes.SET_RESOURCES: {
      return {
        ...state,
        resources: {
          rows: action.payload.rows,
          count: action.payload.count,
        },
      }
    }
    case communityResourceTypes.SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }
    }
    case communityResourceTypes.SET_CONSTANTS: {
      return {
        ...state,
        constants: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
