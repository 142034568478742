import React from 'react'
import { Drawer, Row, Col } from 'antd'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import './MenuDrawer.scss'

const MenuDrawer = ({ visible, onClose, menuDrawerLinks, subscription }) => {
  return (
    <Drawer
      title='More GovGenie Links'
      visible={visible}
      onClose={onClose}
      width={380}
      className='menu-drawer'
    >
      <Row className='menu-links' gutter={[16, 16]}>
        {menuDrawerLinks
          .map((link, key) => (
            <Col span={8} className='menu-link' key={key}>
              <NavLink
                exact={link.route.exact}
                to={link.usePathString ? link.route.path_string() : link.route.path}
                activeClassName='active'
              >
                <link.Icon />
                <span>{link.route.name}</span>
              </NavLink>
            </Col>
          ))}
      </Row>
    </Drawer>
  )
}

const mapStateToProps = ({ config, company }) => ({
  menuDrawerLinks: config.menuDrawerLinks,
  subscription: company.companySubscription,
})

export default connect(mapStateToProps, {})(MenuDrawer)
