import { company, directory } from '../types'
import { get, put, deletee, uploadToPresignedUrl, getErrorMessage, post } from 'api'
import { compressImage } from 'helpers/common'
import { message } from 'helpers/message'
import NaicsCodes from 'helpers/naics.json'
import PSCCodes from 'helpers/psc.json'
import moment from 'moment'

export const getCompany = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: company.SET_LOADING,
      payload: {
        getCompany: true,
      },
    })
    const companyId = getState()?.auth?.user?.companyId
    const { data } = await get({
      subUrl: `/company/${companyId}`,
    })
    data.entrance_date_8_b = data.entrance_date_8_b
      ? moment(data.entrance_date_8_b)
      : null
    data.exit_date_8_c = data.exit_date_8_c ? moment(data.exit_date_8_c) : null
    data.additionalnaicscodes = data.additionalnaicscodes || []
    data.additionalpsccodes = data.additionalpsccodes || []

    dispatch({
      type: company.SET_COMPANY,
      payload: data,
    })
    dispatch({
      type: company.SET_LOADING,
      payload: {
        getCompany: false,
      },
    })
    return data
  } catch (error) {
    message.error('Error retrieving company data')
  } finally {
    dispatch({
      type: company.SET_LOADING,
      payload: {
        getCompany: false,
      },
    })
  }
}

const handleCodeDescriptions = (data) => {
  const {
    additionalnaicscodes: addNaicsCodes,
    additionalpsccodes: addPscCodes,
  } = data
  const naiscDescriptions = addNaicsCodes?.map((code) => {
    const foundDesc = NaicsCodes.find(
      (naics) => naics.value === (Number(code) || code)
    )
    return foundDesc.text
  })
  const pscDescriptions = addPscCodes?.map((code) => {
    const foundDesc = PSCCodes.find(
      (psc) => psc.value === (Number(code) || code)
    )
    return foundDesc.text
  })

  return {
    ...data,
    additionalnaicscode_descriptions: naiscDescriptions,
    additionalpsccode_descriptions: pscDescriptions,
    primarynaicscodedescription: data.primarynaicscode
      ? NaicsCodes.find(
          (naics) =>
            naics.value ===
            (Number(data.primarynaicscode) || data.primarynaicscode)
        ).text
      : null,
    primarypsccodedescription: data.primarypsccode
      ? PSCCodes.find(
          (psc) =>
            psc.value === (Number(data.primarypsccode) || data.primarypsccode)
        ).text
      : null,
  }
}

export const updateCompany = (companyInfo) => async (dispatch) => {
  try {
    const companyData = handleCodeDescriptions(companyInfo)
    companyData.entrance_date_8_b =
      companyData?.entrance_date_8_b?.format('MM/DD/YYYY')
    companyData.exit_date_8_c = companyData?.exit_date_8_c?.format('MM/DD/YYYY')
    dispatch({
      type: company.SET_LOADING,
      payload: {
        updateCompany: true,
      },
    })
    const companyId = companyData.id
    const { data } = await put({
      subUrl: `/company/${companyId}`,
      data: companyData,
    })
    data.entrance_date_8_b = data.entrance_date_8_b
      ? moment(data.entrance_date_8_b)
      : null
    data.exit_date_8_c = data.exit_date_8_c ? moment(data.exit_date_8_c) : null
    data.additionalnaicscodes = data.additionalnaicscodes || []
    data.additionalpsccodes = data.additionalpsccodes || []

    dispatch({
      type: company.SET_LOADING,
      payload: {
        updateCompany: false,
      },
    })
    message.success('Company information saved successfully.')
    return dispatch({
      type: company.SET_COMPANY,
      payload: data,
    })
  } catch (error) {
    message.error('Error saving company data')
  } finally {
    dispatch({
      type: company.SET_LOADING,
      payload: {
        updateCompany: false,
      },
    })
  }
}

export const uploadFile =
  (params, companyInfo) => async (dispatch, getState) => {
    try {
      let fileToUpload
      const { file, fileType, tableColumn } = params
      const companyId = getState()?.auth?.user?.companyId
      dispatch({
        type: company.SET_LOADING,
        payload: { uploadFile: true },
      })
      const { data } = await get({
        subUrl: `/company/get-company-file-presigned-url/${companyId}`,
        params: {
          fileName: file.name,
          fileType,
        },
      })

      const { url, key } = data.data
      await uploadToPresignedUrl(url, file)
      const { data: updatedCompany } = await put({
        subUrl: `/company/${companyId}`,
        data: { [tableColumn]: key },
      })
      updatedCompany.entrance_date_8_b = updatedCompany.entrance_date_8_b
        ? moment(updatedCompany.entrance_date_8_b)
        : null
      updatedCompany.exit_date_8_c = updatedCompany.exit_date_8_c
        ? moment(updatedCompany.exit_date_8_c)
        : null
      updatedCompany.additionalnaicscodes =
        updatedCompany.additionalnaicscodes || []
      updatedCompany.additionalpsccodes =
        updatedCompany.additionalpsccodes || []
      message.success('File uploaded successfully.')
      dispatch({
        type: company.SET_COMPANY,
        payload: {
          ...updatedCompany,
          ...companyInfo,
          [tableColumn]: key,
        },
      })
    } catch (error) {
      message.error(error?.message)
      message.error(error?.response?.message)
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: company.SET_LOADING,
        payload: { uploadFile: false },
      })
    }
  }

export const deleteFile =
  (params, companyInfo) => async (dispatch, getState) => {
    try {
      const { key, tableColumn } = params
      const companyId = getState()?.auth?.user?.companyId
      await deletee({
        subUrl: `/company/delete-file/${companyId}`,
        params: {
          key,
        },
      })
      const { data: updatedCompany } = await put({
        subUrl: `/company/${companyId}`,
        data: { [tableColumn]: null },
      })
      dispatch({
        type: company.SET_COMPANY,
        payload: {
          ...updatedCompany,
          ...companyInfo,
          [tableColumn]: null,
        },
      })
      message.success('File deleted successfully.')
    } catch (error) {
      message.error(error?.message)
      message.error(error?.response?.message)
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: company.SET_LOADING,
        payload: { uploadFile: false },
      })
    }
  }

export const getCompanySubscription = () => async (dispatch) => {
  try {
    const { data } = await get({
      subUrl: '/company/subscription',
    })
    dispatch({
      type: company.SET_COMPANY_SUBSCRIPTION,
      payload: data.data,
    })
    return data.data
  } catch (error) {
    return null
  }
}

export const clearSubscription = () => (dispatch) => {
  return dispatch({
    type: company.SET_COMPANY_SUBSCRIPTION,
    payload: null,
  })
}

export const followCompany = (companyId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: company.SET_LOADING,
      payload: { followCompany: true },
    })
    await post({
      subUrl: `/company/follow/${companyId}`,
    })
    const { data } = await get({ subUrl: `/contacts/${companyId}` })
    dispatch({
      type: directory.SET_CONTACT_INFO,
      payload: data.data,
    })
  } catch (error) {
    message.error(error?.message)
    message.error(error?.response?.message)
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: company.SET_LOADING,
      payload: { followCompany: false },
    })
  }
}

export const unFollowCompany = (companyId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: company.SET_LOADING,
      payload: { followCompany: true },
    })
    await post({
      subUrl: `/company/unfollow/${companyId}`,
    })
    const { data } = await get({ subUrl: `/contacts/${companyId}` })
    dispatch({
      type: directory.SET_CONTACT_INFO,
      payload: data.data,
    })
  } catch (error) {
    message.error(error?.message)
    message.error(error?.response?.message)
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: company.SET_LOADING,
      payload: { followCompany: false },
    })
  }
}

export const getMembers = ({
  companyId,
  page,
  search
}) => async (dispatch) => {
  try {
    dispatch({
      type: company.SET_LOADING,
      payload: { getMembers: true },
    })
    const { data } = await get({
      subUrl: `/company/members/${companyId}`,
      params: {
        page,
        search
      }
    })
    dispatch({
      type: company.SET_MEMBERS,
      payload: data
    })
    return data
  } catch (error) {
    message.error(error?.message)
    message.error(error?.response?.message)
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: company.SET_LOADING,
      payload: { getMembers: false },
    })
  }
}

export const saveCompanyCoverPhoto = (companyId, coverImageKey) => async (dispatch) => {
  try {
    return put({
      subUrl: `/company/${companyId}`,
      data: {
        cover_image_key: coverImageKey
      }
    })
  } catch (error) {
    message.error(error?.message)
    message.error(error?.response?.message)
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const setSubscriptionPrompt = (payload) => (dispatch) => {
  dispatch({
    type: company.SET_SUBSCRIPTION_PROMPT,
    payload
  })
}
