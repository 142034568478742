import { communityType } from '../types'

const initialAuthState = {
  community: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  page: 1,
  perPage: 10,
  searchCommunities: '',
  tab: 'Discover Communities',
  community_detail: [],
  modal: {
    editCommunity: false,
    shareCommunity: false,
  },
  loading: {
    getCommunityList: false,
    getCommunityDetails: false,
  },
  users: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  inviteUsers: {
    count: 0,
    totalPages: 0,
    currentPage: 0,
    rows: [],
  },
}

const getPayload = (payload, value, isInvite = false) => {
  if (isInvite) {
    return {
      count: payload?.count || 0,
      rows:
        payload?.currentPage === 1
          ? [...payload?.rows]
          : [...value.rows, ...payload?.rows],
      currentPage: payload?.currentPage || 0,
      totalPages: payload?.totalPages || 0,
    }
  } else {
    return {
      count: payload?.count || 0,
      rows:
        payload.page === 1
          ? [...payload?.contacts]
          : [...value.rows, ...payload?.contacts],
      totalPages: payload?.totalPages || 0,
    }
  }
}

const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case communityType.LIST_COMMUNITY: {
      return {
        ...state,
        community: {
          count: action?.payload?.count,
          rows: action?.payload?.communities,
        },
      }
    }
    case communityType.SET_CURRENT_PAGE: {
      return { ...state, page: action?.payload }
    }
    case communityType.SET_SEARCH_TEXT: {
      return { ...state, searchCommunities: action?.payload }
    }
    case communityType.SET_PER_PAGE: {
      return { ...state, perPage: action?.payload, page: 1 }
    }
    case communityType.SET_TAB: {
      return { ...state, tab: action?.payload }
    }
    case communityType.SET_MODAL: {
      const modal = {}
      modal[action.payload.modal] = action.payload.value
      return { ...state, modal: { ...state.modal, ...modal } }
    }
    case communityType.SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }
    }
    case communityType.COMMUNITY_DETAIL: {
      const newLocal = { ...state, community_detail: action.payload }
      return newLocal
    }
    case communityType.RESET_COMMUNITY_DETAILS: {
      const newLocal = {
        ...state,
        community_detail: initialAuthState.community_detail,
      }
      return newLocal
    }
    case communityType.SET_MEMBERS: {
      return { ...state, users: getPayload(action?.payload, state?.users) }
    }
    case communityType.RESET_MEMBERS: {
      return { ...state, users: initialAuthState.users }
    }
    case communityType.RESET_INVITE_USERS: {
      return {
        ...state,
        inviteUsers: initialAuthState.inviteUsers,
      }
    }
    case communityType.SET_INVITE_USERS: {
      return {
        ...state,
        inviteUsers: getPayload(action?.payload, state.inviteUsers, true),
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
