import { bid } from '../types'
import { message } from 'helpers/message'
import {
  convertToForm,
  deletee,
  get,
  getErrorMessage,
  post,
  put,
  uploadToPresignedUrl,
} from 'api'
import { arrayToObj, BID_SWIMLANE, ORDER } from 'helpers/common'
import { routes, history } from 'routes/config'

export const addToBidBoard = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { addToBidBoard: true },
    })
    const { data } = await post({ subUrl: '/bid', data: params })
    message.success(data.message)

    if (history.location.pathname === routes.opportunities.path) {
      history.push(routes.bidboard.path)
    } else {
      const { bid } = getState()

      refetchAllView(bid?.view)(dispatch, getState)
    }
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { addToBidBoard: true },
    })
  }
}

export const getBidsAssignedToMe = () => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getMyBids: true },
    })
    const { data } = await get({
      subUrl: '/bid/my-bids',
    })
    dispatch({
      type: bid.SET_MY_BIDS,
      payload: {
        count: data.count,
        bids: data.bids
      }
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getMyBids: false },
    })
  }
}

export const getBids = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getBids: true },
    })
    const { data } = await get({
      subUrl: '/bid',
      params: { ...params, isListView: true },
    })
    dispatch({
      type: bid.SET_BIDS,
      payload: { bids: data.data.bids, total: data.data.count },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getBids: false },
    })
  }
}

export const getBidInfo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: {
        getBidInfo: true,
      },
    })
    const { data } = await get({ subUrl: `/bid/profile/${id}` })
    dispatch({
      type: bid.SET_BID_INFO,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: {
        getBidInfo: false,
      },
    })
  }
}

export const setBidInfo = (info) => (dispatch) => {
  dispatch({
    type: bid.SET_BID_INFO,
    payload: info,
  })
}

/**
 *
 * @param {{ search: string; bidId:number; }} params
 */
export const getTeamsDropDown = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getTeamsDropDown: true },
    })
    const { data } = await get({ subUrl: '/bid/assigned/teams', params })

    dispatch({
      type: bid.SET_EDIT_TEAM_DROPDOWN,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getTeamsDropDown: true },
    })
  }
}

/**
 *
 * @param {{ bidId:number; teamId:number; }} params
 */
export const removeTeam = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { removeTeam: true },
    })
    await deletee({ subUrl: '/bid/assigned/teams', data: params })
    await getBidInfo(params.bidId)(dispatch)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { removeTeam: false },
    })
  }
}

/**
 *
 * @param {{bidId:number; teamIdArray:number[]; }} params
 */
export const addTeam = (params) => async (dispatch) => {
  try {
    const { bidId, teamIdArray } = params
    dispatch({
      type: bid.SETLOADING,
      payload: { getTeamsDropDown: true },
    })
    await Promise.all(
      teamIdArray.map((t) =>
        post({ subUrl: '/bid/assigned/teams', data: { bidId, teamId: t } })
      )
    )
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    await getBidInfo(params.bidId)(dispatch)
    dispatch({
      type: bid.SETLOADING,
      payload: { getTeamsDropDown: true },
    })
  }
}

/**
 *
 * @param {{bidId:string; companyIds:number[]; search:string }} params
 */
export const getMembersDropDown = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getMembersDropDown: true },
    })
    const { data } = await get({
      subUrl: '/bid/assigned/members',
      params: params,
    })
    dispatch({
      type: bid.SET_EDIT_MEMBER_DROPDOWN,
      payload: data.data,
    })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getMembersDropDown: false },
    })
  }
}

/**
 *
 * @param {{bidId:string; companyIds:number[]; search:string }} params
 */
export const getBidMembers = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getBidMembers: true },
    })
    const { data } = await get({
      subUrl: '/bid/assigned/members/all',
      params: params,
    })
    dispatch({
      type: bid.SET_BID_MEMBERS,
      payload: data.data,
    })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getBidMembers: false },
    })
  }
}

/**
 *
 * @param {{bidId:number; userId:number;}} params
 */
export const removeMember = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { removeMember: true },
    })
    await deletee({ subUrl: '/bid/assigned/members', data: params })
    await getBidInfo(params.bidId)(dispatch)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { removeMember: false },
    })
  }
}

/**
 *
 * @param {{bidId:number; userIdArray:number}} params
 */
export const addMember = (params) => async (dispatch) => {
  const { userIdArray, bidId } = params
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { addMember: true },
    })
    await Promise.all(
      userIdArray.map((id) =>
        post({
          subUrl: '/bid/assigned/members',
          data: { bidId: bidId, userId: id },
        })
      )
    )
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    getBidInfo(params.bidId)(dispatch)
    dispatch({
      type: bid.SETLOADING,
      payload: { addMember: false },
    })
  }
}

/**
 *
 * @param {{ bidId:number; proposalType:string; otherProposalType:string; link:string; }} params
 */
export const addProposalDocument = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { addProposalDocument: true },
    })
    await post({ subUrl: '/bid/documents', data: params })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { addProposalDocument: false },
    })
  }
}

/**
 *
 * @param {{ documentId:number; proposalType:string; otherProposalType:string; link:string; }} params
 */
export const editProposalDocument = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { editProposalDocument: true },
    })
    await put({ subUrl: '/bid/documents', data: params })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { editProposalDocument: false },
    })
  }
}

/**
 *
 * @param {{ documentId:number; }} params
 */
export const deleteProposalDocument = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteProposalDocument: true },
    })
    await deletee({ subUrl: '/bid/documents', params })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteProposalDocument: false },
    })
  }
}

/**
 *
 * @param {{ bidId:number; page:number; perPage:number; }} params
 */
export const getProposalDocuments = (params) => async (dispatch) => {
  try {
    if (!params.page || !params.perPage) {
      params.page = 0
      params.perPage = 0
    }
    dispatch({
      type: bid.SETLOADING,
      payload: { addProposalDocument: true },
    })
    const { data } = await get({ subUrl: '/bid/documents', params })
    dispatch({
      type: bid.SET_PROPOSAL_DOC,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { addProposalDocument: false },
    })
  }
}

/**
 * @params {{bidId:number; link:string}}
 */
export const getLinks = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getLinks: true },
    })
    const { data } = await get({ subUrl: '/bid/links', params })
    dispatch({
      type: bid.SET_LINKS,
      payload: { links: data.data.bidLinks, count: data.data.count },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getLinks: false },
    })
  }
}

/**
 *
 * @param {{bidId:number; page:number; perPage:number; sortField:string; sortBy:string;}} params
 */
export const addLink = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { addLink: true },
    })
    const { data } = await post({ subUrl: '/bid/links', data: params })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { addLink: false },
    })
  }
}

export const deleteLink = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteLink: true },
    })
    await deletee({ subUrl: '/bid/links', params })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteLink: true },
    })
  }
}

export const getFiles = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getFiles: true },
    })
    const { data } = await get({ subUrl: '/bid/attachments', params })
    dispatch({
      type: bid.SET_ATTACHMENTS,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getFiles: false },
    })
  }
}
/**
 *
 * @param {{file:File; bidId: number}} params0
 */
export const uploadFile =
  ({ file, bidId }) =>
  async (dispatch) => {
    try {
      const fileName = file.name
      const { data: preSignData } = await get({
        subUrl: '/bid/attachments/presigned',
        params: { fileName },
      })

      const url = preSignData?.data?.url
      const key = preSignData?.data?.key
      await uploadToPresignedUrl(url, file)
      const { data } = await post({
        subUrl: '/bid/attachments ',
        data: {
          bidId,
          attachmentName: file.name,
          size: file.size,
          type: file.type,
          keyName: key,
        },
      })
      return data
    } catch (error) {
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
    }
  }

export const deleteAttachment = (params) => async (dispatch) => {
  try {
    const { data } = await deletee({ subUrl: '/bid/attachments/', params })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
  }
}

export const editBidBoard = (params) => async (dispatch) => {
  try {
    const { data } = await put({ subUrl: '/bid/profile', data: params })
    dispatch({
      type: bid.EDIT_BID_INFO,
      payload: data?.data,
    })
    if (params.swimlane && params.bidMatrixScore) {
      dispatch({
        type: bid.SET_BID_DECISION_MATRIX_MODAL_NEXT_SWIMLANE,
        payload: null,
      })
    }
    return true
  } catch (error) {
    if (error.response.status === 424 && params.swimlane) {
      dispatch({
        type: bid.SET_BID_DECISION_MATRIX_MODAL_NEXT_SWIMLANE,
        payload: { bidId: params.bidId, swimlane: params.swimlane },
      })
    }
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const setListViewParams = (params) => (dispatch) => {
  dispatch({
    type: bid.SET_LIST_VIEW_PARAMS,
    payload: params,
  })
}

export const setParams = (params) => (dispatch) => {
  dispatch({
    type: bid.SET_PARAMS,
    payload: params,
  })
}

export const bulkUpload = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { bulkUpload: true },
    })
    const { data, status } = await post({
      subUrl: '/bid/excel/import',
      data: convertToForm(params),
    })
    message.success(data.message)
    await refetchAllView()(dispatch, getState)
    return status === 200
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { bulkUpload: false },
    })
  }
}

export const getBulkUploadTemplate = async () => {
  try {
    const { data } = await get({ subUrl: '/bid/excel/export' })
    window.location = data.data
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const getTabularView = (params, swimlane) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getTabularView: true },
    })
    const { data } = await get({
      subUrl: '/bid',
      params: { ...params, isListView: false },
    })
    dispatch({
      type: bid.SET_TABULAR_VIEW,
      payload: { data: data.data, swimlane },
    })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getTabularView: false },
    })
  }
}

export const setTablularViewParams = (data, swimlane) => (dispatch) => {
  const { current, pageSize } = data[0]
  const { order, columnKey } = data[2]
  const sortBy = ORDER[order]
  const tableConfig = {
    page: current,
    perPage: pageSize,
    ...(sortBy && columnKey
      ? { sortField: columnKey, sortBy }
      : { sortField: '', sortBy: '' }),
  }
  dispatch({
    type: bid.SET_TABULAR_VIEW_PAGINATION,
    payload: { swimlane, tableConfig },
  })
}

export const setPageOne = () => (dispatch) => {
  Object.values(BID_SWIMLANE).forEach((swimlane) => {
    dispatch({
      type: bid.SET_TABULAR_VIEW_PAGINATION,
      payload: { swimlane, tableConfig: { page: 1 } },
    })
  })
  dispatch({
    type: bid.SET_LIST_VIEW_PARAMS,
    payload: { page: 1 },
  })
}

export const setBidDecisionMatrixPopup = (value) => (dispatch) => {
  dispatch({
    type: bid.SET_BID_DECISION_MATRIX_MODAL_NEXT_SWIMLANE,
    payload: value,
  })
}

export const getKanban = (params) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { getKanban: true },
    })
    const { data } = await get({
      subUrl: '/bid',
      params: {
        ...params,
        isListView: false,
        sortByIndex: true,
      },
    })
    dispatch({
      type: bid.SET_KANBAN_VIEW,
      payload: data.data,
    })
    return data.data
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { getKanban: false },
    })
  }
}

export const updateKanbanLane = (params) => (dispatch) => {
  dispatch({
    type: bid.UPDATE_KANBAN_LANE,
    payload: params,
  })
}
//Not using this as of now
export const loadMoreInKanban = async (params) => {
  try {
    const lane = params.lane
    delete params.lane
    const { data } = await get({
      subUrl: '/bid',
      params: {
        perPage: 10,
        isListView: false,
        sortField: 'updatedAt',
        sortBy: 'DESC',
        ...params,
      },
    })
    const list = arrayToObj(data.data, 'swimlaneName')
    return list[lane]?.bids
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const setBidView = (view) => (dispatch) => {
  dispatch({
    type: bid.SET_VIEW,
    payload: view,
  })
}

export const refetchAllView = (view) => async (dispatch, getState) => {
  const { bid } = getState()
  console.log('bid: ', bid)
  try {
    switch (view) {
      case 'list':
        getBids({ ...bid.bidParams, ...bid.listViewParams })(dispatch)
        break
      case 'table':
        getTabularView({ ...bid.bidParams })(dispatch)
        break
      case 'board':
        getKanban({ ...bid.bidParams })(dispatch)
        break
      default:
        getBids({ ...bid.bidParams, ...bid.listViewParams })(dispatch)
        getTabularView({ ...bid.bidParams })(dispatch)
        getKanban({ ...bid.bidParams })(dispatch)
        break
    }
  } catch (error) {
    console.log('error: ', error)
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
  }
}

export const deleteBid = (bidId) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteBid: false },
    })
    await deletee({
      subUrl: `/bid/profile/${bidId}`
    })
    
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { deleteBid: false },
    })
  }
}

export const archiveBid = (bidId) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { archiveBid: false },
    })
    await put({
      subUrl: `/bid/profile/archive/${bidId}`
    })

  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { archiveBid: false },
    })
  }
}

export const unarchiveBid = (bidId) => async (dispatch) => {
  try {
    dispatch({
      type: bid.SETLOADING,
      payload: { unarchiveBid: false },
    })
    await put({
      subUrl: `/bid/profile/unarchive/${bidId}`
    })

  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
    throw error
  } finally {
    dispatch({
      type: bid.SETLOADING,
      payload: { unarchiveBid: false },
    })
  }
}
