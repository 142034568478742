import { userProfileTypes } from "../types";

const initialAuthState = {
  data: {},
};
const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case userProfileTypes.GET_USER_PROFILE: {
      return { ...state, data: action.payload.data };
    }
    case userProfileTypes.ADD_FOLLOWER: {
      return { ...state, data: { ...state.data, ...{ is_following: true } } };
    }
    case userProfileTypes.REMOVE_FOLLOWER: {
      return { ...state, data: { ...state.data, ...{ is_following: false } } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
