import { archived } from '../types'

const initialState = {
  opportunities: [],
  opportunitiesCount: 0,
  contacts: [],
  contactsCount: 0,
  opportunitiesPagination: {
    page: 1,
    perPage: 10,
    sortField: '',
    sortBy: '',
  },
  contactsPagination: {
    page: 1,
    perPage: 10,
    sortField: '',
    sortBy: '',
  },
  globalConfig: {
    isAssignedToMe: false,
    recentlyUpdated: false,
    priority: [],
    search: '',
  },
  globalConfigContact: {
    isAssignedToMe: false,
    recentlyUpdated: false,
    search: '',
    contactType: [],
  },
  selectedBid: null,
  selectedContact: null,
  loading: {},
}
/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case archived.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case archived.SET_GLOBAL_CONFIG: {
      return {
        ...state,
        globalConfig: { ...state.globalConfig, ...action.payload },
        opportunitiesPagination: { ...state.opportunitiesPagination, page: 1 },
      }
    }

    case archived.SET_GLOBAL_CONFIG_CONTACT: {
      return {
        ...state,
        globalConfigContact: {
          ...state.globalConfigContact,
          ...action.payload,
        },
        contactsPagination: { ...state.contactsPagination, page: 1 },
      }
    }

    case archived.SET_OPPORTUNITY_PAGINATION: {
      return {
        ...state,
        opportunitiesPagination: {
          ...state.opportunitiesPagination,
          ...action.payload,
        },
      }
    }

    case archived.SET_CONTACTS_PAGINATION: {
      return {
        ...state,
        contactsPagination: {
          ...state.contactsPagination,
          ...action.payload,
        },
      }
    }

    case archived.SET_OPPORTUNITIES: {
      const { count, bids } = action.payload
      return {
        ...state,
        opportunities: bids,
        opportunitiesCount: count,
      }
    }

    case archived.SET_CONTACTS: {
      const { count, crmContacts } = action.payload
      return {
        ...state,
        contacts: crmContacts,
        contactsCount: count,
      }
    }

    case archived.SET_SELECTED_BID: {
      return {
        ...state,
        selectedBid: action.payload,
      }
    }

    case archived.SET_SELECTED_CONTACT: {
      return {
        ...state,
        selectedContact: action.payload,
      }
    }

    case archived.UNARCHIVE_OPPORTUNITY: {
      const { bidId } = action.payload
      return {
        ...state,
        opportunities: state.opportunities.filter((o) => o.id !== bidId),
        opportunitiesCount: state.opportunitiesCount - 1,
        selectedBid: null,
      }
    }

    case archived.UNARCHIVE_CONTACT: {
      const { crmContactId } = action.payload

      return {
        ...state,
        contacts: state.contacts.filter((o) => o.id !== crmContactId),
        contactsCount: state.contactsCount - 1,
        selectedContact: null,
      }
    }

    default: {
      return state
    }
  }
}
export default reducer
