import { pipelineTypes, contactTypes } from '../types'

const initialState = {
  pipelines: {},
  contacts: {},
  loading: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case pipelineTypes.SET_LOADING:
      return { ...state, loading: true }

    case contactTypes.SET_LOADING:
      return { ...state, loading: true }

    case pipelineTypes.SET_DETAILS:
      return { ...state, pipelines: action.payload.data, loading: false }

    case contactTypes.SET_DETAILS:
      return { ...state, contacts: action.payload.data, loading: false }

    default:
      return state
  }
}

// /**
//  *
//  * @param {initialState} state
//  * @param {{type:string; payload:any}} action
//  * @returns {initialState}
//  */

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case pipelineTypes.SET_LOADING: {
//       return { ...state, loading: true }
//     }

//     case pipelineTypes.SET_DETAILS: {
//       return { ...state, pipelines: action.payload.data, loading: false }
//     }

//     default: {
//       return state
//     }
//   }
// }

// export default reducer
