import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import './Footer.scss'
import { List } from 'antd/lib/form/Form'

function Footer() {
  return (
    <div className='FooterStyles'>
      <div className='footermainblock flexblock'>
        <Menu className='flexblock'>
          <Menu.Item>
            Copyright © 2020 <Link to=''>GovGenie</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to=''>Terms </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to=''>Privacy Policy</Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
}

export default Footer
