import moment from 'moment'
import { debounce } from 'lodash'
import { CONSTANTS, liveStreamURI } from 'helpers/common'

let timerCounter = null

export const checkSubscriptionPageAccess = (user) => {
  return (
    user?.user_type === CONSTANTS.USER_TYPES.INDIRECT &&
    user?.uOrg?.organization?.created_by === user?.id
  )
}

export const checkIndividualSubscription = (user) => {
  return (
    user?.user_type === CONSTANTS.USER_TYPES.INDIRECT &&
    user?.uOrg?.organization?.created_by === user?.id &&
    user?.uOrg?.organization?.OrgSubscription?.sub_plan?.plan_type ===
      CONSTANTS.SUBSCRIPTION_TYPE.INDIVIDUAL
  )
}

export const checkCompanyAdmin = (user) => {
  return (
    user?.user_type === CONSTANTS.USER_TYPES.INDIRECT &&
    user?.uOrg?.organization?.created_by === user?.id &&
    user?.uOrg?.organization?.OrgSubscription?.sub_plan?.plan_type ===
      CONSTANTS.SUBSCRIPTION_TYPE.COMPANY
  )
}

export const checkCompanyAdminNoType = (user) => {
  return (
    user?.user_type === CONSTANTS.USER_TYPES.INDIRECT &&
    user?.uOrg?.organization?.created_by === user?.id
  )
}

export const checkSubscriptionExpire = (date) => {
  return moment(date)?.unix() < moment()?.unix()
}

export const removeEmpty = (obj) => {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || val === undefined || val === '') && delete obj[key])
  )
  if (obj?.speciality_ids && obj?.speciality_ids?.length === 0)
    delete obj.speciality_ids
  if (obj?.postType && obj?.postType?.length === 0) delete obj.postType
  if (obj?.companyTypeIds && obj?.companyTypeIds?.length === 0)
    delete obj.companyTypeIds
  if (obj?.specialityIds && obj?.specialityIds?.length === 0)
    delete obj.specialityIds
  return obj
}

export const checkLiveStreamPost = (postId, posts) => {
  const data = posts.find((post) => post.id === postId)
  return data?.live_stream_id?.id ? true : false
}

export const addDefaultImage = (state, parentElement) => {
  if (
    state === false &&
    document.getElementById(parentElement) &&
    !document.getElementsByClassName(`${parentElement}__image`).length
  ) {
    var img = document.createElement('img')
    img.setAttribute('src', liveStreamURI)
    img.classList.add(`${parentElement}__image`)

    document.getElementById(parentElement).appendChild(img)
  }
}

export const muteStream = (state) => {
  let players = document.querySelectorAll('video')
  players.forEach(function (player) {
    player.muted = state
  })
}

export const checkMicCamera = async (device) => {
  let data
  navigator.getWebcam =
    navigator.getUserMedia ||
    navigator.webKitGetUserMedia ||
    navigator.moxGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  if (navigator.mediaDevices.getUserMedia) {
    try {
      data = await navigator.mediaDevices.getUserMedia({
        audio: device === 'audio' ? true : false,
        video: device === 'video' ? true : false,
      })
    } catch (e) {
      data = false
    }
  } else {
    try {
      data = await navigator.getWebcam({
        audio: device === 'audio' ? true : false,
        video: device === 'video' ? true : false,
      })
    } catch (e) {
      data = false
    }
  }
  return data
}

// Video Stream Options
export const videoUnblockOptions = (cameraResult) => {
  return {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    publishAudio: false,
    publishVideo: cameraResult === false ? false : true,
    insertDefaultUI: cameraResult === false ? false : true,
    showControls: false,
  }
}

export const videoBlockOptions = (cameraResult) => {
  return {
    insertMode: 'append',
    width: '100%',
    height: '100%',
    publishAudio: false,
    publishVideo: cameraResult === false ? false : true,
    videoSource: null,
    insertDefaultUI: false,
    showControls: false,
  }
}

// Audio Stream Options
export const audioDefaultOptions = (micResult, audioInputSelect) => {
  return {
    publishAudio: micResult === false ? false : true,
    publishVideo: false,
    videoSource: null,
    audioSource: audioInputSelect,
    insertDefaultUI: false,
    showControls: false,
  }
}

export const openExternalLink = (link) => {
  window.open(link, '_blank')
}

export const liveStreamDisconnect = (
  status,
  endLiveStream,
  handleSound = null
) => {
  if (status) {
    timerCounter = setTimeout(endLiveStream, 60000)
  } else {
    if (timerCounter) {
      clearTimeout(timerCounter)
      timerCounter = null
      handleSound()
    }
  }
}

// helper function to get file type before uploading
export const getFileType = (type) => {
  if (type.startsWith('image')) {
    return 'postImage'
  } else if (type.startsWith('video')) {
    return 'postVideo'
  } else if (type.startsWith('application')) {
    return 'postDocument'
  } else return null
}

// helper function to download file and rename it based on filename
export const downloadFile = debounce((urlToSend, name) => {
  let req = new XMLHttpRequest()
  req.open('GET', urlToSend, true)
  req.responseType = 'blob'
  req.onload = function (event) {
    let blob = req.response
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = name
    link.click()
  }
  req.send()
}, 1000)

export const checkSafariBrowser = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

// helper function to check user can ediy org page
export const checkUserEditRights = (user, page) => {
  return (
    user?.id === page?.created_by &&
    user?.uOrg?.role === CONSTANTS.ORG_USER_TYPES.ADMIN
  )
}

// pick property to display on pricing page
export const pickProperty = (items) => {
  let finalData = items.map((item, index) => {
    switch (index) {
      case 0:
        return (
          <li key={index}>
            {items.find((item) => item.title === 'Posts').count} Posts
          </li>
        )
      case 1:
        return (
          <li key={index}>
            {items.find((item) => item.title === 'Polls').count} Poll
          </li>
        )
      case 2:
        return (
          <li key={index}>
            {items.find((item) => item.title === 'Events').count} Events
          </li>
        )
      case 3:
        return (
          <li key={index}>
            {items.find((item) => item.title === 'Live Stream').count} Live
            Streams
          </li>
        )
      case 4:
        return (
          <li key={index}>
            {items.find((item) => item.title === 'Users').count} Users
          </li>
        )
      default:
        return <li key={index}>N/A</li>
    }
  })

  return finalData
}

// get other specialities in one string
export const getOtherSpeciality = (lists) => {
  let specialityList = null
  lists?.forEach((list, index) => {
    if (index === 0) specialityList = list?.speciality
    else specialityList += ', ' + list?.speciality
  })
  return specialityList
}
