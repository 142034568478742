import React from "react";
import "./SearchDropdown.scss";
import "./SearchDropdown.scss";
import LastSearchItem from "./LastSearchItem";
import SuggestionItem from "./SuggestionItem";
import { connect } from "react-redux";
import { clearLastSearch } from "store/action/search";

function SearchDropdown({
  search,
  users,
  clearLastSearch,
  hints,
  searchGlobally,
}) {
  if (search === "") {
    return (
      <LastSearchItem
        users={hints}
        clearUsers={clearLastSearch}
        searchGlobally={searchGlobally}
      />
    );
  } else {
    return (
      <SuggestionItem
        users={users}
        searchGlobally={() => searchGlobally(search)}
      />
    );
  }
}

const mapStateToProps = ({ search }) => ({
  users: search.searchDropDownUsers,
  hints: search.searchDropDownRecentSearch,
});

export default connect(mapStateToProps, { clearLastSearch })(SearchDropdown);
