import { arrayToObj, CRM_RELATIONSHIP_STAGE } from 'helpers/common'
import { tabularViewDefaultPagination } from 'helpers/const'
import { crm } from '../types'

const tabularViewConfig = {}
Object.values(CRM_RELATIONSHIP_STAGE).forEach((swimlane) => {
  tabularViewConfig[swimlane] = {
    ...tabularViewDefaultPagination,
    sortBy: '',
    sortField: '',
  }
})

const initialState = {
  filters: {
    isAssignedToMe: false,
    recentlyUpdated: false,
    contactType: [],
    search: '',
  },
  listView: [],
  listViewCount: 0,

  listViewParams: {
    ...tabularViewDefaultPagination,
    sortField: '',
    sortBy: '',
  },
  tabularView: {},
  tabularViewParams: tabularViewConfig,
  kanbanView: {},
  view: 'board',
  loading: {
    getHistory: false,
  },
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case crm.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case crm.SET_TABULAR_VIEW_PAGINATION: {
      const { relationshipStage, tableConfig } = action.payload || {}
      return {
        ...state,
        tabularViewParams: {
          ...state.tabularViewParams,
          [relationshipStage]: {
            ...state.tabularViewParams[relationshipStage],
            ...tableConfig,
          },
        },
      }
    }

    case crm.SET_TABULAR_VIEW: {
      const { relationshipStage, data } = action.payload
      const list = arrayToObj(data, 'relationshipStage')
      if (!relationshipStage) {
        return {
          ...state,
          tabularView: list,
        }
      } else {
        return {
          ...state,
          tabularView: {
            ...state.tabularView,
            [relationshipStage]: list[relationshipStage],
          },
        }
      }
    }

    case crm.SET_LIST_VIEW: {
      const { count, crmContacts } = action.payload
      return {
        ...state,
        listView: crmContacts,
        listViewCount: count,
      }
    }

    case crm.SET_LIST_VIEW_PAGINATION: {
      return {
        ...state,
        listViewParams: { ...state.listViewParams, ...action.payload },
      }
    }

    case crm.SET_BOARD_VIEW: {
      const kanban = action.payload.map((i) => ({
        id: i.relationshipStage,
        ...i,
        cards: i.crmContacts,
        crmContacts: undefined,
      }))
      const list = arrayToObj(kanban, 'relationshipStage')
      const sortedList = {}
      Object.values(CRM_RELATIONSHIP_STAGE).forEach(
        (i) => (sortedList[i] = list[i])
      )
      return {
        ...state,
        kanbanView: sortedList,
      }
    }

    case crm.EDIT_CONTACT_INFO: {
      return state
    }

    case crm.SET_GLOBAL_CONFIG: {
      const tabularViewParams = {}
      Object.values(CRM_RELATIONSHIP_STAGE).forEach((k) => {
        tabularViewParams[k] = { ...state.tabularViewParams[k], page: 1 }
      })

      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        listViewParams: { ...state.listViewParams, page: 1 },
        tabularViewParams,
      }
    }

    case crm.UPDATE_KANBAN_LANE: {
      const {
        sourceLaneId,
        targetLaneId,
        card,
        index: bidIndex,
        position,
      } = action.payload

      let insertAt = position
      if (targetLaneId === sourceLaneId) {
        let lane = state.kanbanView[sourceLaneId]?.cards
        let indexOfCard = lane.findIndex((d) => d.id === card.id)
        let filtered = lane.filter((d, i) => {
          return d.id !== card.id
        })

        if (position > indexOfCard) {
          insertAt += 1
        }

        filtered.splice(insertAt, 0, { ...card, bidIndex })

        return {
          ...state,
          kanbanView: {
            ...state.kanbanView,
            [targetLaneId]: {
              ...state.kanbanView[sourceLaneId],
              cards: [...filtered],
            },
          },
        }
      }
      return {
        ...state,
        kanbanView: {
          ...state.kanbanView,
          [sourceLaneId]: {
            ...state.kanbanView[sourceLaneId],
            count: state.kanbanView[sourceLaneId].count - 1,
            cards: [
              ...state.kanbanView[sourceLaneId].cards.filter(
                (c) => c.id.toString() !== card.id.toString()
              ),
            ],
          },
          [targetLaneId]: {
            ...state.kanbanView[targetLaneId],
            count: state.kanbanView[targetLaneId].count + 1,
            cards: [
              ...state.kanbanView[targetLaneId].cards.slice(0, position),
              { ...card, bidIndex },
              ...state.kanbanView[targetLaneId].cards.slice(position),
            ],
          },
        },
      }
    }

    case crm.SET_VIEW: {
      return {
        ...state,
        view: action.payload,
      }
    }

    case crm.RESET: {
      return initialState
    }

    case crm.RESET_ALL: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default reducer
