import React from 'react'
import { Spin } from 'antd'
import './Loader.scss'

const Loader = ({ loading = true, wrapperClass = '', ...otherProp }) => {
  return (
    <div className={`LoaderStyles ${wrapperClass}`}>
      {loading && <Spin {...otherProp} />}
    </div>
  )
}

export default Loader
