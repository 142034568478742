import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { routes } from 'routes/config'
import { getDueOpportunities } from 'store/action/opportunity'
import SectionLoader from 'components/Global/SectionLoader/SectionLoader'

import moment from 'moment'
import NoData from 'components/Global/NoData/NoData'
import { normalizeParams, truncateText } from 'helpers/common'
import './DueOpportunities.scss'

const DueOpportunities = ({
  list,
  count,
  getDueOpportunities,
  loading,
}) => {
  useEffect(() => {
    getDueOpportunities({
      tableType: 'federal',
      params: {
        perPage: 5
      }
    })
  }, [getDueOpportunities])
  return (
    <div className='DueOpportunityStylePanel'>
      <div className='panel__header'>
        <h4 className='panel__header__title'>Opportunities Due Soon</h4>
      </div>
      <div className='panel__body'>
        {loading ? (
          <Row>
            <Col span={24}>
              <SectionLoader height='100' />
            </Col>
          </Row>
        ) : (
          <>
            {list.length ? (
              <div className='panel__body__list'>
                {list.map((data, key) => (
                  <Link
                    to={routes.opportunities.path_string(
                      data.id
                    )}
                    className='panel__body__list-item'
                    key={key}
                  >
                    <h5 className='item-name'>
                      {truncateText(data.solicitationname, 50)}
                    </h5>
                    <div className='item-date'>
                      <CalendarOutlined />
                      <span>
                        {data.posteddate
                          ? 'Posted Date: ' +
                            moment(data.posteddate).format('MM/DD/YYYY')
                          : 'Posted Date: N/A'}
                      </span>
                    </div>
                    <div className='item-date'>
                      <CalendarOutlined />
                      <span>
                        {data.responsedate
                          ? 'Response Date: ' +
                            moment(data.responsedate).format('MM/DD/YYYY')
                          : 'Response Date: N/A'}
                      </span>
                    </div>
                    {/* <hr /> */}
                  </Link>
                ))}
                <Link
                  className='panel__see-more-link'
                  to={routes.opportunities.path}
                >
                  See More
                </Link>
              </div>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ opportunity }) => ({
  list: opportunity.dueOpportunities.list,
  count: opportunity.dueOpportunities.count,
  loading: opportunity.loading.getDueOpportunities,
})

export default connect(mapStateToProps, {
  getDueOpportunities,
})(DueOpportunities)
