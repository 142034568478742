import React, { useEffect } from "react";
import { Menu } from "antd";
import "./GSSidebar.scss";
import language from "helpers/language";
import { CONSTANTS } from "helpers/const";
import { setTabs } from "store/action/search";
import { history, routes } from "routes/config";
import { connect } from "react-redux";

function GSSidebar({ lang, tab, setTabs, filter }) {
  useEffect(() => {
    if (filter?.search?.charAt(0) === "#") {
      setTabs("content");
    } else {
      setTabs(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.search]);
  const handleMenuClick = (key) => {
    if (['opportunities', 'awards', 'contractordirectory'].includes(key)) {
      return history.push(`${routes[key].path}?search=${filter.search}`)
    }
    return setTabs(key)
  }
  return (
    <div className="GSSidebarStyles">
      <Menu
        onClick={(e) => handleMenuClick(e.key)}
        mode="inline"
        // defaultSelectedKeys={tab}
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={tab}
      >
        <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.people}>{lang.people}</Menu.Item>
        {/* <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.group}>{lang.groups}</Menu.Item> */}
        <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.content}>{lang.content}</Menu.Item>
        <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.contractordirectory}>{lang.directory}</Menu.Item>
        <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.opportunities}>{lang.opportunities}</Menu.Item>
        <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.awards} hr>{lang.awards}</Menu.Item>
        {/* <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.event}>{lang.events}</Menu.Item> */}
        {/* <Menu.Item key={CONSTANTS.GLOBAL_SEARCH_MENU.pages}>{lang.pages}</Menu.Item> */}
      </Menu>
    </div>
  );
}

const mapStateToProps = ({ search }) => ({
  tab: search.tab,
  filter: search.filter,
});

export default connect(mapStateToProps, { setTabs })(
  language(["sidebarMenu"])(GSSidebar)
);
