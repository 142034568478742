import React from 'react'
import { CONSTANTS } from 'helpers/const'
import UserSidebarCard from './UserSidebarCard/UserSidebarCard'
import QuickMenu from './QuickMenu/QuickMenu'
import GSSidebar from 'components/GlobalSearch/GSSidebar/GSSidebar'
import TrackedOpportunities from './TrackedOpportunities/TrackedOpportunities'
import DueOpportunities from './DueOpportunities/DueOpportunities'
import './Leftsidebar.scss'

const LeftSidebar = ({ type }) => {
  return (
    <div className="left-sidebar">
      {
        type === CONSTANTS.ROUTE_SIDEBAR.FEED && (
          <>
            <UserSidebarCard/>
            <QuickMenu/>
            <TrackedOpportunities/>
            <DueOpportunities/>
          </>
        )
      }
      {
        type === CONSTANTS.ROUTE_SIDEBAR.GLOBAL_SEARCH && <GSSidebar/>
      }
    </div>
  )
}

export default LeftSidebar

