import { award } from '../types'
import awardColumns from 'helpers/awardColumns.json'
// import stateColumns from 'helpers/stateOpportunityColumns.json'

const columnOrder = [
  'action_date_fiscal_year',
  'action_date',
  'awarding_agency_name',
  'awarding_sub_agency_name',
  'awarding_office_name',
]

const initialState = {
  awards: [],
  getAwardsParams: {
    page: 1,
    perPage: 10,
  },
  tableColumns: awardColumns.sort((a, b) => {
    const indexA = columnOrder.indexOf(a.dataIndex)
    const indexB = columnOrder.indexOf(b.dataIndex)
    if (indexA === -1) {
      return 1 // move object with unknown dataIndex to end of array
    } else if (indexB === -1) {
      return -1 // move object with unknown dataIndex to beginning of array
    }
    return indexA - indexB
  }),
  count: 0,
  selected: null,
  showAwardModal: null,
  loading: {
    getAwards: false,
    loadingAnalytics: false,
  },
  analytics: {
    chartAnalytics: {},
    offers: {},
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case award.SET: {
      const { awards = [], count } = action.payload
      return { ...state, awards, count }
    }
    case award.SET_SELECTED: {
      const { selected } = action.payload
      return { ...state, selected }
    }
    case award.SET_PARAMS: {
      return {
        ...state,
        getAwardsParams: {
          ...state.getAwardsParams,
          ...action.payload,
        },
      }
    }
    case award.OPEN_AWARD_MODAL: {
      return {
        ...state,
        showAwardModal: action.payload,
      }
    }
    case award.SET_TABLE_COLUMNS: {
      const { value } = action.payload
      return {
        ...state,
        tableColumns: value,
      }
    }
    case award.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case award.SET_ANALYTICS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          ...action.payload,
        },
      }
    }
    case award.RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
