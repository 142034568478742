import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import en from "i18n/en";
import fr from "i18n/fr";
import _ from "lodash";

const languages = {
  en,
  fr,
};

function translate(keys = []) {
  return (Component) => {
    const TranslationComponent = (props) => {
      let s = {};
      keys.forEach((element) => {
        _.merge(s, languages[props.language][element]);
      });

      return <Component {...props} lang={s} />;
    };
    const mapStateToProps = ({ general }) => ({
      language: general.language,
    });
    return connect(mapStateToProps, "")(TranslationComponent);
  }; 
}

export default translate;
