import { plans } from '../types'

const initialState = {
  plans: [],
  plan: null,
  loading: {
    getPlans: false,
  },
  addonCheckoutModal: null,
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case plans.SET_PLANS: {
      return {
        ...state,
        plans: action.payload.plans,
      }
    }
    case plans.SET_PLAN: {
      return {
        ...state,
        plan: action.payload.plan,
      }
    }
    case plans.SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }
    }
    case plans.SET_ADDON_CHECKOUT_MODAL: {
      return {
        ...state,
        addonCheckoutModal: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
export default reducer
