import { support } from '../types'

const initialState = {
  modalIsVisible: false,
  loading: false
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case support.OPEN_MODAL: {
      return {
        ...state,
        modalIsVisible: action.payload,
      }
    }
    
    case support.SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    default: {
      return state
    }
  }
}
export default reducer
