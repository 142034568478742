import { combineReducers } from 'redux'
import auth from './auth'
import forgetpass from './forgetpass'
import opportunity from './opportunity'
import trackedOpportunity from './trackedOpportunity'
import company from './company'
import team from './team'
import config from './config'
import bid from './bid'
import tablefilter from './tablefilter'
import history from './history'
import bidcomments from './bidcomments'
import teamingPortal from './teamingPortal'
import archived from './archived'
import teamComments from './teamcomments'
import resources from './resources'
import award from './award'
import directoryfilter from './directoryfilter'
import directory from './directory'
import teamMemberDropdown from './teamMemberDropdown'
import saveSearch from './saveSearch'
import saved from './saved'
import crm from './crm'
import crmInfo from './crminfo'
import communityResource from './communityResource'
import posts from './posts'
import general from './general'
import userProfile from './userProfile'
import support from './support'
import search from './search'
import network from './network'
import myMessages from './myMessages'
import community from './community'
import events from './events'
import plan from './plan'
import pipeline from './dashboard'
import setAside from './setAside'

export const rootReducer = combineReducers({
  auth,
  forgetpass,
  opportunity,
  trackedOpportunity,
  company,
  team,
  config,
  bid,
  tablefilter,
  history,
  saved,
  support,
  bidcomments,
  teamingPortal,
  archived,
  teamComments,
  award,
  resources,
  directoryfilter,
  communityResource,
  directory,
  posts,
  userProfile,
  general,
  teamMemberDropdown,
  saveSearch,
  crm,
  crmInfo,
  search,
  network,
  myMessages,
  community,
  events,
  plan,
  pipeline,
  setAside
})
