import { myMessages } from "../types";

const initialAuthState = {
  messageCount: 0,
};

const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case myMessages.SET_MESSAGE_COUNT: {
      return { ...state, messageCount: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
