import { team } from '../types'

const initialState = {
  team: [],
  internalteam: [],
  internalteamCount: 0,
  vendors: [],
  vendorsCount: 0,
  teamCount: 0,
  getTeamParams: {
    search: '',
    page: 1,
    perPage: 100,
  },
  invitedEmployee: [],
  invitedEmployeeCount: 0,
  getInvitedEmployeeParams: {
    search: '',
    page: 1,
    perPage: 100,
  },
  makeAdminModalData: null,
  removeModalData: null,
  leaveModalData: null,
  manageMemberApprovalModal: null,
  onlyAdmin: false,
  inviteModalData: false,
  loading: {
    getTeam: false,
    inviteToTeam: false,
    invitedEmployee: false,
    reInvite: false,
    leave: false,
    remove: false,
    makeAdmin: false,
    removeInvite: false,
  },
}
/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case team.SETSTATE: {
      return { ...state, ...action.payload }
    }
    case team.REMOVE: {
      const id = action.payload
      return {
        ...state,
        removeModalData: null,
        team: state.team.filter((item) => item.id !== id),
        teamCount: state.teamCount - 1,
      }
    }
    case team.REMOVE_INVITE: {
      const id = action.payload
      return {
        ...state,
        invitedEmployee: state.invitedEmployee.filter((item) => item.id !== id),
      }
    }
    case team.ADD_INVITE: {
      return {
        ...state,
        invitedEmployee: [action.payload, ...state.invitedEmployee],
      }
    }
    case team.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case team.RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
