import { CONSTANTS } from "helpers/common";
import { routes } from "routes/config";

export const notificationReirection = (notification, history, location) => {
  const redirect = (path) =>
    location?.pathname === path ? false : history.push(path);
  switch (notification?.type) {
    case CONSTANTS.NOTIFICATION_TYPES.like:
    case CONSTANTS.NOTIFICATION_TYPES.comment:
    case CONSTANTS.NOTIFICATION_TYPES.share:
    case CONSTANTS.NOTIFICATION_TYPES.livePost:
    case CONSTANTS.NOTIFICATION_TYPES.pollVoted:
      return redirect(routes.feedDetail.path_string(notification?.post_id));
    case CONSTANTS.NOTIFICATION_TYPES.follow:
      return redirect(routes.userProfile.path_string(notification?.user_id));
    // case CONSTANTS.NOTIFICATION_TYPES.joinedGroup:
    // case CONSTANTS.NOTIFICATION_TYPES.groupInvite:
    // case CONSTANTS.NOTIFICATION_TYPES.groupJoinRequest:
    // case CONSTANTS.NOTIFICATION_TYPES.rejectGroupRequest:
    // case CONSTANTS.NOTIFICATION_TYPES.acceptGroupRequest:
    // case CONSTANTS.NOTIFICATION_TYPES.acceptGroupInvite:
    // case CONSTANTS.NOTIFICATION_TYPES.rejectGroupInvite:
    //   return redirect(routes.groupDetails.path_string(notification?.group_id));
    // case CONSTANTS.NOTIFICATION_TYPES.eventInvite:
    // case CONSTANTS.NOTIFICATION_TYPES.eventJoinRequest:
    // case CONSTANTS.NOTIFICATION_TYPES.eventInviteAccept:
    // case CONSTANTS.NOTIFICATION_TYPES.attendEvent:
    // case CONSTANTS.NOTIFICATION_TYPES.eventRequestAccept:
    // case CONSTANTS.NOTIFICATION_TYPES.eventRequestReject:
    //   return redirect(routes.eventDetails.path_string(notification?.event_id));
    default: {
      return false;
    }
  }
};
