import { arrayToObj, BID_SWIMLANE, getPositions } from 'helpers/common'
import { tabularViewDefaultPagination } from 'helpers/const'
import { bid } from '../types'

const tabularViewConfig = {}
Object.values(BID_SWIMLANE).forEach((swimlane) => {
  tabularViewConfig[swimlane] = {
    ...tabularViewDefaultPagination,
    sortBy: '',
    sortField: '',
  }
})

const initialState = {
  bids: [],
  listViewParams: {
    ...tabularViewDefaultPagination,
    sortField: '',
    sortBy: '',
  },
  tabularView: [],
  tabularViewParams: tabularViewConfig,
  total: 0,
  kanbanView: [],
  myBids: {
    bids: [],
    count: 0,
  },
  bidParams: {
    isAssignedToMe: false,
    recentlyUpdated: false,
    priority: [],
    search: '',
    status: [],
  },
  bidInfo: null,
  editTeamDropDown: [],
  editMemberDropDown: [],
  bidMembers: [],
  proposalDocuments: [],
  proposalDocumentsCount: 0,
  links: [],
  linksCount: 0,
  attachments: [],
  attachmentsCount: 0,
  loading: {
    addToBidBoard: false,
    getBids: false,
    getBidInfo: false,
    removeTeam: false,
    getTeamsDropDown: false,
    getMembersDropDown: false,
    getBidMembers: false,
    addProposalDocument: false,
    deleteBid: false,
    archiveBid: false,
    unarchiveBid: false,
  },
  bidDecisionMatrixModalNextSwimlane: null,
  view: 'board',
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case bid.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case bid.SET_BIDS: {
      const { bids, total, fetchMore = false } = action.payload
      if (fetchMore) {
        return { ...state, bids: [...state.bids, bids], total }
      }
      return { ...state, bids, total }
    }

    case bid.SET_PARAMS: {
      return { ...state, bidParams: { ...state.bidParams, ...action.payload } }
    }

    case bid.SET_MY_BIDS: {
      return { ...state, myBids: { ...action.payload } }
    }

    case bid.SET_BID_MEMBERS: {
      return { ...state, bidMembers: action.payload }
    }

    case bid.SET_LIST_VIEW_PARAMS: {
      return {
        ...state,
        listViewParams: { ...state.listViewParams, ...action.payload },
      }
    }

    case bid.SET_BID_INFO: {
      return { ...state, bidInfo: action.payload }
    }

    case bid.EDIT_BID_INFO: {
      return { ...state, bidInfo: { ...state.bidInfo, ...action.payload } }
    }

    case bid.UPDATE_ASSIGNED_TEAMS: {
      return {
        ...state,
        bidInfo: { ...state.bidInfo, assignedTeams: action.payload },
      }
    }
    case bid.UPDATE_ASSIGNED_MEMBER: {
      return {
        ...state,
        bidInfo: { ...state.bidInfo, assignedMembers: action.payload },
      }
    }
    case bid.SET_EDIT_MEMBER_DROPDOWN: {
      return { ...state, editMemberDropDown: action.payload }
    }
    case bid.SET_EDIT_TEAM_DROPDOWN: {
      return { ...state, editTeamDropDown: action.payload }
    }

    case bid.SET_PROPOSAL_DOC: {
      const { proposalDocuments, count } = action.payload
      return {
        ...state,
        proposalDocumentsCount: count,
        proposalDocuments: proposalDocuments,
      }
    }
    case bid.SET_LINKS: {
      const { count, links } = action.payload
      return {
        ...state,
        links,
        linksCount: count,
      }
    }

    case bid.SET_ATTACHMENTS: {
      const { bidAttachments, count } = action.payload
      return {
        ...state,
        attachments: bidAttachments,
        attachmentsCount: count,
      }
    }

    case bid.UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case bid.SET_BOARD_VIEW: {
      const { swimlane, data } = action.payload
      const list = arrayToObj(data, 'swimlaneName')
      if (swimlane) {
        const newState = state.listView
        newState[swimlane] = list[swimlane]
        newState[swimlane].bids = [
          ...state[swimlane].bids,
          ...list[swimlane].bids,
        ]
        return {
          ...state,
          listView: newState,
        }
      } else {
        return {
          ...state,
          listView: list,
        }
      }
    }

    case bid.SET_TABULAR_VIEW: {
      const { swimlane, data } = action.payload
      const list = arrayToObj(data, 'swimlaneName')
      if (!swimlane) {
        return {
          ...state,
          tabularView: list,
        }
      } else {
        return {
          ...state,
          tabularView: {
            ...state.tabularView,
            [swimlane]: list[swimlane],
          },
        }
      }
    }
    case bid.SET_TABULAR_VIEW_PAGINATION: {
      const { swimlane, tableConfig } = action.payload || {}
      return {
        ...state,
        tabularViewParams: {
          ...state.tabularViewParams,
          [swimlane]: {
            ...state.tabularViewParams[swimlane],
            ...tableConfig,
          },
        },
      }
    }
    case bid.SET_BID_DECISION_MATRIX_MODAL_NEXT_SWIMLANE: {
      return {
        ...state,
        bidDecisionMatrixModalNextSwimlane: action.payload,
      }
    }
    case bid.SET_KANBAN_VIEW: {
      const kanban = action.payload.map((i) => ({
        id: i.swimlaneName,
        ...i,
        cards: i.bids.map((b, index) => ({ ...b, index })),
        bids: undefined,
      }))
      const list = arrayToObj(kanban, 'id')
      const sortedList = {}
      Object.values(BID_SWIMLANE).forEach((i) => (sortedList[i] = list[i]))
      return {
        ...state,
        kanbanView: sortedList,
      }
    }

    case bid.UPDATE_KANBAN_LANE: {
      const {
        sourceLaneId,
        targetLaneId,
        card,
        index: bidIndex,
        position,
      } = action.payload

      let insertAt = position
      if (targetLaneId === sourceLaneId) {
        let lane = state.kanbanView[sourceLaneId]?.cards
        let indexOfCard = lane.findIndex((d) => d.id === card.id)
        let filtered = lane.filter((d, i) => {
          return d.id !== card.id
        })

        if (position > indexOfCard) {
          insertAt += 1
        }

        filtered.splice(insertAt, 0, { ...card, bidIndex })

        return {
          ...state,
          kanbanView: {
            ...state.kanbanView,
            [targetLaneId]: {
              ...state.kanbanView[sourceLaneId],
              cards: [...filtered],
            },
          },
        }
      }
      return {
        ...state,
        kanbanView: {
          ...state.kanbanView,
          [sourceLaneId]: {
            ...state.kanbanView[sourceLaneId],
            count: state.kanbanView[sourceLaneId].count - 1,
            pipelineValue:
              state.kanbanView[sourceLaneId].pipelineValue -
              parseInt(card.potentialValue || 0),
            cards: [
              ...state.kanbanView[sourceLaneId].cards.filter(
                (c) => c.id.toString() !== card.id.toString()
              ),
            ],
          },
          [targetLaneId]: {
            ...state.kanbanView[targetLaneId],
            count: state.kanbanView[targetLaneId].count + 1,
            pipelineValue:
              state.kanbanView[targetLaneId].pipelineValue +
              parseInt(card.potentialValue || 0),
            cards: [
              ...state.kanbanView[targetLaneId].cards.slice(0, position),
              { ...card, bidIndex },
              ...state.kanbanView[targetLaneId].cards.slice(position),
            ],
          },
        },
      }
    }
    case bid.FETCH_KANBAN_SWIMLANE_DATA: {
      const { data, lane } = action.payload
      const kanban = data.map((i) => ({
        cards: i.bids,
      }))
      const { cards, ...countAndOther } = kanban
      return {
        ...state,
        kanbanView: {
          ...state.kanbanView,
          [lane]: {
            ...state.kanbanView[lane],
            ...countAndOther,
            cards: [...state.kanbanView[lane].cards, ...data.cards],
          },
        },
      }
    }

    case bid.SET_VIEW: {
      return {
        ...state,
        view: action.payload,
      }
    }

    case bid.RESET: {
      return initialState
    }
    case bid.RESET_ALL: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
