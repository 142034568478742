import { globalSearch } from "../types";
import { CONSTANTS } from "helpers/const";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialAuthState = {
  filter: {
    search: "",
    companySearch: "",
    specialityIds: [],
    companyTypeIds: [],
    userType: "",
    postType: [],
    date: "",
    popularity: "Recommended",
    created_by: "",
    start_date_time: "",
    end_date_time: "",
    event_type: "",
    group_type: "",
    speciality_ids: [],
    type: "",
  },
  people: {
    count: 0,
    rows: [],
    totalPages: 0,
    mutualConnection: {
      rows: [],
      totalPages: 0,
    },
  },
  totalResults: 0,
  specialities: [],
  companyType: [],
  postType: [],
  tab: CONSTANTS.GLOBAL_SEARCH_MENU.people,
  searchDropDownUsers: [],
  searchDropDownRecentSearch: [],
};

const reducer = persistReducer(
  {
    storage,
    key: CONSTANTS.PERSIST_KEY_GLOBAL_SEARCH,
    whitelist: ["filter"],
  },
  (state = initialAuthState, action = []) => {
    switch (action.type) {
      case globalSearch.SET_FILTER: {
        return { ...state, filter: { ...state.filter, ...action.payload } };
      }
      case globalSearch.RESET_FILTER: {
        if (action.payload)
          return {
            ...state,
            filter: { ...initialAuthState.filter, search: state.filter.search },
          };
        else return { ...state, filter: initialAuthState.filter };
      }
      case globalSearch.SET_TAB: {
        return { ...state, tab: action.payload };
      }
      case globalSearch.SET_SEARCH_DROP_DOWN_USER: {
        return {
          ...state,
          searchDropDownUsers: action.payload.slice(0, 5),
          searchDropDownRecentSearch: [],
        };
      }
      case globalSearch.CLEAR_SEARCH_DROP_DOWN_RECENT_SEARCH: {
        return { ...state, searchDropDownRecentSearch: [] };
      }
      case globalSearch.SET_SEARCH_DROP_DOWN_RECENT_SEARCH: {
        return {
          ...state,
          searchDropDownRecentSearch: action.payload,
          searchDropDownUsers: [],
        };
      }
      case globalSearch.SET_PEOPLE: {
        const people = {
          count: action?.payload?.count || 0,
          rows:
            action.payload.page === 1
              ? [...action?.payload?.rows]
              : [...state.people.rows, ...action?.payload?.rows],
          totalPages: action?.payload?.totalPages || 0,
        };
        return { ...state, people, totalResults: action?.payload?.count || 0 };
      }
      case globalSearch.TOGGLE_FOLLOWER: {
        const obj = Object.assign({}, state.people);
        const p = action.payload;
        return {
          ...state,
          people: {
            ...obj,
            ...{
              rows: obj.rows.map((i) =>
                i.id === p.id ? { ...i, ...{ is_following: p.status } } : i
              ),
            },
          },
        };
      }
      case globalSearch.SET_SPECIALITY: {
        return { ...state, specialities: action.payload.specialities };
      }
      case globalSearch.SET_COMPANY_TYPE: {
        return { ...state, companyType: action.payload.companyType };
      }
      case globalSearch.SET_MUTUAL_CONNECTION: {
        const mutualConnection = {
          count: action?.payload?.count || 0,
          rows:
            action.payload.page === 1
              ? [...action?.payload?.rows]
              : [
                  ...state.people.mutualConnection.rows,
                  ...action?.payload?.rows,
                ],
          totalPages: action?.payload?.totalPages || 0,
        };
        return {
          ...state,
          people: {
            ...state.people,
            mutualConnection,
          },
        };
      }
      case globalSearch.SET_POST_TYPES: {
        return {
          ...state,
          postType: action?.payload,
        };
      }
      default: {
        return state;
      }
    }
  }
);

export default reducer;
