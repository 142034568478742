import { post, deletee, get, getErrorMessage } from 'api'
import { normalizeParams } from 'helpers/common'
import { message } from 'helpers/message'
import ExportJsonToExcel from 'js-export-excel'
import { trackedOpportunity, opportunity } from 'store/types'

export const track =
  ({ opportunityId, opportunityType }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: {
          loadingTrackingStatus: true
        },
      })
      const res = await post({
        subUrl: `/tracked-opportunity/track/${opportunityId}/${opportunityType}`,
      })
      message.success('Opportunity successfully tracked')
      return dispatch({
        type: trackedOpportunity.SET,
        payload: res.data.data,
      })
    } catch (error) {
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: {
          loadingTrackingStatus: false
        }
      })
    }
  }

export const untrack =
  ({ opportunityId, opportunityType }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: {
          loadingTrackingStatus: true
        },
      })
      const res = await deletee({
        subUrl: `/tracked-opportunity/untrack/${opportunityId}/${opportunityType}`,
      })
      message.success('Opportunity successfully untracked')
      return dispatch({
        type: trackedOpportunity.SET,
        payload: res.data.data,
      })
    } catch (error) {
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: {
          loadingTrackingStatus: false
        },
      })
    }
  }

export const setCurrentTab = (tab) => (dispatch) => {
  dispatch({
    type: trackedOpportunity.SET_CURRENT_TAB,
    payload: tab,
  })
}

export const resetTrackedOppState = () => (dispatch) => {
  dispatch({
    type: trackedOpportunity.RESET,
  })
}

export const getTrackedIds = () => async (dispatch) => {
  try {
    const res = await get({
      subUrl: `/tracked-opportunity/list`,
    })
    return dispatch({
      type: trackedOpportunity.SET,
      payload: res.data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
  }
}


const api = {
  federal: process.env.REACT_APP_OPPORTUNITIES_URL,
  state: process.env.REACT_APP_SL_OPPORTUNITIES_URL,
}

export const getOpportunities =
  ({ tableType = 'federal', params }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: { getOpportunities: true },
      })
      let opportunities = []
      let count = 0

      await dispatch(getTrackedIds())
      const trackedIds = getState().trackedOpportunity.trackedIds[tableType]

      if (trackedIds.length) {
        const opportunitiesAPI = api[tableType]
        const { data } = await get({
          url: opportunitiesAPI,
          withCredentials: false,
          params: normalizeParams({ ...params, id: trackedIds }),
        })
        opportunities = data?.data
        count = data?.total || 0
      }

      dispatch({
        type: trackedOpportunity.SET_OPPORTUNITIES,
        payload: { opportunities, count },
      })
    } catch (error) {
      console.log(error, 'err')
    } finally {
      dispatch({
        type: trackedOpportunity.SETLOADING,
        payload: { getOpportunities: false },
      })
    }
  }

export const exportToExcel = (tableType) => (dispatch, getState) => {
  const { opportunities } = getState().trackedOpportunity
  if (!opportunities.length) return

  const option = {
    fileName: 'exported-data',
    datas: [
      {
        sheetData: opportunities,
        sheetHeader: Object.keys(opportunities[0]),
        sheetName: 'Exported data',
      },
    ],
  }
  const toExcel = new ExportJsonToExcel(option)
  toExcel.saveExcel()
}

export const setSelectedRow = (id) => (dispatch) => {
  dispatch({
    type: trackedOpportunity.SET_SELECTED,
    payload: { selected: id },
  })
}

export const setTableColumns = (data) => (dispatch) => {
  dispatch({
    type: trackedOpportunity.SET_TABLE_COLUMNS,
    payload: data,
  })
}
