export const API_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PROD_URL || process.env.REACT_APP_DEV_URL

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
}
export const profileImageAWSBucketUrl = process.env.REACT_APP_PROFILE_BUCKET_URL
export const attachmentBucketUrl =
  process.env.REACT_APP_PROFILE_BUCKET_URL_DOCUMENT_BUCKET_URL
export const resourcesBucketUrl =
  process.env.REACT_APP_TEAM_RESOURCES_BUCKET_URL
export const crmBucketUrl = process.env.REACT_APP_CRM_BUCKET_URL
export const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY
export const hCaptchSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY
