import { post } from 'api'
import { message } from 'helpers/message'
import { support } from '../types'

export const openSupportModal = (payload) => (dispatch) => {
  dispatch({
    type: support.OPEN_MODAL,
    payload
  })
}

export const submitSupportRequest = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: support.SET_LOADING,
      payload: true
    })
    await post({
      subUrl: '/support/submit',
      data: payload
    })
    message.success('Support Ticket submitted successfully')
  } catch (error) {
    message.error('An error occurred while submitting your request.')
  } finally {
    dispatch({
      type: support.SET_LOADING,
      payload: false
    })
  }
}
