import { get, post } from "api";
import { notification } from "store/types";

export const setToken = async (device_token) => {
  try {
    await post({
      subUrl: '/user/register-device',
      data: {
        device_token,
        device_type: "web",
      }
    });
  } catch (e) {
    throw e;
  }
};

export const getNotification = (page = 1) => async (dispatch) => {
  try {
    if (page === 1) {
      dispatch({
        type: notification.SET_NOTIFICATION,
        payload: {
          count: 0,
          rows: [],
          totalPages: 0,
          page,
          unread_count: 0,
        },
      });
    }
    const {
      data: { data },
    } = await get({
      subUrl: '/user/notifications',
      params: { page },
    });
    dispatch({
      type: notification.SET_NOTIFICATION,
      payload: { ...data, ...{ page } },
    });
    return true;
  } catch (e) {
    throw e;
  }
};

export const markAsRead = (notification_id = null) => async (dispatch) => {
  try {
    await post({
      subUrl: '/user/notification/read',
      data: {
        notification_id,
      }
    });
    dispatch({
      type: notification.MARK_AS_READ,
      payload: { id: notification_id },
    });
  } catch (e) {
    throw e;
  }
};

export const updateCount = (count, increase = false) => async (dispatch) => {
  dispatch({
    type: notification.SET_NOTIFICATION_COUNT,
    payload: { count, increase },
  });
  dispatch({
    type: notification.SET_BELL_COUNT,
    payload: { count, increase },
  });
};

export const groupRequestAcceptDeny = (
  n_id,
  group_id,
  member_id,
  accept
) => async (dispatch) => {
  try {
    await post({
      subUrl: '/groups/accept-reject-request',
      data: {
        group_id,
        member_id,
        accept,
      },
    })
    dispatch({
      type: notification.REMOVE_NOTIFICATION,
      payload: { id: n_id },
    })
  } catch (e) {
    throw e;
  }
};

export const groupInvitationAcceptDeny = (n_id, group_id, accept) => async (
  dispatch
) => {
  try {
    await post({
      subUrl: '/groups/accept-reject-invite',
      data: {
        group_id,
        accept,
      }
    });
    dispatch({
      type: notification.REMOVE_NOTIFICATION,
      payload: { id: n_id },
    });
  } catch (e) {
    throw e;
  }
};

export const eventRequestAcceptDeny = (
  n_id,
  event_id,
  attendee_id,
  accept
) => async (dispatch) => {
  try {
    await post({
      subUrl: '/event/accept-reject-request',
      data: {
        event_id,
        attendee_id,
        accept,
      }
    });
    dispatch({
      type: notification.REMOVE_NOTIFICATION,
      payload: { id: n_id },
    });
  } catch (e) {
    throw e;
  }
};

export const eventInvitationAcceptDeny = (n_id, event_id, accept) => async (
  dispatch
) => {
  try {
    await post({
      subUrl: '/event/accepe-reject-invitation',
      data: {
        event_id,
        accept,
      }
    });
    dispatch({
      type: notification.REMOVE_NOTIFICATION,
      payload: { id: n_id },
    });
  } catch (e) {
    throw e;
  }
};

export const resetBellCount = () => async (dispatch) => {
  try {
    await post({
      subUrl: '/user/notification/seen'
    }); 
    dispatch({
      type: notification.SET_BELL_COUNT,
      payload: { count: 0 },
    });
  } catch (e) {
    throw e;
  }
};
