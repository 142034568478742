import {
  deletee,
  get,
  getErrorMessage,
  post,
  put,
  uploadToPresignedUrl,
} from 'api'
import {
  cleanUpObject,
  compressImage,
  getCRMActivityCursor,
} from 'helpers/common'
import { message } from 'helpers/message'
import { history, routes } from 'routes/config'
import { crmInfo } from 'store/types'

export const getCrmInfo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getCrmInfo: true },
    })
    const { data } = await get({ subUrl: `/crm/${id}` })
    dispatch({
      type: crmInfo.SET_CRM_INFO,
      payload: data.data,
    })
  } catch (error) {
    if (error?.response?.status === 422) {
      history.push(routes.crm.path)
    }
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getCrmInfo: false },
    })
  }
}

export const editCrmInfo = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editCrmInfo: true },
    })
    const { data } = await put({ subUrl: '/crm/profile', data: params })
    dispatch({
      type: crmInfo.SET_CRM_INFO,
      payload: data.data,
    })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editCrmInfo: false },
    })
  }
}

export const editCrmPhoto =
  ({ crmContactId, file }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: crmInfo.SETLOADING,
        payload: { editCrmPhoto: true },
      })
      if (!file) {
        const { data: updatedUser } = await put({
          subUrl: '/crm/profile',
          data: { profileImage: null, crmContactId },
        })
        dispatch({
          type: crmInfo.SET_CRM_INFO,
          payload: updatedUser.data,
        })
      } else {
        const { data } = await get({
          subUrl: '/user/get-user-img-presigned-url',
          params: { fileName: file.name },
        })
        const smallImage = await compressImage(file)
        const { url, key } = data.data
        await uploadToPresignedUrl(url, smallImage)
        const { data: updatedUser } = await put({
          subUrl: '/crm/profile',
          data: { profileImage: key, crmContactId },
        })
        dispatch({
          type: crmInfo.SET_CRM_INFO,
          payload: updatedUser.data,
        })
      }
    } catch (error) {
      const msg = getErrorMessage(error)
      message.error(msg)
    } finally {
      dispatch({
        type: crmInfo.SETLOADING,
        payload: { editCrmPhoto: false },
      })
    }
  }

export const editCrmCompany = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editCrmCompany: true },
    })
    const { data } = await put({ subUrl: '/crm/company', data: params })
    dispatch({
      type: crmInfo.SET_CRM_COMPANY,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editCrmCompany: false },
    })
  }
}

export const getPrimaryNAICScodes = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getPrimaryNAICScodes: true },
    })
    const { data } = await get({ subUrl: '/crm/company/naics', params })
    dispatch({
      type: crmInfo.SET_NAICS_CODES,
      payload: { data: data.data, perPage: params.perPage },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getPrimaryNAICScodes: false },
    })
  }
}

export const removeTeam = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { removeTeam: params.teamPortalId },
    })
    await deletee({ subUrl: '/crm/teams', params })
    await getCrmInfo(params.crmContactId)(dispatch)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { removeTeam: false },
    })
  }
}
export const addTeam = (params) => async (dispatch) => {
  try {
    const { crmContactId, teamIdArray } = params
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addTeam: true },
    })
    await Promise.all(
      teamIdArray.map((t) =>
        post({
          subUrl: '/crm/teams',
          data: { crmContactId, teamPortalId: t },
        })
      )
    )
    await getCrmInfo(params.crmContactId)(dispatch)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addTeam: false },
    })
  }
}

export const getTeamsDropDown = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getTeamsDropDown: true },
    })
    const { data } = await get({ subUrl: '/crm/teams/available', params })
    dispatch({
      type: crmInfo.SET_TEAM_DROPDOWN,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getTeamsDropDown: false },
    })
  }
}

export const getMembersDropDown = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getMemberDropdown: true },
    })
    const { data } = await get({ subUrl: '/crm/members/available', params })
    dispatch({
      type: crmInfo.SET_MEMBER_DROPDOWN,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getMemberDropdown: false },
    })
  }
}

export const removeMember = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { removeMember: params.userId },
    })
    await deletee({ subUrl: '/crm/members', params })
    await getCrmInfo(params.crmContactId)(dispatch)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { removeMember: false },
    })
  }
}

export const addMember = (params) => async (dispatch) => {
  try {
    const { crmContactId, userIdArray } = params

    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addMember: true },
    })
    await Promise.all(
      userIdArray.map((id) =>
        post({ subUrl: '/crm/members', data: { crmContactId, userId: id } })
      )
    )
    await getCrmInfo(params.crmContactId)(dispatch)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addMember: false },
    })
  }
}

export const addPastPerformance = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addPastPerformance: true },
    })
    const { data } = await post({
      subUrl: '/crm/company/performances',
      data: params,
    })
    await getCrmInfo(params.crmContactId)(dispatch)
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addPastPerformance: false },
    })
  }
}

export const getPastPerformance = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getPastPerformance: true },
    })
    const { data } = await get({ subUrl: '/crm/company/performances', params })
    dispatch({
      type: crmInfo.SET_PAST_PERFORMANCE,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getPastPerformance: false },
    })
  }
}

export const addTask = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addTask: true },
    })
    const { data } = await post({
      subUrl: '/crm/activities/tasks',
      data: params,
    })

    await refetchTasksList()(dispatch, getState)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addTask: false },
    })
  }
}

export const addNote = (params, files) => async (dispatch, getState) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addNote: true },
    })
    let attachments = []
    if (files && files.length > 0) {
      attachments = await Promise.all(
        files.map(async (file) => uploadFileToPresignedURL(file.originFileObj))
      )
    }
    const { data } = await post({
      subUrl: '/crm/activities/notes',
      data: {
        ...cleanUpObject(params),
        attachments: attachments.length > 0 ? attachments : undefined,
      },
    })
    await refetchNotesList()(dispatch, getState)
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addNote: false },
    })
  }
}

export const editNote = (params, files) => async (dispatch, getState) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editNote: true },
    })
    let attachments = []
    if (files && files.length > 0) {
      attachments = await Promise.all(
        files.map(async (file) => uploadFileToPresignedURL(file.originFileObj))
      )
    }
    const { data } = await put({
      subUrl: '/crm/activities/notes',
      data: {
        ...cleanUpObject(params),
        attachments: attachments.length > 0 ? attachments : undefined,
      },
    })
    if (params.followUpDate || params.isTaskCreated) {
      await refetchNotesList()(dispatch, getState)
    } else {
      params.crmNoteId && (await refetchNote(params.crmNoteId)(dispatch))
      params.crmTaskId && (await refetchTask(params.crmTaskId)(dispatch))
    }
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { editNote: false },
    })
  }
}

export const deleteNote = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteNote: true },
    })

    const { data } = await deletee({ subUrl: '/crm/activities/notes', params })
    dispatch({
      type: crmInfo.DELETE_CRM_NOTE,
      payload: params.crmNoteId,
    })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteNote: false },
    })
  }
}

export const deleteTask = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteTask: true },
    })
    await deletee({ subUrl: '/crm/activities/tasks', params })
    dispatch({
      type: crmInfo.DELETE_CRM_TASK,
      payload: params.crmTaskId,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteTask: false },
    })
  }
}

export const refetchNote = (id) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { refetchNote: true },
    })
    const { data } = await get({ subUrl: `/crm/activities/notes/${id}` })
    dispatch({
      type: crmInfo.UPDATE_CRM_NOTE,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { refetchNote: false },
    })
  }
}

export const refetchTask = (id) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { refetchTask: true },
    })
    const { data } = await get({ subUrl: `/crm/activities/tasks/${id}` })
    dispatch({
      type: crmInfo.UPDATE_CRM_TASK,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { refetchTask: false },
    })
  }
}

export const getActiveTask = () => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getActiveTask: true },
    })
    const { data } = await get({
      subUrl: '/crm/activities/tasks/active-task'
    })
    dispatch({
      type: crmInfo.SET_ACTIVE_TASKS,
      payload: {
        tasks: data.rows,
        count: data.count
      }
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getActiveTask: false },
    })
  }
}

export const uploadFileToPresignedURL = async (file) => {
  const fileName = file.name
  const { data: preSignData } = await get({
    subUrl: '/crm/activities/documents/presigned',
    params: { fileName },
  })
  const url = preSignData?.data?.url
  const key = preSignData?.data?.key
  await uploadToPresignedUrl(url, file)
  return {
    name: file.name,
    size: file.size,
    type: file.type,
    attachedDocument: key,
  }
}

export const deleteAttachment = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteAttachment: true },
    })
    const { data } = await deletee({
      subUrl: '/crm/activities/documents',
      params: { documentId: params.documentId },
    })
    params.crmNoteId && (await refetchNote(params.crmNoteId)(dispatch))
    params.crmTaskId && (await refetchTask(params.crmTaskId)(dispatch))
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteAttachment: false },
    })
  }
}

export const addNoteReply = (params, files) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addNoteReply: true },
    })
    let attachments = []
    if (files && files.length > 0) {
      attachments = await Promise.all(
        files.map(async (file) => uploadFileToPresignedURL(file.originFileObj))
      )
    }
    const { data } = await post({
      subUrl: '/crm/activities/notes/reply',
      data: { ...params, attachments },
    })
    params.crmNoteId && (await refetchNote(params.crmNoteId)(dispatch))
    params.crmTaskId && (await refetchTask(params.crmTaskId)(dispatch))
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { addNoteReply: false },
    })
  }
}

export const updateReply = (params, files) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { updateReply: true },
    })
    let attachments = []
    if (files && files.length > 0) {
      attachments = await Promise.all(
        files.map(async (file) => uploadFileToPresignedURL(file.originFileObj))
      )
    }
    const { data } = await put({
      subUrl: '/crm/activities/notes/reply',
      data: { ...params, attachments },
    })
    params.crmNoteId && (await refetchNote(params.crmNoteId)(dispatch))
    params.crmTaskId && (await refetchTask(params.crmTaskId)(dispatch))
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { updateReply: false },
    })
  }
}

export const deleteReply = (params) => async (dispatch) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteReply: true },
    })
    const { data } = await deletee({
      subUrl: '/crm/activities/notes/reply',
      params,
    })
    params.crmNoteId && (await refetchNote(params.crmNoteId)(dispatch))
    params.crmTaskId && (await refetchTask(params.crmTaskId)(dispatch))
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { deleteReply: false },
    })
  }
}

const perPage = 20
export const getHistory = (params) => async (dispatch, getState) => {
  try {
    const history = getState()?.crmInfo?.history || []
    const { cursor, last } = getCRMActivityCursor(history)
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getHistory: true },
    })

    const { data } = await get({
      subUrl: '/crm/activities',
      params: { activityType: `["history"]`, cursor, last, perPage, ...params },
    })

    dispatch({
      type: crmInfo.SET_CRM_HISTORY,
      payload: {
        rows: data.data?.rows,
        fetchNext: data.data?.fetchNext,
        cursor,
        last,
      },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getHistory: false },
    })
  }
}

export const getNotes = (params, reset) => async (dispatch, getState) => {
  try {
    let cursor, last
    if (!reset) {
      const notes = getState()?.crmInfo?.notes || []
      ;({ cursor, last } = getCRMActivityCursor(notes))
    }

    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getNotes: true },
    })

    const { data } = await get({
      subUrl: '/crm/activities',
      params: { activityType: `["note"]`, cursor, last, perPage, ...params },
    })

    dispatch({
      type: crmInfo.SET_CRM_NOTES,
      payload: {
        rows: data.data?.rows,
        fetchNext: data.data?.fetchNext,
        cursor,
        last,
      },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getNotes: false },
    })
  }
}

export const getActivity = (params, reset) => async (dispatch, getState) => {
  try {
    let cursor, last
    if (!reset) {
      const activities = getState()?.crmInfo?.activities || []
      ;({ cursor, last } = getCRMActivityCursor(activities))
    }
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getActivity: true },
    })

    const activityType = params.activityType
      ? params.activityType
      : `["note", "task", "history"]`
    const { data } = await get({
      subUrl: '/crm/activities',
      params: {
        cursor,
        last,
        perPage,
        activityType,
        ...params,
      },
    })

    dispatch({
      type: crmInfo.SET_CRM_ACTIVTY,
      payload: {
        rows: data.data?.rows,
        fetchNext: data.data?.fetchNext,
        cursor,
        last,
      },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getActivity: false },
    })
  }
}

export const getTasks = (params, reset) => async (dispatch, getState) => {
  try {
    let cursor, last
    if (!reset) {
      const tasks = getState()?.crmInfo?.tasks || []
      ;({ cursor, last } = getCRMActivityCursor(tasks))
    }

    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getTasks: true },
    })

    const { data } = await get({
      subUrl: '/crm/activities',
      params: { activityType: `["task"]`, cursor, last, perPage, ...params },
    })

    dispatch({
      type: crmInfo.SET_CRM_TASKS,
      payload: {
        rows: data.data?.rows,
        fetchNext: data.data?.fetchNext,
        cursor,
        last,
      },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { getTasks: false },
    })
  }
}

export const updateTask = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { updateNote: true },
    })
    const { data } = await put({
      subUrl: '/crm/activities/tasks',
      data: params,
    })
    if (params.dueDate || params.dueTime) {
      await refetchTasksList()(dispatch, getState)
    } else {
      dispatch({
        type: crmInfo.UPDATE_CRM_TASK,
        payload: data.data,
      })
    }
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: crmInfo.SETLOADING,
      payload: { updateNote: false },
    })
  }
}
export const setActivityFilters =
  (f = {}) =>
  (dispatch) => {
    dispatch({
      type: crmInfo.SET_ACTIVITY_FILTERS,
      payload: f,
    })
  }

export const refetchTasksList = (params) => async (dispatch, getState) => {
  try {
    const {
      crmInfo: { contact, tasks, activities, activityFilters },
    } = getState()
    const crmContactId = contact?.id
    await Promise.all([
      getTasks(
        {
          crmContactId,
          perPage: tasks.length + 1,
          cursor: null,
          last: null,
        },
        true
      )(dispatch, getState),
      getActivity(
        {
          crmContactId,
          perPage: activities.length + 1,
          cursor: null,
          last: null,
          ...activityFilters,
        },
        true
      )(dispatch, getState),
    ])
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const refetchNotesList = () => async (dispatch, getState) => {
  try {
    const {
      crmInfo: { contact, notes, activities, activityFilters },
    } = getState()
    const crmContactId = contact.id
    await Promise.all([
      getNotes(
        {
          crmContactId,
          perPage: notes.length + 1,
          cursor: null,
          last: null,
        },
        true
      )(dispatch, getState),
      getActivity(
        {
          crmContactId,
          perPage: activities.length + 1,
          cursor: null,
          last: null,
          ...activityFilters,
        },
        true
      )(dispatch, getState),
    ])
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}

export const resetCrmInfo = () => (dispatch) => {
  dispatch({
    type: crmInfo.RESET,
  })
}
