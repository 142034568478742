import React from "react";
import { Button } from "antd";
import UserRow from "components/Global/UserRow/Followerrow";
import RecentIcon from "components/Icon/RecentIcon/RecentIcon";
import language from "helpers/language";

function LastSearchItem({ users, clearUsers, searchGlobally, lang }) {
  return (
    <div className="SearchDropdownStyle">
      <div className="searchDropdownRecent">
        <div className="searchDropdownRecentTitle">
          <strong>{lang.recent}</strong>
          <Button type="palegrey" onClick={() => clearUsers()}>
            {lang.clear}
          </Button>
        </div>
        <div className="searchDropdownRecentBox">
          {users.map((v) => (
            <div key={v} className="searchDropdownRecentItem">
              <RecentIcon />
              <UserRow
                name={v}
                noImage={true}
                onClick={() => searchGlobally(v)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default language([ "globalSearch"])(LastSearchItem);
