import { opportunity } from '../types'
import fedColumns from 'helpers/opportunityColumns.json'
import stateColumns from 'helpers/stateOpportunityColumns.json'

const initialState = {
  opportunities: [],
  dueOpportunities: {
    list: [],
    count: 0,
  },
  topOpportunities: {
    list: [],
    count: 0,
  },
  getOpportunitiesParams: {
    page: 1,
    perPage: 10,
  },
  tableColumns: {
    federal: fedColumns,
    state: stateColumns,
  },
  count: 0,
  selected: null,
  showAddToBidBoard: null,
  showOpportunityModal: null,
  showFindContractorsModal: false,
  teams: [],
  teamParams: { search: '' },
  memberParams: { companyIds: [], search: '' },
  members: [],
  allMemberIds: [],
  organizations: [],
  loading: {
    getOpportunities: false,
    getTeamsLoading: false,
    getTeamMembers: false,
    getTopOpportunities: true,
  },
}
/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case opportunity.SET: {
      const { opportunities = [], count } = action.payload
      return { ...state, opportunities, count }
    }
    case opportunity.SET_DUE_OPP: {
      const { list = [], count } = action.payload
      return { ...state, dueOpportunities: { list, count } }
    }
    case opportunity.SET_TOP_OPPORTUNITIES: {
      const { list, count } = action.payload
      return {
        ...state,
        topOpportunities: {
          list,
          count,
        },
      }
    }
    case opportunity.SET_SELECTED: {
      const { selected } = action.payload
      return { ...state, selected }
    }
    case opportunity.SET_PARAMS: {
      return {
        ...state,
        getOpportunitiesParams: {
          ...state.getOpportunitiesParams,
          ...action.payload,
        },
      }
    }
    case opportunity.RESET: {
      return initialState
    }
    case opportunity.OPEN_ADD_TO_BIDBOARD: {
      return {
        ...state,
        showAddToBidBoard: action.payload,
      }
    }
    case opportunity.OPEN_OPPORTUNITY_MODAL: {
      return {
        ...state,
        showOpportunityModal: action.payload,
      }
    }
    case opportunity.OPEN_FIND_CONTRACTOR_MODAL: {
      return {
        ...state,
        showFindContractorsModal: action.payload,
      }
    }
    case opportunity.SET_TABLE_COLUMNS: {
      const { tableType, value } = action.payload
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          [tableType]: value,
        },
      }
    }
    case opportunity.SET_TEAMS: {
      return { ...state, teams: action.payload }
    }
    case opportunity.SET_ACTIVE_ORG: {
      const { organizations, page } = action.payload
      return {
        ...state,
        organizations:
          page === 1
            ? organizations
            : [...state.organizations, ...organizations],
      }
    }
    case opportunity.SET_MEMBERS: {
      const { members, allMemberIds } = action.payload
      return { ...state, members, allMemberIds }
    }
    case opportunity.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case opportunity.RESET_ALL: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
