import { Button, Modal, Form, Select, Space } from 'antd'
import { Icondropdown } from 'config/Icons'

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setNoTeamModal, becomeMember } from 'store/action/auth'
import { getActiveOrganizations } from 'store/action/opportunity'
import { useDebounce } from 'use-debounce'

import './Modalblockedfornotmember.scss'

function Modalblockedfornotmember({
  setNoTeamModal,
  isBlockedForNotMemberModal,
  becomeMember,
  token,
  getActiveOrganizations,
  organizations,
  getActiveOrganizationsLoading,
}) {
  const [addTeam, setAddTeam] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 500)
  useEffect(() => {
    token &&
      addTeam &&
      isBlockedForNotMemberModal &&
      getActiveOrganizations({ search: debouncedSearch, page: 1, perPage: 30 })
  }, [
    getActiveOrganizations,
    debouncedSearch,
    token,
    addTeam,
    isBlockedForNotMemberModal,
  ])

  const onSearch = (val) => {
    setSearch(val)
  }

  const onClose = () => {
    setAddTeam(false)
    setNoTeamModal(false)
  }
  const [addTeamForm] = Form.useForm()
  return addTeam ? (
    <Modal
      visible={addTeam && isBlockedForNotMemberModal}
      onCancel={onClose}
      title='Select Organization'
      onOk={() => {
        addTeamForm.submit()
      }}
      destroyOnClose
    >
      <Form
        form={addTeamForm}
        onFinish={async (values) => {
          const success = await becomeMember(values)
          if (success) {
            onClose()
          }
        }}
      >
        <Form.Item
          name='companyId'
          label='Company | Organization'
          rules={[
            {
              required: true,
              message: 'Please select company | organization',
            },
          ]}
        >
          <Select
            placeholder='Select Team'
            suffixIcon={<Icondropdown />}
            onSearch={onSearch}
            showSearch={true}
            filterOption={false}
            loading={getActiveOrganizationsLoading}
            options={organizations?.map((item) => ({
              value: item.id,
              label: item.companylegalname,
              key: item.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  ) : (
    <Modal
      visible={isBlockedForNotMemberModal}
      onCancel={onClose}
      footer={false}
      maskClosable={false}
      width={400}
    >
      <div className='align-center'>
        <h3>Become a Member</h3>
        <p>Please add yourself to a Organization to access these modules</p>
      </div>

      <Space className='btngorup'>
        <Button type='primary' size='large' onClick={() => setAddTeam(true)}>
          Add
        </Button>
      </Space>
    </Modal>
  )
}
export default connect(
  ({ auth, opportunity }) => ({
    isBlockedForNotMemberModal: auth.isBlockedForNotMemberModal,
    token: auth.accessToken,
    organizations: opportunity.organizations,
    getActiveOrganizationsLoading: opportunity.loading.getActiveOrganizations,
  }),
  {
    setNoTeamModal,
    becomeMember,
    getActiveOrganizations,
  }
)(Modalblockedfornotmember)
