import { parsePhoneNumber, getRegionCodeForCountryCode } from 'awesome-phonenumber'

export const REDUCERKEY = 'GOV_GENIE'

export const passwordValidations = (s = 'Password') => [
  { required: true, message: `Please enter ${s}!` },
  {
    pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).*/,
    message: `${s} must contain 1 uppercase, 1 lowercase, 1 number and 1 Character!`,
  },
  {
    min: 8,
    message: `${s} length must be a minimum of 8 characters`,
  },
  {
    max: 20,
    message: `${s} length must be less than 20 characters`,
  },
]
const nameRegex = /^[a-zA-Z0-9]+(?:[-'_\s][a-zA-Z0-9]+)*$/

export const firstNameValidations = [
  { required: true, message: 'Please enter first name' },
  { pattern: nameRegex, message: 'Enter valid first name' },
  {
    min: 2,
    message: 'First name length must be a minimum of 2 characters',
  },
  {
    max: 75,
    message: 'First name length must be less than 75 characters',
  },
]

export const firstNameValidationsWithoutRegex = [
  { required: true, message: 'Please enter first name' },
  {
    min: 2,
    message: 'First name length must be a minimum of 2 characters',
  },
  {
    max: 75,
    message: 'First name length must be less than 75 characters',
  },
]

export const lastNameValidations = [
  { required: true, message: 'Please enter last name' },
  { pattern: nameRegex, message: 'Enter valid last name' },
  {
    min: 2,
    message: 'Last Name length must be a minimum of 2 characters',
  },
  {
    max: 75,
    message: 'Last Name length must be less than 75 characters',
  },
]

export const lastNameValidationsWithoutRegex = [
  { required: true, message: 'Please enter last name' },
  { pattern: nameRegex, message: 'Enter valid last name' },
  {
    min: 2,
    message: 'Last Name length must be a minimum of 2 characters',
  },
  {
    max: 75,
    message: 'Last Name length must be less than 75 characters',
  },
]

export const emailValidation = [
  {
    required: true,
    message: 'Please enter email Address',
  },
  {
    type: 'email',
    message: 'Please enter valid email address',
  },
]

export const jobTitleValidation = [
  {
    min: 2,
    message: 'Job title length must be a minimum of 2 characters',
  },
  {
    max: 75,
    message: 'Job title length must be less than 75 characters',
  },
]

export const opportunityName = [
  { required: true, message: 'Please enter Opportunity Name' },
  {
    min: 2,
    message: 'Opportunity Name length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Opportunity Name length must be less than 150 characters',
  },
]

export const opportunityDescription = [
  { required: true, message: 'Please enter Description' },
  {
    min: 2,
    message: 'Opportunity Description length must be a minimum of 2 characters',
  },
  {
    max: 100000,
    message:
      'Opportunity Description length must be less than 100000 characters',
  },
]
export const contractingOfficer = [
  {
    min: 2,
    message: 'Contracting Officer length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Contracting Officer length must be less than 150 characters',
  },
]

export const agency = [
  {
    required: true,
    message: 'Please enter Agency Name',
  },
  {
    min: 2,
    message: 'Agency Name length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Agency Name length must be less than 150 characters',
  },
]

export const teamName = [
  { required: true, message: 'Please enter team Name' },
  {
    min: 2,
    message: 'Team Name length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Team Name length must be less than 150 characters',
  },
]
export const teamDescription = [
  { required: true, message: 'Please enter Description' },
  {
    min: 2,
    message: 'Team Description length must be a minimum of 2 characters',
  },
  {
    max: 1000,
    message: 'Team Description length must be less than 1000 characters',
  },
]

export const linkValidation = [
  { type: 'url', message: 'Link is not a valid url' },
  { required: true, message: 'Link is required' },
]

// export const

export const proposalDocumentDropDown = [
  'Requirements Matrix',
  'Technical Proposal',
  'Management Proposal',
  'Pricing Proposal',
  'Past Performance',
  'Resume',
  'Quality Assurance',
  'Other',
]

export const bidDatesFormat = 'YYYY-MM-DD'
export const bidDatesTimeFormat = 'YYYY-MM-DD h:mm A'
export const apiDateFormat = 'YYYY-MM-DD'
export const tabularViewDefaultPagination = {
  page: 1,
  perPage: 10,
}

export const commonFileTypes = [
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/gif',
  'image/webp',
  'text/plain',
  'text/csv',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/zip',
]

export const documentName = [
  { required: true, message: 'Please enter Document Name' },
  {
    min: 2,
    message: 'Document Name length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Document Name length must be less than 150 characters',
  },
]

export const documentType = [
  {
    required: true,
    message: 'Please select Document Type',
  },
]

export const resourceName = [
  { required: true, message: 'Please enter Resource Name' },
  {
    min: 2,
    message: 'Resource Name length must be a minimum of 2 characters',
  },
  {
    max: 150,
    message: 'Resource Name length must be less than 150 characters',
  },
]

export const description = [
  { required: true, message: 'Please enter Description' },
  {
    min: 2,
    message: 'Description length must be a minimum of 2 characters',
  },
  {
    max: 1000,
    message: 'Description length must be less than 1000 characters',
  },
]

export const fileRules = [
  { required: true, message: 'Resource File is required' },
]

export const phoneValidation = [
  { required: true, message: 'Please enter phone number' },
  {
    min: 10,
    max: 10,
    message: 'Phone number must be 10 digits',
  },
]

export const companyValidation = [
  { required: true, message: 'Please enter company name' },
]

export const relationshipStageValidation = [
  {
    require: true,
    message: 'Please enter relationship stage',
  },
]

export const contactTypeValidation = [
  {
    require: true,
    message: 'Please enter contact type',
  },
]

export const searchNameValidation = [
  {
    min: 2,
    message: 'Search name length must be a minimum of 2 characters',
  },
  {
    required: true,
    message: 'Please enter Search name',
  },
]

export const phoneInputValidation = [
  {
    validator(_, value) {
      const pn = parsePhoneNumber(value, { defaultCountry: 'US', regionCode: 'US'});
      const pattern = /[a-zA-Z!@#$%^&*_=<>?/,\':;"|\\[\]{}~`]/;
      if(!pn.valid) {
        return Promise.reject('Invalid phone number format')
      } else if(pattern.test(value)) {
        return Promise.reject('Invalid phone number format')
      }
      return Promise.resolve('')
    },
  },
]

export const contractingOfficerPhoneValidation = [
  {
    validator(_, value) {
      if (value && isNaN(value)) {
        return Promise.reject(
          `Contracting Officer Phone Number should consist only numbers`
        )
      } else if (value && value.toString()?.length !== 10) {
        return Promise.reject(
          `Contracting Officer Phone Number length must be 10`
        )
      } else {
        return Promise.resolve('')
      }
    },
  },
]

export const requiredValidation = (n) => [
  {
    required: true,
    message: `Please enter ${n}`,
  },
]
export const reminderValidation = [
  ...requiredValidation('Reminder'),
  {
    validator(_, value) {
      if (isNaN(value) || value.toString().includes('.')) {
        return Promise.reject('Reminder should be integer number')
      } else if (value > 24) {
        return Promise.reject('Reminder must be less than or equal to 24')
      } else if (value < 1) {
        return Promise.reject('Reminder must be greater than or equal to 24')
      } else {
        return Promise.resolve()
      }
    },
  },
]

export const loginErrorInvalidEmail = {
  code: 'auth/user-not-found',
  message: 'Requested email address not found',
}

export const loginErrorInvalidPassword = {
  code: 'auth/wrong-password',
  message: 'Incorrect email address or password',
}

export const linkValidator = (s) => [
  {
    type: 'url',
    message: `Please enter correct url of ${s} profile`,
  },
]

export const stringValidation = (n, { min, max }, required = true) => [
  ...(min !== 0
    ? [
        {
          min,
          message: `${n} length must be a minimum of ${min} characters`,
        },
      ]
    : []),
  ...(max !== 0
    ? [
        {
          max,
          message: `${n} length must be less than ${max} characters`,
        },
      ]
    : []),
  ...(required
    ? [
        {
          required: true,
          message: `Please enter ${n}`,
        },
      ]
    : []),
]

export const NA = 'N/A'

export const CRM_TASK_TYPE = {
  CALL: 'Call',
  EMAIL: 'Email',
  TODO: 'To-do',
}
export const CRM_TASK_PRIORITY = {
  NONE: 'None',
  HIGH: 'High',
}

export const BID_TASK_TYPE = {
  CALL: 'Call',
  EMAIL: 'Email',
  TODO: 'To-do',
}
export const BID_TASK_PRIORITY = {
  NONE: 'None',
  HIGH: 'High',
}

export const MODULE_NAMES = {
  homepage: 'Homepage',
  directory: 'Directory',
  opportunities: 'Opportunities',
  awards: 'Awards',
  crm: 'CRM',
  bid_board: 'Bid Board',
  teaming_portal: 'Teaming Portal',
  events: 'Events',
  polls: 'Polls',
  groups: 'Groups',
  tracked_bids: 'Tracked Bids',
  bid_alerts: 'Bid Alerts',
  saved: 'Saved',
  archived: 'Archived',
}

export const CONSTANTS = {
  PERSIST_KEY_GLOBAL_SEARCH: 'SearchParams',
  USER_TYPES: {
    DIRECT: 'direct',
    INDIRECT: 'indirect',
  },
  NETWORK_FROM: {
    suggestion: 'suggestion',
    follower: 'follower',
    following: 'following',
  },
  UNDEFINED: 'undefined',
  POPULARITY: ['Trending', 'Most Viewed', 'Recommended'],
  DATE_TYPES: ['Today', 'This Week', 'Last Week', 'Last Month'],
  GLOBAL_SEARCH_MENU: {
    people: 'people',
    group: 'group',
    content: 'content',
    event: 'event',
    opportunities: 'opportunities',
    awards: 'awards',
    contractordirectory: 'contractordirectory',
  },
  ROUTE_SIDEBAR: {
    GLOBAL_SEARCH: 'global-search',
    FEED: 'feed',
    DEFAULT: false,
  },
  COMMUNITY_UPLOAD_TYPES: {
    LOGO: 'logo',
    COVER: 'cover',
    BACKGROUND: 'background',
  },
  POST_PRIVACY: {
    PUBLIC: 'public',
    COMMUNITY: 'community',
  },
  INVITE_REQUEST_MEMBERS: {
    INVITE: 'Invited',
    INVITE_KEY: 'invite',
    REQUEST: 'Requested',
    REQUEST_KEY: 'join',
  },
  INVITE_REQUESTED: 'requested',
  EVENT_STATUS: {
    REQUESTED: 'requested',
    CANCELLED: 'cancelled',
    ATTENDING: 'attending',
  },
  EVENT_VISIBLITY: {
    PUBLIC: 'public',
    PRIVATE: 'private',
  },
}

export const federalCertifications = [
  {
    field: 'womanownedsmallbusiness',
    text: 'Woman Owned Small Business',
  },
  {
    field: 'economicallydisadvantagedwomenowned',
    text: 'Economically Disadvantaged Women-Owned',
  },
  {
    field: 'localareasetaside',
    text: 'Local Area Set-Aside',
  },
  {
    field: 'hubzone',
    text: 'Hubzone',
  },
  {
    field: 'partialsmallbusiness',
    text: 'Partial Small Business',
  },
  {
    field: 'totalsmallbusiness',
    text: 'Total Small Business',
  },
  {
    field: 'veteranowned',
    text: 'Veteran Owned',
  },
  {
    field: 'servicerelateddisabledveteranowned',
    text: 'Service Related Disabled Veteran Owned',
  },
  {
    field: 'totalhbcumi',
    text: 'Total HBCU / MI',
  },
  {
    field: 'certified8a',
    text: 'Certified 8a',
  },
  {
    field: 'indiansmallbusinesseconomicenterprise',
    text: 'Indian Small Business Economic Enterprise',
  },
  {
    field: 'indianeconomicenterprise',
    text: 'Indian Economic Enterprise',
  },
]

export const nonFederalCertifications = [
  {
    field: 'minoritybusinessenterprise',
    text: 'Minority Business Enterprise (MBE)',
  },
  {
    field: 'womenbusinessenterprise',
    text: 'Women Business Enterprise (WBE)',
  },
  {
    field: 'smallbusinessenterprise',
    text: 'Small Business Enterprise (SBE)',
  },
  {
    field: 'disabledveteranbusinessenterprise',
    text: 'Disabled/Veteran Business Enterprise (D/VBE)',
  },
  {
    field: 'iso50001',
    text: 'ISO 50001',
  },
  {
    field: 'iso9000',
    text: 'ISO 9000',
  },
  {
    field: 'iso9001',
    text: 'ISO 9001',
  },
  {
    field: 'iso9002',
    text: 'ISO 9002',
  },
  {
    field: 'iso9003',
    text: 'ISO 9003',
  },
  {
    field: 'iso9004',
    text: 'ISO 9004',
  },
  {
    field: 'isots16949',
    text: 'ISO/TS 16949',
  },
]

export const communityAWSBucket =
  'https://govgenie-community-files.s3.amazonaws.com/'

export const eventAWSBucket = 'https://govgenie-event-files.s3.amazonaws.com/'
export const accessParams = [
  {
    key: 'users',
    name: 'Users',
  },
  {
    key: 'posts',
    name: 'Posts',
  },
  {
    key: 'polls',
    name: 'Polls',
  },
  {
    key: 'events',
    name: 'Events',
  },
  {
    key: 'live_streams',
    name: 'Live Streams',
  },
]

export const cardStyle = {
  style: {
    base: {
      backgroundColor: '#fff',
      letterSpacing: '0.025em',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
      ':hover': {
        '::placeholder': {
          color: '#0096dc',
        },
      },
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#cccccc',
      },
    },
    invalid: {
      color: '#9e2146',
    },
    empty: {
      backgroundColor: '#fff',
    },
  },
  hidePostalCode: true,
}

export const BID_BOARD_DESCRIPTION_TRUNCATE_LENGTH = 350
