const ExternalLinkIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.5927 1.28034C9.86836 1.00468 10.1883 0.907064 10.464 1.18273C10.7396 1.45839 10.8621 1.89237 10.5864 2.16803L4.79624 7.84873C4.52058 8.12439 4.07364 8.12439 3.79798 7.84873C3.52231 7.57307 3.52231 7.12613 3.79798 6.85046L9.5927 1.28034Z'
        fill='#2A324B'
      />
      <path
        d='M10.5422 1.44872H7.64477C7.24472 1.44872 6.92041 1.12442 6.92041 0.724362C6.92041 0.324308 7.24472 0 7.64477 0H11.2666C11.6666 0 11.9909 0.324308 11.9909 0.724362V4.34617C11.9909 4.74623 11.6666 5.07054 11.2666 5.07054C10.8665 5.07054 10.5422 4.74623 10.5422 4.34617V1.44872Z'
        fill='#2A324B'
      />
      <path
        d='M10.5882 7.00317C10.5882 6.61333 10.9043 6.29729 11.2941 6.29729C11.684 6.29729 12 6.61333 12 7.00317V9.97059C12 11.0914 11.0914 12 9.97059 12H2.02941C0.908598 12 0 11.0914 0 9.97059L1.63765e-06 2.02941C1.63765e-06 0.908596 0.908599 0 2.02941 0C4.87549 2.23059e-06 3.50507 2.23059e-06 4.96047 2.6753e-06C5.35032 2.6753e-06 5.66635 0.316037 5.66635 0.705885C5.66635 1.09573 5.35032 1.41177 4.96047 1.41177C3.50507 1.41177 4.87549 1.41177 2.02941 1.41176C1.68829 1.41176 1.41177 1.68829 1.41177 2.02941L1.41176 9.97059C1.41176 10.3117 1.68829 10.5882 2.02941 10.5882H9.97059C10.3117 10.5882 10.5882 10.3117 10.5882 9.97059V7.00317Z'
        fill='#2A324B'
      />
    </svg>
  )
}

export default ExternalLinkIcon
