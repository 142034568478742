import React, { useEffect, useState } from 'react'
import { Modal, Slider, Row, Col, Popover, Button } from 'antd'
import Sliderbar from '../../assets/images/BAR.png'
import Icontooltipinfo from 'components/Icontooltipinfo/Icontooltipinfo'
import './Modalbiddecisionmatrix.scss'
import { connect } from 'react-redux'
import {
  editBidBoard,
  getKanban,
  setBidDecisionMatrixPopup,
} from 'store/action/bid'
const chances = {
  LOW: 'Low',
  MODERATE: 'Moderate',
  GOOD: 'Good',
  HIGH: 'High',
}


const defaultQuestionAndScore = [
  {
    question: 'Can we meet the proposal due date?',
    value: 0,
  },
  {
    question: 'Do we have the required past performance?',
    value: 0,
  },
  {
    question: 'Are you known by the client?',
    value: 0,
  },
  {
    question: 'What is our overall technical capability/position?',
    value: 0,
  },
  {
    question: 'Can we provide proof of qualified staff?',
    value: 0,
  },
  {
    question: 'Are subcontractors needed?',
    value: 0,
  },
  {
    question: 'What is the financial potential?',
    value: 0,
  },
  {
    question: 'Can we respond with a complete, compelling proposal?',
    value: 0,
  },
  {
    question: 'Who are our competitors?',
    value: 0,
  },
  {
    question: 'What is our pricing competitiveness?',
    value: 0,
  },
]


const Modalbiddecisionmatrix = ({
  bidInfo,
  bidDecisionMatrix = {},
  editBidBoard,
  setBidDecisionMatrixPopup,
  getKanban,
  bidParams,
}) => {

  const [bidMatrix, setBidMatrix] = useState(defaultQuestionAndScore)

  const [average, setAverage] = useState(0)
  const [chance, setChance] = useState(chances.LOW)
  const swimlane = bidDecisionMatrix?.swimlane
  const bidId = bidDecisionMatrix?.bidId

  useEffect(() => {
    if (bidInfo?.bidMatrix && bidId && bidInfo?.id === bidId) {
      setBidMatrix([...bidInfo?.bidMatrix])
      computeAverage(bidInfo?.bidMatrix)
    } else {
      setBidMatrix([...defaultQuestionAndScore])
      setAverage(0)
    }
  }, [bidInfo, bidId])

  useEffect(() => {
    if (average <= 49) {
      setChance(chances.LOW)
    } else if (average >= 50 && average <= 69) {
      setChance(chances.MODERATE)
    } else if (average >= 70 && average <= 89) {
      setChance(chances.GOOD)
    } else {
      setChance(chances.HIGH)
    }
  }, [average])

  const isForReview = React.useMemo(() => {
    if (bidInfo && bidInfo?.bidMatrix && bidInfo?.bidMatrix?.length > 0) {
      return true
    }
    return false
  }, [bidInfo])

  const onChangeBidMatrix = ({ index, value }) => {
    const tempArray = [...bidMatrix]
    tempArray[index] = { ...tempArray[index], value }

    setBidMatrix(tempArray)
    computeAverage(tempArray)
  }

  const computeAverage = (tempArray = []) => {
    const sum = tempArray.reduce((sum, item) => sum + item.value, 0)
    const average = Math.round(sum / 10)
    setAverage(average)
  }

  // Encountering stale state.
  // Added this hack, to force this component to rerender.
  if(!swimlane && !bidId) {
    return null
  }

  return (
    <Modal
      className='biddecisionmatrixmodal'
      title='Bid Decision Matrix'
      okText='Submit'
      visible={swimlane && bidId}
      onOk={async () => {
        await editBidBoard({
          bidId,
          bidMatrixScore: average,
          swimlane,
          bidDecisionMatrix: true,
          bidMatrix,
        })
        await getKanban({ page: 0, perPage: 0, ...bidParams })
      }}
      onCancel={() => setBidDecisionMatrixPopup(null)}
      zIndex={2}
      // {...(isForReview ? { footer: null } : {})}
    >
      <div className='biddecisionblock'>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              1. Can we meet the proposal due date?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3: <span>Less than 7 days due date</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6: <span>Apx 2 to 3 weeks time</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10: <span>30 days or longer</span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>Less than 7 days due date</div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>Apx 2 to 3 weeks time</div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>30 days or longer</div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 0,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[0].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              2. Do we have the required past performance?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>
                        None, need to find a teaming partner or subcontractor
                      </span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:{' '}
                      <span>
                        We have some PP, but not a perfect fit. Will need a
                        partner.
                      </span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:{' '}
                      <span>
                        We have done this type of work and have 3+ PP within 3
                        years
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    None, need to find a teaming partner or subcontractor
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    We have some PP, but not a perfect fit. Will need a partner.
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    We have done this type of work and have 3+ PP within 3 years
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 1,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[1].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              3. Are you known by the client?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Unknown to this client</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Known to client, but not fully cultivated</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>
                        Well-developed working relationship; previous contracts
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>Unknown to this client</div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Known to client, but not fully cultivated
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Well-developed working relationship; previous contracts
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 2,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[2].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              4. What is our overall technical capability/position?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Not qualified; weak relevant experience</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Capable understand problem; experienced</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>
                        Can meet/exceed every requirement; technically superior
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Not qualified; weak relevant experience
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Capable understand problem; experienced
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Can meet/exceed every requirement; technically superior
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 3,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[3].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              5. Can we provide proof of qualified staff?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Limited in-house staff available</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Good in-house staff available</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>Best in-house staff available</span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Limited in-house staff available
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Good in-house staff available
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Best in-house staff available
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 4,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[4].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              6. Are subcontractors needed?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Yes, but will dilute position</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Yes, but will have little or no effect</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>Yes, and will enhance overall proposal</span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Yes, but will dilute position
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Yes, but will have little or no effect
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Yes, and will enhance overall proposal
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 5,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[5].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              7. What is the financial potential?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Marginal long term; no short term return</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Good long term; questionable short term</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>Excellent long term; excellent short term</span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Marginal long term; no short term return
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Good long term; questionable short term
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Excellent long term; excellent short term
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 6,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[6].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              8. Can we respond with a complete, compelling proposal?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>
                        Unclear understanding of problem/project needs; limited
                        response
                      </span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>
                        Understand problems, project & client needs; can respond
                      </span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>
                        Can meet/exceed all requirements; have compelling story;
                        know hot buttons
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Unclear understanding of problem/project needs; limited
                    response
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Understand problems, project & client needs; can respond
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Can meet/exceed all requirements; have compelling story;
                    know hot buttons
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 7,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[7].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              9. Who are our competitors?
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Competitor is strongly favored or UNKNOWN</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Open competition with no strong favorite</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>
                        We are strongly favored over competition; incumbent
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Competitor is strongly favored or UNKNOWN
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Open competition with no strong favorite
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    We are strongly favored over competition; incumbent
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 8,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[8].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col span={24}>
            <h5>
              10. What is our pricing competitiveness?{' '}
              <Popover
                trigger='click'
                placement='bottomLeft'
                content={
                  <div className='successratio filtermobileinfo'>
                    <div className='successratiotext'>
                      Select 1-3:
                      <span>Must cut corners; cost share; risky</span>
                    </div>
                    <div className='successratiotext'>
                      Select 4-6:
                      <span>Reasonable & competi- tive; reasonable risks</span>
                    </div>
                    <div className='successratiotext'>
                      Select 7-10:
                      <span>
                        Honest, credible price within known limits; acceptable
                        risks
                      </span>
                    </div>
                  </div>
                }
                title={false}
              >
                <Button className='noTheme flexblock' size='small'>
                  <Icontooltipinfo />
                </Button>
              </Popover>
            </h5>
            <div className='biddecisionmatrixbox'>
              <Row gutter={[20, 20]} className='filterwrapper'>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Must cut corners; cost share; risky
                  </div>
                </Col>
                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                  <div className='filterinfo'>
                    Reasonable & competi- tive; reasonable risks
                  </div>
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                  <div className='filterinfo'>
                    Honest, credible price within known limits; acceptable risks
                  </div>
                </Col>
              </Row>
              <div className='sliderblock'>
                <img src={Sliderbar} alt='' title='SliderBar' />
                <Slider
                  onChange={(e) => {
                    onChangeBidMatrix({
                      index: 9,
                      value: e,
                    })
                  }}
                  //disabled={isForReview}
                  value={bidMatrix[9].value}
                />
              </div>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <ul className='filternumber'>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className='flexblock justifyspacebetween scorefactorblock'>
              <h4>
                Total score of factors evaluated (Maximum potential score 100)
              </h4>

              <h4 className='flexblock'>
                {average}% {chance} chance of success
                <Popover
                  title={false}
                  trigger='click'
                  placement='bottomLeft'
                  content={
                    <div className='successratio'>
                      <div className='successratiotext'>
                        0-49 percentage: <span>Low chance of success</span>
                      </div>
                      <div className='successratiotext'>
                        50-69 percentage:
                        <span>Moderate chance of success</span>
                      </div>
                      <div className='successratiotext'>
                        70-89 percentage: <span>Good chance of success</span>
                      </div>
                      <div className='successratiotext'>
                        90-100 percentage:
                        <span>High chance of success</span>
                      </div>
                    </div>
                  }
                >
                  <Button className='noTheme flexblock' size='small'>
                    <Icontooltipinfo />
                  </Button>
                </Popover>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default connect(
  ({ bid }) => ({
    bidDecisionMatrix: bid.bidDecisionMatrixModalNextSwimlane,
    bidParams: bid.bidParams,
    bidInfo: bid.bidInfo,
  }),
  {
    editBidBoard,
    setBidDecisionMatrixPopup,
    getKanban,
  }
)(Modalbiddecisionmatrix)
