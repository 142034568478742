import { tablefilter } from '../types'

const initialState = {
  filters: {
    directory: {
      search: ''
    },
    fed_opportunities: {
      search: ''
    },
    sle_opportunities: {
      search: ''
    },
    awards: {
      search: ''
    }
  },
  params: {
    directory: {
      page: 1,
      perPage: 10,
    },
    fed_opportunities: {
      page: 1,
      perPage: 10,
    },
    sle_opportunities: {
      page: 1,
      perPage: 10,
    },
    awards: {
      page: 1,
      perPage: 10,
    }
  },
  tableSearch: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case tablefilter.SET_FILTER_VALUE: {
      const payload = action.payload
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterType]: {
            ...state.filters[payload.filterType],
            [payload.filterKey]: payload.value
          }
        }
      }
    }
    case tablefilter.SET_FILTERS: {
      const payload = action.payload
      return {
        ...state,
        filters: {
          [payload.filterType]: {
            ...state.filters[payload.filterType],
            ...payload.filters
          }
        }
      }
    }
    case tablefilter.SET_PARAMS: {
      const payload = action.payload
      return {
        ...state,
        params: {
          [payload.filterType]: {
            ...state.params[payload.filterType],
            ...payload.params
          }
        },
      }
    }
    case tablefilter.CLEAR_FILTER: {
      const payload = action.payload
      return {
        ...state,
        filters: {
          [payload.filterType]: {
            search: ''
          }
        }
      }
    }
    case tablefilter.SET_SEARCH_VALUE: {
      const payload = action.payload
      return {
        ...state,
        tableSearch: {
          ...state.tableSearch,
          [payload.filterType]: payload.value
        }
      }
    }
    default: {
      return state
    }
  }
}

export default reducer