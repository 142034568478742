import axios from 'axios'
import { API_URL } from 'config/env'
import { firebaseAuth } from 'helpers/firebase'
import store from 'store'
import { setSubscriptionPrompt } from 'store/action/company'
import { auth } from 'store/types'

const baseUrl = API_URL
const codes = {
  UNAUTHORIZED: [401],
  CUSTOM_TOKEN_EXPIRED: -2,
  REQUEST_TIMEOUT: 408,
  ECONNABORTED: 'ECONNABORTED',
}

const getNewToken = async () => {
  const token = await firebaseAuth?.currentUser?.getIdToken()
  return token
}
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 402) {
      const message = error.response.data.message
      store.dispatch(setSubscriptionPrompt({
        visible: true,
        title: message.title,
        message: `You have reached the maximum limit of ${message.paramName} in the current plan. Please upgrade your account to the next plan.`
      }))
      return Promise.reject(error)
    }
    if (codes.UNAUTHORIZED.includes(error?.response?.status)) {
      firebaseAuth.signOut()
      store.dispatch({ type: auth.RESET,})
      store.dispatch({ type: auth.RESET_ALL })
      return Promise.reject(error)
    }

    if (
      error?.response?.status === codes.REQUEST_TIMEOUT ||
      error.code === codes.ECONNABORTED
    ) {
      //Looks like the server is taking to long to respond, please try again in sometime.
      console.log(`A timeout happend on url ${error.config.url}`)
      //errorToast({ content: 'Server request timed out. Please retry again.' })
    }

    return Promise.reject(error)
  }
)

export const getFullUrl = (url, apiVersion) => {
  return `${baseUrl}/${apiVersion || 'v1'}${url}`
}

/**
 * @typedef {{ subUrl: string;  method?: Method;  data?: object;  params?: object;  headers?: object; cancelToken?:any}} Irequest
 */

/**
 * @param {Irequest} request
 */
export const get = (request) => {
  return commonFetch({ method: 'get', ...request })
}

/**
 * @param {Irequest} request
 */
export const post = (request) => {
  return commonFetch({ method: 'post', ...request })
}

/**
 * @param {Irequest} request
 */
export const patch = (request) => {
  return commonFetch({ method: 'patch', ...request })
}

/**
 * @param {Irequest} request
 */
export const put = (request) => {
  return commonFetch({ method: 'put', ...request })
}

/**
 * @param {Irequest} request
 */
export const deletee = (request) => {
  return commonFetch({ method: 'delete', ...request })
}
/**
 * @param {Irequest} request
 */
const commonFetch = async (request) => {
  const {
    subUrl,
    method,
    url,
    data = {},
    params,
    headers = {},
    cancelToken,
    apiVersion = 'v1',
    withCredentials = true,
  } = request
  const commonHeaders = await getCommonHeaders()
  return axios({
    method,
    url: url || getFullUrl(subUrl, apiVersion),
    params,
    data,
    headers: { ...commonHeaders, ...headers },
    cancelToken,
    withCredentials,
  })
}

export const content_types = {
  multipart: {
    'Content-Type': 'multipart/form-data',
  },
  json: {
    'Content-Type': 'application/json',
  },
}

const getCommonHeaders = async () => {
  // const { auth } = store.getState()
  const token = await getNewToken()
  return {
    ...content_types.json,
    Authorization: token ? `Bearer ${token}` : null,
  }
}

export const convertToForm = (item) => {
  const form_data = new FormData()
  for (const key in item) {
    const value = item[key]
    if (value) {
      form_data.append(key, value)
    }
  }
  return form_data
}

export const getErrorMessage = (error) => {
  if (typeof error?.response?.data.error === 'string') {
    return error?.response?.data.error
  } else {
    return ''
  }
}

export const cancellableApiRequest = () => {
  let cancelToken = null
  const apiCall = async (fn, config) => {
    if (cancelToken) {
      cancelToken.cancel()
    }
    cancelToken = axios.CancelToken.source()
    const data = await fn({ ...config, cancelToken: cancelToken.token })
    cancelToken = null
    return data
  }
  return apiCall
}
/**
 *
 * @param {stirng} url
 * @param {File} file
 */
export const uploadToPresignedUrl = (url, file) => {
  return axios.put(url, file)
}

export const uploadFileToPresignedURL = async (file) => {
  const fileName = file.name
  const { data: preSignData } = await get({
    subUrl: '/bid/attachments/presigned',
    params: { fileName },
  })
  const url = preSignData?.data?.url
  const key = preSignData?.data?.key
  await uploadToPresignedUrl(url, file)
  return {
    attachmentName: file.name,
    size: file.size,
    type: file.type,
    attachedImage: key,
  }
}
