import { trackedOpportunity } from '../types'
import fedColumns from 'helpers/opportunityColumns.json'
import stateColumns from 'helpers/stateOpportunityColumns.json'

const initialState = {
  trackedIds: {},
  currentTab: 'federal',
  opportunities: [],
  tableColumns: {
    federal: fedColumns,
    state: stateColumns,
  },
  count: 0,
  loading: {
    getOpportunities: false,
    loadingTrackStatus: false
  },
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case trackedOpportunity.SET: {
      return {
        ...state,
        trackedIds: action.payload
      }
    }
    case trackedOpportunity.SET_OPPORTUNITIES: {
      const { opportunities = [], count } = action.payload
      return { ...state, opportunities, count }
    }
    case trackedOpportunity.SETLOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload
        }
      }
    }
    case trackedOpportunity.RESET: {
      return initialState
    }
    case trackedOpportunity.SET_TABLE_COLUMNS: {
      const { tableType, value } = action.payload
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          [tableType]: value,
        },
      }
    }
    case trackedOpportunity.SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
