import React from "react";

function MuteIcon() {
  return (
    <span className="anticon anticon-mute">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 23 23"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-502 -885)">
            <g transform="translate(502 885)">
              <path
                stroke="#FFF"
                strokeLinecap="square"
                strokeWidth="1.263"
                d="M12.4117647 4L12.4117647 17.1764706 7.70588235 13.4117647 3 13.4117647 3 7.76470588 7.70588235 7.76470588z"
              ></path>
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeWidth="1.263"
                d="M12.412 12.47c1.412-.94 1.412-2.823 0-3.764"
              ></path>
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeWidth="1.263"
                d="M13.824 13.935c2.51-1.674 2.51-5.02 0-6.693"
              ></path>
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeWidth="1.263"
                d="M15.706 16.444c4.392-2.928 4.392-8.784 0-11.712"
              ></path>
              <path
                fill="#000"
                fillRule="nonzero"
                d="M1.50710673 1.41421356L2.9213203 -1.69642078e-12 22.9213203 20 21.5071067 21.4142136z"
              ></path>
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M1.22568622e-13 2.70710678L1.41421356 1.29289322 21.4142136 21.2928932 20 22.7071068z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default MuteIcon;
