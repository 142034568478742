import React, { useCallback, useEffect } from 'react'
import { messaging } from 'helpers/firebase'
import { setToken, updateCount } from 'store/action/notification'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notificationReirection } from 'helpers/notificationRedirection'

function NotificationWrapper({ children, updateCount, history, location }) {
  const handleMessage = ({ data }) => {
    if (messaging) {
      const notification = data?.notification
        ? data?.notification
        : data['firebase-messaging-msg-data']?.notification
      const userData = data?.data
        ? data?.data
        : data['firebase-messaging-msg-data']?.data
      updateCount(0, true)
      let notificationShow = new Notification(notification?.title, {
        icon: process.env.REACT_APP_URL + 'logo512.png',
        body: notification?.body,
        data: userData,
      })
      notificationShow.onclick = (e) =>
        notificationReirection(e?.target?.data, history, location)
    }
  }

  useEffect(() => {
    let notificationListener = null
    if (messaging) {
      Promise.resolve(Notification.requestPermission()).then((permission) => {
        if (permission === 'granted') {
          console.log(permission, 'permission')
          messaging
            .getToken()
            .then((firebaseToken) => {
              setToken(firebaseToken)
            })
            .catch((e) => console.log({ e }))
          notificationListener = messaging.onMessage(handleMessage)
          navigator.serviceWorker.addEventListener('message', handleMessage)
        }
      })
    }
    return () => {
      if (notificationListener) notificationListener()
      if ('serviceWorker' in navigator && navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener('message', handleMessage)
      }
    }
  }, [])

  return children
}

export default connect(null, { updateCount })(withRouter(NotificationWrapper))
