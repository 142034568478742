import { useEffect, useState } from 'react'
import qs from 'qs'
import { withRouter } from 'react-router'
import { history, routes } from 'routes/config'
import { checkSubscriptionExpire } from 'helpers/auth'
import Loader from 'components/Loader/Loader'
import { connect } from 'react-redux'


// Todo: Refactor needed
const ValidateSubscription = ({
//   subscriptionRequired,
  subscription,
  children,
  company,
  companyId,
  location,
}) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    try {
      setLoading(true)
      if (!companyId) {
        return setLoading(false)
      }
      
      if (company?.entity_record_type === 'agency') {
        return setLoading(false)
      }
      const searchQuery = qs.parse(
        location.search.slice(1, location.search.length)
      )
      if (!subscription || !subscription.isActive) {
        if (
          ![routes.pricing.path, routes.reviewsubscription.path].includes(
            location.pathname
          ) &&
          subscription?.status !== 'deactivated'
        ) {
          return history.push(routes.pricing.path)
        }
      }
      console.log('got here')
      if (
        subscription?.status === 'awaiting-payment' &&
        ![
          routes.pricing.path,
          routes.reviewsubscription.path,
          routes.companyplanchanged.path,
        ].includes(location.pathname)
      ) {
        return history.push(routes.companyplanchanged.path)
      }
      if (
        subscription?.status === 'active' &&
        !subscription.plan.isFree &&
        [routes.pricing.path, routes.reviewsubscription.path].includes(
          location.pathname
        ) &&
        (!searchQuery.upgrade || !searchQuery.upgrade === 'true')
      ) {
        return history.push(routes.myprofilepage.path_string('subscription'))
      }
      if (
        subscription?.status === 'cancelled' &&
        checkSubscriptionExpire(subscription?.expiresOn) &&
        ![
          routes.subscriptionexpired.path,
          routes.pricing.path,
          routes.reviewsubscription.path,
        ].includes(location.pathname)
      ) {
        return history.push(routes.subscriptionexpired.path)
      }
      if (
        subscription?.status === 'pending' &&
        ![
          routes.subscriptionpending.path,
          routes.reviewsubscription.path,
        ].includes(location.pathname)
      ) {
        return history.push(routes.subscriptionpending.path)
      } else if (
        subscription?.status === 'expired' &&
        location.pathname !== routes.subscriptionexpired.path
      ) {
        return history.push(routes.subscriptionexpired.path)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [company, subscription])

  if (loading) {
    return (
      <Loader
        delay={500}
        className='center'
        size='large'
        wrapperClass='fullpage-loader'
      />
    )
  }
  return children
}

const mapStateToProps = ({ auth, company }, ownProps) => ({
  subscription: company.companySubscription,
  company: auth?.user?.company,
  companyId: auth?.user?.companyId,
  ...ownProps,
})

export default connect(mapStateToProps)(withRouter(ValidateSubscription))
