import { company } from 'store/types'

const initialState = {
  companyInfo: {},
  companySubscription: null,
  subscriptionPrompt: {
    visible: false,
    title: null,
    message: null
  },
  members: {
    rows: [],
    totalPages: 0,
    count: 0
  },
  loading: {
    updateCompany: false,
    getCompany: false,
    uploadFile: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case company.SET_COMPANY: {
      return {
        ...state,
        companyInfo: action.payload,
      }
    }
    case company.SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      }
    }
    case company.SET_COMPANY_SUBSCRIPTION: {
      return {
        ...state,
        companySubscription: action.payload,
      }
    }
    case company.SET_SUBSCRIPTION_PROMPT: {
      return {
        ...state,
        subscriptionPrompt: action.payload,
      }
    }
    case company.SET_MEMBERS: {
      return {
        ...state,
        members: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
