import store from '../index'
import { teamComments as comments } from '../types'

const initialState = {
  comments: [],
  fetchMore: true,
  commentsParams: {
    page: 1,
    perPage: 5,
  },
  loading: {
    getComments: false,
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case comments.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case comments.RESET: {
      return initialState
    }

    case comments.SET_COMMENTS: {
      const { fetchMore, loadMore } = action.payload
      let { comments } = action.payload
      if (loadMore) {
        comments = [...state.comments, ...comments]
      }
      return {
        ...state,
        comments: comments,
        fetchMore: fetchMore,
      }
    }

    case comments.ADD_COMMENT: {
      return {
        ...state,
        comments: [action.payload, ...state.comments],
      }
    }

    case comments.SET_COMMENTS_PARAMS: {
      return {
        ...state,
        commentsParams: { ...state.commentsParams, ...action.payload },
      }
    }

    case comments.DELETE_COMMENT: {
      const { commentId } = action.payload
      return {
        ...state,
        comments: state.comments.filter((i) => i.id !== commentId),
      }
    }

    case comments.EDIT_COMMENT: {
      const { id, ...rest } = action.payload

      return {
        ...state,
        comments: state.comments.map((i) => {
          if (i.id === id) {
            return { ...i, ...rest }
          }
          return i
        }),
      }
    }

    case comments.REMOVE_REACTION: {
      const { user, commentId } = action.payload
      return {
        ...state,
        comments: state.comments.map((com) => {
          if (com.id === commentId) {
            com.reactionsInfo = [
              ...com.reactionsInfo.filter((i) => i.reactorInfo.id !== user.id),
            ]
          }
          return com
        }),
      }
    }

    case comments.ADD_REACTION: {
      const { emoji, commentId, user: reactorInfo } = action.payload
      return {
        ...state,
        comments: [
          ...state.comments.map((i) => {
            if (i.id === commentId) {
              i.reactionsInfo = [
                ...i.reactionsInfo,
                {
                  emoji,
                  reactorInfo,
                },
              ]
            }
            return i
          }),
        ],
      }
    }

    default: {
      return state
    }
  }
}
export default reducer
