const lang = {
  login: {
    error: {
      userInvalid: 'Email or password not matched',
      emailRequired: 'Please enter your email!',
      passwordRequired: 'Please enter your password!',
    },
    googleSignIn: 'Continue with Google',
    appleSignIn: 'Sign in with Apple',
    facebookSingIn: 'Sign in with Facebook',
    forgotPassword: 'Forgot Password?',
    noAccount: 'Don’t have an account?',
    email: 'Email',
    password: 'Password',
  },
  signUp: {
    error: {
      firstNameRequired: 'Please enter your first name',
      lastNameRequired: 'Please enter your last name',
      emailRequired: 'Please enter your email address',
      passwordRequired: 'Please enter a password',
      passwordMin8: 'Password must contain min 8 character',
      passwordMax20: 'Password must not contain more then 20 character',
      uniqueChar:
        'Your password must contain at least one uppercase letter, one lowercase letter, one number and one special character ( $, #, @, !,%,^,&,*,(,) -)',
      mobileNumberRequired: 'Please enter your mobile number',
      validMobileNumber: 'Please enter a valid mobile number',
      charAllowedOnly: 'Please enter a valid name',
    },
    firstName: 'First Name',
    lastName: 'Last Name',
    emailAddress: 'Email Address',
    password: 'Password',
    mobileNumber: 'Mobile Number',
    sendToMail: 'Send verification to email',
    sendToMessage: 'Send verification to text message',
    min2char: 'You must enter min 2 character',
  },
  globalSearch: {
    results: 'results',
    showing: 'Showing',
    popularity: 'Popularity',
    date: 'Date',
    postType: 'Post type',
    companyType: 'Company type',
    company: 'Company',
    speciality: 'Specialty',
    surgeons: 'Surgeons',
    search: 'Search',
    viewAllResults: 'View all results',
    clear: 'Clear',
    recent: 'Recent',
    and: 'and',
    healthcareProfessionals: 'Healthcare Professionals',
    apply: 'Apply',
    other: 'other',
    mutualConnections: 'Mutual connections',
    eventType: 'Event type',
    createdBy: 'Created by',
  },
  forgotPassword: {
    enterEmailOrPhone:
      'Enter your Email or Contact Number so we can complete your verification process.',
    emailOrPhone: 'Email or Contact Number',
  },
  verification: {
    error: {
      otpExpire: 'The code you entered is incorrect or expired',
    },
    resendCode: "Didn't Recieve Code? ",
    resend: 'Resend',
    verified: 'Verified',
    resendOtp: 'Verification code has been resent successfully',
    verifyEmail: 'Verify Email address',
    otpEmail: (email) =>
      `Enter your Code sent to ${email.substring(0, 3)}***${email.slice(
        email.indexOf('@')
      )}`,
    verifyMobile: 'Verify Mobile Number',
    otpMobile: (mob) =>
      `Enter your Code sent to *** *** *${mob.slice(mob.length - 3)}`,
    successVerify: (v) =>
      `You have successfully verified your ${
        v === 'email' ? 'email address' : 'mobile number'
      }`,
  },
  directory: {
    follow: 'Follow',
    following: 'Following',
    downloadCapStatement: 'Download Capabilities Statement',
    addToCRM: 'Add to CRM',
    share: 'Share',
    more: 'More',
    aboutTitle: 'About',
    overview: 'Overview',
    details: 'Details',
    federalContracting: 'Federal Contracting',
    fedContractAwards: 'Federal Contract Awards',
    people: 'People'
  },
  accountInfoSpeciality: {
    selectSpeciality: 'Select your specialty',
  },
  accountInformation: {
    success: {
      profileUpdateSuccess:
        'Your interests are saved and we will try to provide you best besed on that',
    },
    accountInformation: 'Account Information',
    selectIntrest: 'Select your interests',
    selectMultipleIntrest:
      'Choose interest areas to customize the content you see',
    whatIsYourIntrests: 'Interest Areas:',
  },
  resetPassword: {
    enterOtp: 'Enter your Code sent to ',
    codeExpired: 'The code you entered is incorrect or expired',
    resendCode: "Didn't Recieve Code? ",
    resend: 'Resend',
    enterNewPassword: 'Please enter New Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    otpSentMobile: 'Enter your Code sent to *** *** *547',
    resetPassword: 'Reset Password',
  },
  education: {
    error: {
      scoolNameRequired: 'School name is required',
      selectYear: 'Pleaes select your graduation year',
      selectEducationType: 'Pleaes select your education type',
    },
    addEducation: 'Add Education',
    editEducation: 'Edit Education',
    educationType: 'Education Type',
    accountSetup: 'Account Setup',
    addMedicalSchool: 'Add your medical school',
    medicalSchoolName: 'Medical School Name',
    schoolPlaceholder: 'Ex:Boston High School',
    graduationYear: 'Graduation Year',
  },
  employer: {
    error: {
      hospitalRequired: 'Hospital name is required',
      locationRequired: 'Location in required',
    },
    accountSetup: 'Account Setup',
    addYourEmployer: 'Add your employer',
    hospitalName: 'Hospital name',
    hospitalPlaceholder: 'Ex:Northside Hospital',
    location: 'Location',
  },
  surgeon: {
    areYouSurgeon: 'Are you an Orthopedic Surgeon?',
  },
  network: {
    followers: 'Followers',
    following: 'Following',
    suggested: 'Suggested',
    viewAll: 'View All',
    surgeons: 'Surgeons',
    healthcareProfessionals: 'Healthcare Professionals',
    clear: 'Clear',
    apply: 'Apply',
    peopleUKnow: 'People you may know',
  },
  profileSettings: {
    success: {
      passwordChangeSuccess: 'Password Changed successfully',
      notificationUpdateSuccess:
        'Your Notification settings updated successfully',
      profileUpdateSuccess: 'Your changes saved successfully',
      copySuccess: 'Link copied successfully',
    },
    error: {
      currentPasswordRequired: 'Please enter your current password',
      newPasswordRequired: 'Please enter a new password',
      confirmPasswordRequired: 'Please confirm your entered password',
      matchWithAbove: 'Password must match with your new password',
      passwordMin8: 'Password must contain min 8 character',
      passwordMax20: 'Password must not contain more then 20 character',
      uniqueChar:
        'Your password must contain at least one uppercase letter, one lowercase letter, one number and one special character ( $, #, @, !,%,^,&,*,(,) -)',
      tagMax75: 'Tagline must not contain more then 75 character',
      firstNameRequired: 'Please enter your first name',
      lastNameRequired: 'Please enter your last name',
      mobileNumberRequired: 'Please enter your mobile number',
      validMobileNumber: 'Please enter a valid mobile number',
      primarySpecialityRequired: 'Please select a specialty',
      companyNameRequired: 'Please enter your Company name',
      companyTypeRequired: 'Please select your company type',
      companySiteRequired: 'Please enter your company web site',
      companySiteInvalid: 'Please enter a valid web address',
      min2char: 'You must enter min 2 character',
      charAllowedOnly: 'Please enter a valid name',
    },
    currentPassword: 'Current Password',
    changePassword: 'Change Password',
    confirmPassword: 'Confirm Password',
    newPassword: 'New Password',
    editProfile: 'Edit Profile',
    inviteUsers: 'Invite Users',
    managePosts: 'Manage Posts',
    notificationSettings: 'Notification Settings',
    privacyPolicy: 'Privacy Policy',
    termCondition: 'Terms & condition',
    specialty: 'Specialty',
    tagline: 'Tagline',
    basicInfo: 'Basic Info',
    firstName: 'First Name',
    lastName: 'Last Name',
    mobileNumber: 'Mobile Number',
    emailAddress: 'Email Address',
    primarySpeciality: 'Primary Specialty',
    secondarySpeciality: 'Secondary Specialty',
    otherSpeciality: 'Other Specialty interested in',
    companyInfo: 'Company Info',
    companyName: 'Company Name',
    companyType: 'Type of Company',
    companyWebsite: 'Company Website',
    intrestedSpeciality: 'Interested Specialty',
    otherSpecialityIntrested: 'Other Specialty interested in',
    intrests: 'Interests',
    facebook: 'Facebook',
    inviteFromFacebook: 'Invite users from Facebook',
    linkedin: 'Linkedin',
    inviteFromLinkedin: 'Invite users from Linkedin',
    twitter: 'Twitter',
    inviteFromTwitter: 'Invite users from Twitter',
    email: 'Email',
    inviteFromEmail: 'Invite users from Email',
    copyLink: 'Copy Link',
    inviteCopy: 'Invite users by copy the Link',
    manageUsers: 'Manage Users',
  },
  profile: {
    success: {
      editAboutSuccess: 'Your about info updated successfully',
      educationAddedSuccess: 'Education added successfully',
      educationDeleteSuccess: 'Your education data removed successfully',
      empolyeeRemoveSuccess: 'Your data removed successfully',
    },
    error: {
      aboutRequired: 'Please enter something about yourself',
      schoolRequired: 'Please enter your school name',
      degreeRequired: 'Please enter your Degree name',
      fieldOfStudyRequired: 'Please enter your field of study ',
      educationDuration: 'Please select education duration',
      titleRequired: 'Please enter your job title',
      companyNameRequired: 'Please enter the name of your company',
      locationRequired: 'Please enter your job location',
    },
    message: {
      deleteEducation: 'Are you sure you want to delete this information?',
      deleteEmployee: 'Are you sure you want to delete this information?',
    },
    viewAll: 'View All',
    noRecentActivity: 'No recent activity',
    noRecentActMessage1: 'You have no recent activity, yet! Seems',
    noRecentActMessage2: 'have a recently created account',
    accountInformation: 'Account Information',
    recentActivity: 'Recent Activity',
    followers: 'Followers',
    post: 'Post',
    about: 'About',
    messageLabel: 'Message',
    title: 'Title',
    companyName: 'Company Name',
    companyType: 'Type of Company',
    companyWebsite: 'Company Website',
    primarySpeciality: 'Primary Specialty',
    secondarySpeciality: 'Secondary Specialty',
    currentEmployer: 'Current Employer',
    editCurrentEmployer: 'Edit Current Employer',
    intrests: 'Interests',
    education: 'Education',
    yourGroups: 'Your groups',
    residency: 'Residency',
    school: 'School',
    location: 'Location',
    degree: 'Degree',
    fieldOfStudy: 'Field of study',
  },
  profileImage: {
    error: {
      maxSize25: 'Max allowed size 25mb',
    },
    success: {
      saveSuccess: 'Changes saved successfully',
    },
    removePicture: 'Remove Picture',
    uploadPicture: 'Upload Picture',
    editProfilePic: 'Edit Profile Picture',
  },
  menuItem: {
    backToHome: 'Back to home',
    signOut: 'Sign Out',
    help: 'Help',
    profileSettings: 'Profile & Settings',
    signIn: 'Sign in',
    signUp: ' Sign up',
    search: 'Search',
    viewProfile: 'View Profile',
    viewAll: 'View All',
  },
  sidebarMenu: {
    home: 'Home',
    myNetwork: 'My Network',
    myMessages: 'My Messages',
    watchVideos: 'Watch Videos',
    events: 'Events',
    polls: 'Polls',
    news: 'News',
    bookmarks: 'Bookmarks',
    contentStudio: 'Content Studio',
    groups: 'Groups',
    content: 'Content',
    people: 'People',
    pages: 'Pages',
    directory: 'Directory',
    opportunities: 'Opportunities',
    awards: 'Awards',
    organizations: 'Organizations',
  },
  news: {
    seemore: 'see more',
    headerTitle: 'News',
    viewArticle: 'View Full Coverage',
  },
  noData: {
    sorryNoResult: 'Sorry, No result found!',
    resultLookingFor: 'The result you are looking for',
    doesntExist: "doesn't seen to exist",
    woops: 'Whoops!',
    noData: 'Looks like there’s no data.',
  },
  needHelp: {
    success: {
      helpSubmitSuccess:
        'You help request has been submitted to use we will reach you in short time',
    },
    error: {
      subjectRequired: 'Please enter your issue subject',
      descriptionRequired: 'Please describe your issue',
    },
    needHelp: 'Need help?',
    subject: 'Subject',
    description: 'Subject Description',
    sendHelpRequest: 'Send Help Request',
  },
  general: {
    error: {
      somethingWentWrong:
        'Something went wrong please try again after some time',
      invalidRoute: 'You are not authorised to access this module.',
    },
    clear: 'Clear',
    apply: 'Apply',
    ortholoop: 'GovGenie',
    signIn: 'Sign In',
    delete: 'Delete',
    signUp: 'Sign Up',
    forgotPassword: 'Forgot Password',
    next: 'Next',
    unfollow: 'Unfollow',
    following: 'Following',
    follow: '+ Follow',
    cancel: 'Cancel',
    save: 'Save',
    skip: 'Skip',
    yes: 'Yes',
    message: 'Message',
    no: 'No',
    submitCaps: 'Submit',
    reset: 'Reset',
    close: 'Close',
    submit: 'Submit',
    confirm: 'Confirm',
    accountInfo: 'Account Information',
    copyRight: 'Copyright © 2020 GovGenie  •',
    terms: 'Terms',
    continue: 'Continue',
    privacyPloicy: 'Privacy Policy',
    iAgree: 'I agree to the',
    resetPassword: 'Reset Password',
    alreadyAnAccount: 'Already have an account?',
    edit: 'Edit',
    keep: 'Keep',
    otherOptions: 'Other options',
    linkedin: 'Linkedin',
    facebook: 'Facebook',
    shareOnGovGenie: 'Share on GovGenie',
    shareInAPost: 'Share in a post',
    shareInAMessage: 'Send in a message',
    share: 'Share',
    twitter: 'Twitter',
    copyLink: 'Copy Link',
    copied: 'Copied',
    whenFollow: 'You started following User',
    whenUnfollow: 'You are no longer following User',
    complete: 'Complete',
    shareMessage: `Hey there, I am using GovGenie to stay connected with Orthopaedic surgeons and Healthcare professionals, you can join the ortholoop network by easy signup. Click on the link below to download the app:
    Android: https://play.google.com/store/apps/details?id=com.orthopedicloop.app,
    iOS: https://apps.apple.com/us/app/ortholoop/id1547589019
    Web: https://app.orthopedicloop.com`,
    inviteMessage: `Hey there, I am using GovGenie to stay connected with Orthopaedic surgeons and Healthcare professionals, you can join the ortholoop network by easy signup. Click on the link below.`,
    characterLimit: '1000 character limit has reached',
    characterLimit100: '100 character limit has reached',
    itemLimit: 'Item limit reached',
    groupModalTitle: 'Select a group you are in',
    contactOrtho: 'Contact GovGenie Sales Team',
    pricingPlans: 'Pricing Plans',
    pricingContent: `See below our three plans for your Company, as well for
    Individuals`,
    companyTitle: 'Company',
    individualTitle: 'Individual',
    contactUs: 'Contact Us!',
    getStarted: 'Get Started',
    max3speciality: 'Maximum 3 specialities allowed',
    approveBtn: 'Approve',
    denyBtn: 'Deny',
    noPendingRequestTitle: 'No pending request',
    noPendingInviteTitle: 'No pending invitations',
    member: 'Member'
  },
  validations: {
    oldPasswordRequired: 'Please input your old Password!',
    newPasswordRequired: 'Please input your new password!',
    minEightChar: 'Password must contain atleast 8 characters',
    maxSixteenChar: 'Password must contain maximum 16 characters',
    passwordValid:
      'Password must contain one uppercase,one lowercase,one special character and one numeric value',
    confirmPasswordRequired: 'Please confirm your password!',
    passwordNotMatched: 'The two passwords that you entered do not match!',
    emailOrPhoneRequired: 'Email or Phone Number is required.',
    validEmailOrPhoneRequired: 'Enter Valid Email or Phone Number.',
  },
  successMessages: {
    resetMailSuccess: 'Password reset mail sent to your email id successfully ',
    passwordChangeSuccess: 'Your password changed successfully.',
    resendOTPSuccess: 'Code has been sent to your mobile no.',
  },
  post: {
    success: {
      pollCreateSuccess: 'Poll created successfully',
      commentRemoveSuccess: 'Comment has been removed successfully',
      changesSavedSuccess: 'Your changes saved successfully',
      postCreateSuccess: 'Post created successfully',
      postShareSuccess: 'Post shared successfully',
    },
    error: {
      max5Poll: 'Maximum 5 attachments allowed for a poll',
      enterPollDescription: 'Please enter poll description',
      enterPostDescription: 'Please enter post description',
      questionRequired: 'Question is required',
      fieldRequired: 'This field is required.',
      max5Post: 'Maximum 5 attachments allowed for a post',
      selectDuration: "Please select your poll's duration",
      limitReached:
        'You don’t have sufficient balance to make a post. Kindly refill your balance to continue posting awesome content.',
      limitReachedMember:
        'You don’t have sufficient balance to make a post. Kindly reach out to your company Admin for more',
    },
    message: {
      discardPoll:
        'You haven’t finished your poll yet. Are you sure you want to leave and discard your poll?',
      discardPost:
        'You haven’t finished your post yet. Are you sure you want to leave and discard your post?',
      deletePostAsk: 'Are you sure you want to delete this post?',
    },
    sharePost: 'Share Post',
    otherOptions: 'Other options',
    linkedin: 'Linkedin',
    facebook: 'Facebook',
    shareOnGovGenie: 'Share on GovGenie',
    shareInAPost: 'Share in a post',
    shareInAMessage: 'Send in a message',
    share: 'Share',
    twitter: 'Twitter',
    views: 'Views',
    comments: 'Comments',
    comment: 'Comment',
    peopleWhoReacted: 'People who reacted',
    likes: 'Likes',
    viewResults: 'View results',
    hideResults: 'Hide results',
    undo: 'Undo',
    vote: 'Vote',
    votes: 'Votes',
    copyLink: 'Copy Link',
    hidePost: 'Hide Post',
    savePost: 'Save Post',
    deletePost: 'Delete Post',
    loadMoreComments: 'Load more comments',
    viewMoreComments: 'View more comments',
    liked: 'Liked',
    like: 'Like',
    enterComment: 'Enter Comment',
    delete: 'Delete',
    edit: 'Edit',
    hide: 'Hide',
    copied: 'Copied',
    copy: 'Copy',
    startTyping: 'Start Typing ...',
    polls: 'Polls',
    myPolls: 'My Polls',
    allPolls: 'All Polls',
    clear: 'Clear',
    apply: 'Apply',
    goLiva: 'Go Live',
    describeCommentsHere: 'Start a conversation',
    selectTypeOfPost: 'Select type of post',
    postDescription: 'Post description',
    createPost: 'Create Post',
    editPost: 'Edit Post',
    discard: 'Discard',
    discardChanges: 'Discard changes',
    selectTypeOfPoll: 'Select type of poll',
    selectGroup: "Select a group you're in",
    surgeonsOnOrtholoop: 'Surgeons on GovGenie',
    anyoneOnGovGenie: 'Anyone on GovGenie',
    whoCanSeeYourPost: 'Who can see your post?',
    pollDuration: 'Poll Duration',
    addMoreOption: 'Add More Option',
    remove: 'Remove',
    yourOption: 'your option',
    option: 'Option',
    yourQuestion: 'Your question',
    upload: 'Upload',
    uploadMedia: 'Upload Media',
    hashtag: 'Hashtag',
    link: 'Link',
    addLink: 'Add link',
    pollDescription: 'Poll description',
    enterTextHere: 'Enter text here…',
    viewResult: 'View results',
    public: 'Public',
    surgeonsOnly: 'Surgeons Only',
    groupMembers: 'Group Members',
    privacySetting: 'Privacy Setting',
    postType: 'Post type',
    post: 'Post',
    save: 'Save',
    back: 'Back',
    uploading: 'Uploading',
    done: 'Done',
    createPoll: 'Create poll',
    editPoll: 'Edit poll',
    editEvent: 'Edit event',
    talkAbout: 'What do you want to talk about?',
    newPostAvailable: 'New posts available',
    buyMore: 'Buy More',
    communityMembers: 'Community Members',
  },
  event: {
    error: {
      max5event: 'Maximum 5 attachments allowed for a event',
      evnetNameRequired: 'Please enter event name',
      broadcastLinkRequired: 'Please enter broadcast link',
      validLink: 'Please enter a valid link',
      locationRequired: 'Please enter location',
      venueDetailsRequired: 'Please enter venue details',
      timezoneRequired: 'Please select your timezone',
      descriptionRequired: 'Please enter event description',
      startDateRequired: 'Please enter event start date',
      startDateNotGreaterEndDate: "Start date can't be greator then end date",
      endDateRequired: 'Please enter event end date',
      endDateNotLessStartDate: "End date can't be less then start date",
      startTimeRequired: 'Please enter event start time',
      startTimeNotGreaterEndTime:
        "Start time can't be greater then end time for the same day",
      endTimeRequired: 'Please enter event end time',
      endTimeNotLessStartTime:
        "End time can't be less then start time for the same day",
    },
    success: {
      saveSuccess: 'Changes saved successfully',
      eventDelete: 'Event removed successfully',
      eventCancel: 'Event cancelled successfully',
      invitationSent: 'Invitation sent',
      userRemove: 'Users removed successfully',
      requestApprove: 'Request approved successfully',
      requestDenied: 'Request denied successfully',
      shareSuccess: 'Event shared successfully',
    },
    message: {
      discardEvent:
        'You haven’t finished your event yet. Are you sure you want to leave and discard your event?',
      deleteEvent:
        'All the posts and conversations on the event page will be removed. Are you sure you want to delete this event? ',
      leaveEvent:
        'You will no longer be able to access the this event. Would you still like to leave this event?',
      cancelEvent:
        'All attendees will be notified that the event is cancelled. Are you sure you want to cancel this event?',
    },
    editEvent: 'Edit Event',
    createEvent: 'Create Event',
    discard: 'Discard',
    discardChanges: 'Discard changes',
    home: 'Home',
    details: 'Details',
    about: 'About',
    aboutTheEvent: 'About The Event',
    edit: 'Edit',
    create: 'Create',
    broadcastLink: 'Broadcast Link',
    venueDetails: 'Venue Details',
    attendees: 'Attendees',
    uploading: 'Uploading',
    eventName: 'Event name',
    cancel: 'Cancel',
    isOnline: 'Is this an online event?',
    isInOrtholoop: 'Is this event on Ortholoop?',
    isInGovGenie: 'Is this event on GovGenie?',
    yes: 'Yes',
    addLink: 'Add a live stream or webinar link, now or later',
    enterEventName: 'Enter event name',
    location: 'Location',
    addFloor: 'Add floor, room number, etc.',
    timezone: 'Timezone',
    selectTimeZone: 'Select time zone',
    selectIndustry: 'Select Industry',
    description: 'Description',
    addDetails: 'Add details like topics, schedule, speakers etc.',
    speciality: 'Specialty',
    industry: 'Industry',
    selectSpeciality: 'Please select a specialty',
    selectyourSpeciality: 'Select your specialties',
    eventVisiblity: 'Event visibility',
    visiblityNotChange:
      'Event visibility can’t be changed once the event is created.',
    visibleAnyone:
      'Visible to anyone on GovGenie. Anyone can choose to attend.',
    public: 'Public',
    privateEvent: 'Private event',
    visiblePrivate:
      'Visible to invitees and anyone else with the event link. Non-invitees need your approval to attend.',
    allowInvite: 'Allow attendees to invite their connections',
    peopleWithNoApproval:
      'The people they invite will not need your approval to attend',
    startDate: 'Start date',
    endDate: 'End date',
    fromDate: 'From Date',
    toDate: 'To Date',
    starttime: 'Start time',
    endTime: 'End Time',
    speakersWithInOrtholoop: 'Speakers (within Ortholoop)',
    speakersWithInGovGenie: 'Speakers (within GovGenie)',
    searchYourNetwork: 'Search Speakers',
    uploadMedia: 'Upload Media',
    uploadCoverPhoto: 'Upload Cover Photo',
    upload: 'Upload',
    otherOptions: 'Other options',
    linkedin: 'Linkedin',
    facebook: 'Facebook',
    shareOnGovGenie: 'Share on GovGenie',
    shareInAPost: 'Share in a post',
    shareInAMessage: 'Send in a message',
    share: 'Share',
    twitter: 'Twitter',
    copyLink: 'Copy Link',
    copied: 'Copied',
    onlineEvent: 'Online Event',
    deleteEvent: 'Delete Event',
    keep: 'Keep',
    attending: 'Attending',
    edit: 'Edit',
    delete: 'Delete',
    share: 'Share',
    interested: 'Interested',
    host: 'Host',
    speaker: 'Speaker',
    google: 'Google',
    outlook: 'Outlook',
    addToCalendar: 'Add to calendar',
    reportThisEvent: 'Report this event',
    goLiva: 'Go Live',
    inviteFollowers: 'Invite Followers',
    witdrawRequest: 'Witdraw Request',
    attendEvent: 'Attend Event',
    requestToAttend: 'Request to attend',
    joinLive: 'Join Live',
    more: 'More',
    leaveThisEvent: 'Leave this event?',
    leave: 'Leave',
    leaveEvent: 'Leave event',
    eventBy: 'Event By',
    online: 'Online',
    isAnAttendee: 'is an attendee',
    otherAttendee: 'other attendee',
    beTheFirst: 'Be the first to attend this event',
    and: 'and',
    eventCancelled: 'Event cancelled',
    eventEnded: 'Event ended',
    organizer: 'Organizer',
    cancelEvent: 'Cancel Event',
    editEvent: 'Edit Event',
    manageAttendees: 'Manage Attendees',
    manageEvent: 'Manage Event',
    invite: 'Invite',
    search: 'Search',
    removeAll: 'Remove All',
    done: 'Done',
    invited: 'Invited',
    requested: 'Requested',
    withdraw: 'Withdraw',
    deny: 'Deny',
    approve: 'Approve',
    pending: 'Pending',
    accepted: 'Accepted',
    speakers: 'Speakers',
    inviteYourFollowers: 'Invite your followers to the event',
    viewAll: 'View All',
    viewEvent: 'View Event',
    filter: 'Filter',
    surgeon: 'Surgeon',
    healthcareProfessionals: 'Healthcare Professionals',
    private: 'Private',
    clear: 'Clear',
    apply: 'Apply',
    sortBy: 'Sort By',
    intrestedIn: 'I’m Interested in',
    imAttending: 'I’m attending',
    allEvents: 'All events',
    events: 'Events',
    allEvent: 'All Event',
    myEvent: 'My Event',
    post: 'Post',
    surgeonsOnly: 'Surgeons Only',
    groupMembers: 'Group Members',
    talkAbout: 'What do you want to talk about?',
    attended: 'Attended',
    going: 'Going',
    intrested: 'Interested',
    noPendingRequestContent:
      'Anyone who requested to join the event will be listed here.',
    noPendingInviteContent:
      'Anyone who hasn’t responded to the event invitiation yet will be listed here.',
  },
  recentActivity: {
    allActivity: 'All Activity',
    posts: 'Posts',
    polls: 'Polls',
    events: 'Events',
    activity: 'Activity',
    liveStreamTitle: 'Live Streams',
    usersTitle: 'Users',
  },
  group: {
    groups: 'Groups',
    all_group: 'All Groups',
    my_group: 'My Groups',
    filter: 'Filter',
    creategroup: 'Create Group',
    entergroupname: 'Enter Group Name',
    validationgroupname: 'Please enter Group name',
    groupname: 'Group Name',
    description: 'Description',
    validationdescription: 'Please enter description',
    descplaceholder: 'Add details like topics, schedule, speakers etc.',
    groupvisibility: 'Group Visibility',
    groupvisibilitycreated:
      'Group visibility can’t be changed once the event is created.',
    alluser: 'All Users',
    public: 'Public',
    publicdesc: 'Visible to anyone on GovGenie. Anyone can choose to attend.',
    private: 'Private',
    privatedesc:
      'Visible to invitees and anyone else with the group link. Non-invitees need your approval to attend.',
    who_access_grp: 'Who can access the group',
    selectspeciality: 'Please select a specialty',
    selectgroup: 'Please Select Group',
    surgeonsonly: 'Surgeons Only',
    speciality: 'Specialty',
    placeholderspec: 'Select your specialties',
    mostview: 'Most Viewed',
    discardGroup:
      'You haven’t finished your group yet. Are you sure you want to leave and discard your group?',
    discardChanges: 'Discard changes',
    deletegroup: 'Delete Group',
    sharePost: 'Share Post',
    otherOptions: 'Other options',
    linkedin: 'Linkedin',
    facebook: 'Facebook',
    shareOnGovGenie: 'Share on GovGenie',
    shareInAPost: 'Share in a post',
    shareInAMessage: 'Send in a message',
    share: 'Share',
    twitter: 'Twitter',
    copyLink: 'Copy Link',
    DeleteGroupContent:
      'All the posts and Conversations on the Group will be removed. Are you sure you want to delete this group?',
    error: {
      max1post: 'Maximum 1 attachments allowed for a group',
    },
    success: {
      grouppCreateSuccess: 'Group Created Successfully',
      deleteSuccess: 'Group Deleted Successfully',
    },
    copied: 'Copied',
    joingroup: 'Join Group',
    viewGroup: 'View Group',
    leavegroup: 'Leave this Group?',
    leaveGroupContent:
      'You will no longer be able to access the this group. Would you still like to leave this group?',
    leave: 'Leave',
    groupalready: 'Groups you already joined',
    bethefirst: 'Be the first to join this group',
    pleaseselectvisibility: 'Please Select Visibility',
    surgeons: 'Surgeons',
    surgeon_access: 'Only Surgeons can access this group',
    alluser_access:
      'Surgeons and healthcare professionals can access this group',
    history: 'History',
    group_created_on: 'Group Created on',
    withdraw: 'Withdraw',
    groupShareSuccess: 'Group shared successfully',
    communityShareSuccess: 'Community shared successfully',
    typeTextMessage: 'Please Select Type and Enter Text',
    textMessage: 'Please Enter Text',
    typeMessage: 'Please Select Type',
  },
  notification: {
    new: 'New',
    markAsReed: 'Mark all as read',
    ignore: 'Ignore',
    accept: 'Accept',
    deny: 'Deny',
    notifications: 'Notifications',
  },
  liveStream: {
    liveStreamEnded:
      'Live Stream Ended. Please check back after some time for recording.',
    liveStreamEndedCreator:
      'Your live stream post would be available in sometime.',
    cancelInfo:
      'You haven’t finished your post yet. Are you sure you want to leave and discard your post?',
    typeLabel: 'Select type of live stream*',
    typeMessage: 'Please select type of live Stream',
    typeModalPlaceholder: 'Select Type of Live Stream',
    whoLabel: 'Who can see your live Stream*',
    whoMessage: 'Please Select Who can see your live Stream',
    whoModalPlaceholder: 'Privacy Setting',
    groupLabel: 'Select a group*',
    groupMessage: 'Please Select a group in which you want to go live',
    groupModalPlaceholder: 'Select a group',
    titleLabel: 'Title*',
    titleMessage: 'Please enter Title',
    titleMessageLength: 'Maximum Length of Title Should be 100.',
    titleModalPlaceholder: 'Enter Title',
    descLabel: 'Description',
    descMessage: 'Maximum Length of Description Should be 15000.',
    descModalPlaceholder: 'Enter text here…',
    commentsLabel: 'Allow comments',
    commentsText: `If you disable comments your viewers won’t be able to post comments
    on your live stream.`,
    copyLink: 'Copy Link',
    messageLink: 'Send in a message',
    twitterLink: 'Share to Twitter',
    facebookLink: 'Share to Facebook',
    linkedInLink: 'Share to LinkedIn',
    streamBlock:
      'Your mic/camera permission is blocked. Please allow permission and reload this page to continue with the stream.',
    viewerBlock: 'Audio is blocked on creator end',
  },
  stripe: {
    technicalErrorHeader: 'Payment Failed',
    technicalErrorContent:
      'Due to some techincal issue we could not process your payment. Please try after some time',
    paymentSuccessHeader: 'Payment Successful',
    paymentSuccessContent:
      'Your payment was successful. You can check your Bill History for payment receipt',
    subscriptionExists: 'Subscription is already exist!',
    orderSummary: 'Order Summary',
    cardSelectTitle: 'Credit Card',
    cardSectionTitle: 'Select Payment Information',
    cancelSubscription: 'Cancel Subscription',
    upgradeSubscription: 'Upgrade Subscription',
    purchaseDate: 'Purchase Date',
    lastPaymentDate: 'Last Payment Date',
    nextBillDate: 'Next Bill Date',
    subscriptionTitle: 'Subscription',
    details: 'Details',
    reuestReceived: 'Request Recieved',
    upgradeSubscriptionContent:
      'We have recieved your request for upgrading your subscription. Our sales team will connect with you on your registered EmailID/Mobile Number',
    cancelSubscriptionContent:
      'Are you sure you want to cancel your current subscription? You will lose access to GovGenie after the current billing cycle.',
    requestReceivedContent:
      'We have recieved your request. GovGenie Sales Team will contact you on your Registered Email-ID/Mobile Number',
    buyMore: 'Buy More',
    notAppplicable: 'N/A',
    usersProperty: 'Users',
    confirmAddonTitle: 'Confirm Purchase',
    confirmAddonContent: 'Are you sure you want to buy',
    purchaseDoneTitle: 'Purchase Successfull',
    purchaseDoneContent1: 'Your purchased of',
    purchaseDoneContent2: 'is Successfull',
    purchaseFailTitle: 'Purchase Failed',
    purchaseFailContent1: 'Your purchase of',
    purchaseFailContent2: 'Failed',
  },
  organization: {
    uploadLogo: 'Upload Logo',
    uploadCover: 'Upload Cover Photo',
    companyLabel: 'Company Page *',
    companyLabelRule: 'Please enter your page name',
    companyPlaceholder: 'GovGenie',
    urlLabel: 'GovGenie public URL *',
    urlLabelRule: 'Please enter your page url',
    urlLabelRule2: 'Space not allowed in url',
    urlPlaceholder: 'ortholoop.com/company/',
    siteLabel: 'Website*',
    siteLabelRule: 'Please enter page website',
    sitePlaceholder: `Begin with a http:// or https:// or www`,
    typeLabel: 'Type Of Company *',
    typeLabelRule: 'Please select your company type',
    typePlaceholder: 'Select company type',
    sizeLabel: 'Company Size *',
    sizeLabelRule: 'Please select your company size',
    sizePlaceholder: 'Select company size',
    taglineLabel: 'Tagline',
    taglinePlaceholder: 'Example : A family run departmental store',
    term: `I verify that I am an authorized representative of this
    organization and have the right to act on its behalf in the
    creation and management of this page. The organization and I
    agree to the additional terms for the page.`,
    history: 'History',
    pageText: 'Page Created on',
    interestHeading: 'Pages you may be interested in',
    followHeading: 'Pages you are following',
  },
  communitites: {
    message: {
      deleteResource: 'All content related to this resource will be deleted. Are you sure you want to delete?'
    },
    deleteResource: 'Are you sure you want to delete this resource?',
    communitiesTitle: 'Communities',
    viewCommunity: 'View Community',
    view: 'View',
    editCommunityTitle: 'Edit Community',
    communityNameLabel: 'Community Name',
    validationCommunityName: 'Please enter Community name',
    enterCommunityName: 'Enter Community Name',
    communityDetailsLabel: 'Community Details',
    validationCommunityDetails: 'Please enter Community Details',
    enterCommunityDetails: 'Enter Community Details',
    communityVisibilityLabel: 'Community Visibility',
    validationCommunityVisibility: 'Please Select Visibility',
    enterCommunityVisibility: 'Enter Community Visibility',
    communityVisibilityText:
      'Visibile to anyone on govgenie. Anyone can choose to join',
    communityAccessLabel: 'Who can access the Community',
    validationCommunityAccess: 'Please Select Access',
    enterCommunityAccess: 'Enter Community Access',
    communityAccessOptionAdmin: 'Company Admin',
    discardCommunityEdit:
      'Are you sure you want to leave and discard your edit?',
    all_communities: 'Discover Communities',
    my_communities: 'My Communities',
    maxAttachment: 'Maximum 1 attachments allowed for a cover',
    publicCommunity: 'Public Community',
    privateCommunity: 'Private Community',
    requestToJoin: 'Request to Join Community',
    joinCommunity: 'Join Community',
    inviteFollowers: 'Invite Followers',
    communityCreatedOn: 'Community created on',
    aboutTitle: 'About',
    whoCanPost: 'Who can post?',
    onlyAdmin: 'Only Community Admins',
    allMembers: 'All members and community admins',
    enablePosting: 'Enable the posting',
    enableEvents: 'Enable the events',
    uploadLogo: 'Upload Logo',
    uploadCover: 'Upload Cover Photo',
    uploadBackground: 'Upload Background Photo',
    publicPost: 'Public',
    communityPost: 'Community',
    shareCommunity: 'Share Community',
    manageDirectory: 'Manage Directory',
    joinSuccess: 'Community Joined Successfully',
    removeMember: 'Remove Member',
    removeMemberContent: 'Are you sure you want to remove this member?',
    removeMemberSuccess: 'Member Removed Successfully',
    requestAccept: 'User Request Accepted Successfully',
    requestDenied: 'User Request Denied Successfully',
    requestToJoinSuccess: 'Request to join community sent successfully',
    requestedToJoin: 'Join Request Send',
    communitiesAlready: 'Communities you already joined',
    bethefirstCommunity: 'Be the first to join this community',
    noPendingRequestCommunity:
      'Anyone who requested to join the community will be listed here.',
    noPendingInviteCommunity:
      'Anyone who hasn’t responded to the community invitiation yet will be listed here.',
  },
}

export default lang
