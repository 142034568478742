import React from "react";

function UpArrow() {
    return (
        <span className="anticon anticon-uparrow">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="11"
                viewBox="0 0 7 11"
            >
                <text
                    fill="#0096DC"
                    fillRule="evenodd"
                    fontFamily="LucidaGrande, Lucida Grande"
                    fontSize="13"
                    letterSpacing="-0.279"
                    transform="translate(-719 -573)"
                >
                    <tspan x="718.447" y="581">
                        ↑
                    </tspan>
                </text>
            </svg>
        </span>
    );
}
export default UpArrow;