import React from 'react'
import './NoData.scss'
import { images } from 'config/images'
import language from 'helpers/language'
import { Button } from 'antd'

function NoDataFound({ search, lang, noButton = false }) {
  return (
    <div className='NoDataFoundStyle'>
      {search ? (
        <div className='noDataBox'>
          <div className='noDataBox__img'>
            <img src={images.noresult} alt='No Data' />
          </div>
          <h1>{lang.sorryNoResult}</h1>
          {!noButton && (
            <div className='feedback-link'>
              <Button type='link' href='#' className='ant-btn-inline'>
                Be the first to post!
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className='noDataBox'>
          <div className='noDataBox__img'>
            <img src={images.nodata} alt='No Data' />
          </div>
          <h1>{lang.woops}</h1>
          <p>{lang.noData}</p>
        </div>
      )}
    </div>
  )
}

export default language(['noData'])(NoDataFound)
