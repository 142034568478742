import { history } from '../types'

const initialState = {
  history: [],
  fetchMore: true,
  historyParams: {
    page: 1,
    perPage: 10,
  },
  loading: {
    getHistory: false,
  },
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case history.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }
    case history.RESET: {
      return initialState
    }

    case history.SET_HISTORY: {
      const { fetchMore, loadMore } = action.payload
      let { history } = action.payload
      if (loadMore) {
        history = [...state.history, ...history]
      }
      return {
        ...state,
        history: history,
        fetchMore: fetchMore,
      }
    }
    case history.SET_HISTORY_PARAMS: {
      return {
        ...state,
        historyParams: { ...state.historyParams, ...action.payload },
      }
    }
    default: {
      return state
    }
  }
}
export default reducer
