import { Modal } from 'antd'
import { connect } from 'react-redux'
import warning from '../../assets/images/warning.svg'
import { triggerNoAccessModal } from 'store/action/auth'
import './ModalNoAccess.scss'

const ModalNoAccess = ({ modal, triggerNoAccessModal }) => {
  const onOk = () => {
    triggerNoAccessModal({
      visible: false,
      message: '',
    })
  }
  return (
    <Modal
      width='340px'
      className='ModalNoAccess'
      visible={modal.visible}
      onOk={onOk}
      cancelText={''}
      cancelButtonProps={{ style: { display: 'none' } }}
      closable={false}
    >
      <div className='modalpopup'>
        <div className='modalimage'>
          <img src={warning} alt='' title='' />
        </div>
        <h2>{modal.title}</h2>
        <p>{modal.message}</p>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ auth }) => ({
  modal: auth.noAccessModal,
})

export default connect(mapStateToProps, {
  triggerNoAccessModal,
})(ModalNoAccess)
