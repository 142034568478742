import { teamingPortalDocumentsType } from 'helpers/common'
import { teamingPortal } from '../types'
const teamDocParams = {}
Object.values(teamingPortalDocumentsType).forEach((t) => {
  teamDocParams[t] = { sortBy: '', sortField: '', page: 1, perPage: 10 }
})

const initialState = {
  myTeamsPinned: [],
  myTeams: [],
  myTeamCount: 0,
  myTeamPagination: {
    page: 1,
    perPage: 16,
  },
  myTeamfetchMore: true,
  allTeamsPinned: [],
  allTeams: [],
  allTeamCount: 0,
  allTeamPagination: {
    page: 1,
    perPage: 16,
  },
  allTeamfetchMore: true,
  search: '',
  isCreateTeamModalVisible: false,
  isEditOrganizationVisible: false,
  isDeleteTeamVisible: false,
  editTeamDropDown: [],
  teamDetail: null,
  teamDocParams,
  loading: {},
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case teamingPortal.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case teamingPortal.CREATE_TEAM: {
      return {
        ...state,
        myTeams: [action.payload, ...state.myTeams],
        myTeamCount: state.myTeamCount + 1,
      }
    }

    case teamingPortal.SET_ALL_TEAM_PAGINATION: {
      return {
        ...state,
        allTeamPagination: {
          ...state.allTeamPagination,
          ...action.payload,
        },
      }
    }

    case teamingPortal.SET_MY_TEAMS_PAGINATION: {
      return {
        ...state,
        myTeamPagination: {
          ...state.myTeamPagination,
          ...action.payload,
        },
      }
    }

    case teamingPortal.SET_ALL_TEAMS: {
      const { count, pinnedTeams, teams, page, perPage } = action.payload
      let fetchMore = true
      if (page * perPage >= count) {
        fetchMore = false
      }
      if (page === 1) {
        return {
          ...state,
          allTeamCount: count,
          allTeams: teams,
          allTeamsPinned: pinnedTeams,
          allTeamfetchMore: fetchMore,
        }
      }
      return {
        ...state,
        allTeamCount: count,
        allTeams: [...state.allTeams, ...teams],
        allTeamsPinned: [...state.allTeamsPinned, ...pinnedTeams],
        allTeamfetchMore: fetchMore,
      }
    }

    case teamingPortal.SET_MY_TEAMS: {
      const { count, pinnedTeams, teams, page, perPage } = action.payload
      let fetchMore = true
      if (page * perPage >= count) {
        fetchMore = false
      }
      if (page === 1) {
        return {
          ...state,
          myTeams: teams,
          myTeamsPinned: pinnedTeams,
          myTeamCount: count,
          myTeamfetchMore: fetchMore,
        }
      }
      return {
        ...state,
        myTeams: [...state.myTeams, ...teams],
        myTeamsPinned: [...state.myTeamsPinned, ...pinnedTeams],
        myTeamCount: count,
        myTeamfetchMore: fetchMore,
      }
    }

    case teamingPortal.SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
        myTeamPagination: initialState.myTeamPagination,
        allTeamPagination: initialState.allTeamPagination,
      }
    }

    case teamingPortal.EDIT_TEAM_INFO: {
      const { keyName, id, ...editInfo } = action.payload
      return {
        ...state,
        [keyName]: state[keyName].map((d) => {
          if (d.id === id) {
            return { ...d, ...editInfo }
          }
          return d
        }),
      }
    }

    case teamingPortal.EDIT_TEAM_ORG: {
      const { teamId, teamedOrganizationsInfo, adminInfo } = action.payload

      return {
        ...state,
        myTeams: state.myTeams.map((d) => {
          if (d.id === teamId) {
            return { ...d, teamedOrganizationsInfo, adminInfo }
          }
          return d
        }),
        myTeamsPinned: state.myTeamsPinned.map((d) => {
          if (d.id === teamId) {
            return { ...d, teamedOrganizationsInfo, adminInfo }
          }
          return d
        }),
        ...(state.isEditOrganizationVisible.id === teamId
          ? {
              isEditOrganizationVisible: {
                ...state.isEditOrganizationVisible,
                teamedOrganizationsInfo,
                adminInfo,
              },
            }
          : {}),
        ...(state.teamDetail.id == teamId
          ? {
              teamDetail: {
                ...state.teamDetail,
                teamedOrganizationsInfo,
                adminInfo,
              },
            }
          : {}),
      }
    }

    case teamingPortal.PIN_ALL_TEAM: {
      const { team, keyName } = action.payload
      const pinned = keyName + 'Pinned'
      return {
        ...state,
        [keyName]: state[keyName].filter((t) => t.id !== team.id),
        [pinned]: [team, ...state[pinned]],
      }
    }

    case teamingPortal.SET: {
      return { ...state, ...action.payload }
    }

    case teamingPortal.TEAM_DROPDOWN: {
      return {
        ...state,
        editTeamDropDown: action.payload,
      }
    }

    case teamingPortal.DELETE_TEAM: {
      const { teamId, keyName } = action.payload
      const countKey =
        keyName === 'myTeamsPinned' || keyName === 'myTeams'
          ? 'myTeamCount'
          : keyName === 'allTeamsPinned' || keyName === 'allTeams'
          ? 'allTeamCount'
          : ''
      return {
        ...state,
        [keyName]: state[keyName].filter((t) => t.id !== teamId),
        isDeleteTeamVisible: false,
        [countKey]: state[countKey] - 1,
      }
    }

    case teamingPortal.RESET: {
      return initialState
    }

    case teamingPortal.SET_DELETE_TEAM_VISIBLE: {
      return {
        ...state,
        isDeleteTeamVisible: action.payload,
      }
    }

    case teamingPortal.SET_TEAM_DETAIL: {
      return {
        ...state,
        teamDetail: { ...(state.teamDetail || {}), ...action.payload },
      }
    }

    case teamingPortal.SET_TEAM_DOCUMENTS: {
      const { documentType, docs, count } = action.payload
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          [documentType]: { docs, count },
        },
      }
    }

    case teamingPortal.ADD_TEAM_DOCUMENT: {
      const { documentTypes } = action.payload
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          [documentTypes]: [
            ...(state.teamDetail[documentTypes] || []),
            action.payload,
          ],
        },
      }
    }

    case teamingPortal.SET_DOC_PARAMS: {
      const { documentType, ...data } = action.payload
      return {
        ...state,
        teamDocParams: {
          ...state.teamDocParams,
          [documentType]: {
            ...(state.teamDocParams[documentType] || {}),
            ...data,
          },
        },
      }
    }
    case teamingPortal.DELETE_DOCUMENT: {
      const { documentType, documentId } = action.payload
      return {
        ...state,
        teamDetail: {
          ...state.teamDetail,
          [documentType]: state.teamDetail[documentType]?.docs?.filter(
            (t) => t.id.toString() !== documentId.toString()
          ),
        },
      }
    }

    default: {
      return state
    }
  }
}
export default reducer
