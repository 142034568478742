import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { routes } from 'routes/config'
import { getTopOpportunities } from 'store/action/opportunity'
import SectionLoader from 'components/Global/SectionLoader/SectionLoader'

import moment from 'moment'
import NoData from 'components/Global/NoData/NoData'
import { normalizeParams, truncateText } from 'helpers/common'
import './TopOpportunities.scss'

const TopOpportunities = ({ naicscode, list, count, getTopOpportunities, loading }) => {
  useEffect(() => {
    const params = {}
    if (naicscode) {
      params.naicscode = [naicscode]
    }
    getTopOpportunities(normalizeParams(params))
  }, [getTopOpportunities, naicscode])
  return (
    <div className='TopOpportunityStylesPanel'>
      <div className='panel__header'>
        <h4 className='panel__header__title'>Top Opportunities</h4>
        <Link
          className='panel__header__see-more-link'
          to={routes.opportunities.path}
        >
          See all
        </Link>
      </div>
      <div className='panel__body'>
        {loading ? (
          <Row>
            <Col span={24}>
              <SectionLoader height='100' />
            </Col>
          </Row>
        ) : (
          <>
            {list.length ? (
              <div className='panel__body__list'>
                {list.map((data, key) => (
                  <Link
                    to={routes.opportunitieDetailV2.path_string(data.id, 'federal')}
                    className='panel__body__list-item'
                    key={key}
                  >
                    <h5 className='item-name'>{truncateText(data.solicitationname, 50)}</h5>
                    <div className='item-date'>
                      <CalendarOutlined />
                      <span>
                        {data.posteddate
                          ? 'Posted Date: ' + moment(data.posteddate).format('MM/DD/YYYY')
                          : 'Posted Date: N/A'}
                      </span>
                    </div>
                    <div className='item-date'>
                      <CalendarOutlined />
                      <span>
                        {data.responsedate
                          ? 'Response Date: ' + moment(data.responsedate).format('MM/DD/YYYY')
                          : 'Response Date: N/A'}
                      </span>
                    </div>
                    {/* <hr /> */}
                  </Link>
                ))}
              </div>
            ) : (
              <NoData/>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ opportunity, auth }) => ({
  list: opportunity.topOpportunities.list,
  count: opportunity.topOpportunities.count,
  loading: opportunity.loading.getTopOpportunities,
  naicscode: auth?.user?.company?.primarynaicscode 
})

export default connect(mapStateToProps, {
  getTopOpportunities,
})(TopOpportunities)
