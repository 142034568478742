import { crmInfo } from '../types'

const initialState = {
  contact: null,
  naicsCodes: [],
  editTeamDropDown: [],
  editMemberDropDown: [],
  fetchMoreNaics: false,
  pastPerformace: [],
  pastPerformaceCount: 0,
  history: [],
  fetchMoreHistory: true,
  notes: [],
  fetchMoreNotes: true,
  tasks: [],
  fetchMoreTasks: true,
  activities: [],
  fetchMoreActivity: true,
  activeTasks: {
    task: [],
    count: 0,
  },
  activityFilters: {
    search: '',
    activityType: [],
  },
  loading: {},
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case crmInfo.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case crmInfo.SET_CRM_INFO: {
      if (!action.payload) {
        return {
          ...state,
          contact: null,
        }
      }
      return {
        ...state,
        contact: { ...state.contact, ...action.payload },
      }
    }

    case crmInfo.SET_CRM_COMPANY: {
      if (!action.payload) {
        return {
          ...state,
          contact: { ...state.contact, crmCompanyInfo: {} },
        }
      } else {
        return {
          ...state,
          contact: {
            ...state.contact,
            crmCompanyInfo: {
              ...state.contact.crmCompanyInfo,
              ...action.payload,
            },
          },
        }
      }
    }

    case crmInfo.SET_NAICS_CODES: {
      const { data, perPage } = action.payload
      return {
        ...state,
        naicsCodes: data,
        fetchMoreNaics: data.length >= perPage,
      }
    }

    case crmInfo.SET_ACTIVE_TASKS: {
      const { tasks, count } = action.payload
      return {
        ...state,
        activeTasks: {
          tasks,
          count
        }
      }
    }

    case crmInfo.SET_TEAM_DROPDOWN: {
      return {
        ...state,
        editTeamDropDown: action.payload,
      }
    }

    case crmInfo.SET_MEMBER_DROPDOWN: {
      return {
        ...state,
        editMemberDropDown: action.payload,
      }
    }

    case crmInfo.SET_PAST_PERFORMANCE: {
      return {
        ...state,
        pastPerformace: action.payload?.pastPerformances,
        pastPerformaceCount: action.payload?.count,
      }
    }

    case crmInfo.SET_CRM_HISTORY: {
      const { rows, fetchNext, cursor, last } = action.payload
      return {
        ...state,
        history: !cursor || !last ? rows : [...state.history, ...rows],
        fetchMoreHistory: fetchNext,
      }
    }

    case crmInfo.SET_CRM_NOTES: {
      const { rows, fetchNext, cursor, last } = action.payload
      return {
        ...state,
        notes: !cursor || !last ? rows : [...state.notes, ...rows],
        fetchMoreNotes: fetchNext,
      }
    }

    case crmInfo.SET_CRM_TASKS: {
      const { rows, fetchNext, cursor, last } = action.payload
      return {
        ...state,
        tasks: !cursor || !last ? rows : [...state.tasks, ...rows],
        fetchMoreTasks: fetchNext,
      }
    }

    case crmInfo.SET_CRM_ACTIVTY: {
      const { rows, fetchNext, cursor, last } = action.payload
      return {
        ...state,
        activities: !cursor || !last ? rows : [...state.activities, ...rows],
        fetchMoreActivity: fetchNext,
      }
    }

    case crmInfo.UPDATE_CRM_NOTE: {
      return {
        ...state,
        notes: state.notes.map((n) => {
          if (n?.crmNoteDetails?.id === action.payload.id) {
            return {
              ...n,
              crmNoteDetails: { ...n.crmNoteDetails, ...action.payload },
            }
          } else {
            return n
          }
        }),
        activities: state.activities.map((n) => {
          if (n?.crmNoteDetails?.id === action.payload.id) {
            return {
              ...n,
              crmNoteDetails: { ...n.crmNoteDetails, ...action.payload },
            }
          } else {
            return n
          }
        }),
      }
    }

    case crmInfo.UPDATE_CRM_TASK: {
      return {
        ...state,
        tasks: state.tasks.map((n) => {
          if (n?.crmTaskDetails?.id === action.payload.id) {
            return {
              ...n,
              crmTaskDetails: { ...n.crmTaskDetails, ...action.payload },
            }
          } else {
            return n
          }
        }),
        activities: state.activities.map((n) => {
          if (n?.crmTaskDetails?.id === action.payload.id) {
            return {
              ...n,
              crmTaskDetails: { ...n.crmTaskDetails, ...action.payload },
            }
          } else {
            return n
          }
        }),
      }
    }

    case crmInfo.DELETE_CRM_NOTE: {
      return {
        ...state,
        notes: state.notes.filter(
          (n) => n?.crmNoteDetails?.id !== action.payload
        ),
        activities: state.activities.filter(
          (n) => n?.crmNoteDetails?.id !== action.payload
        ),
      }
    }

    case crmInfo.DELETE_CRM_TASK: {
      return {
        ...state,
        tasks: state.tasks.filter(
          (t) => t?.crmTaskDetails?.id !== action.payload
        ),
        activities: state.activities.filter(
          (t) => t?.crmTaskDetails?.id !== action.payload
        ),
      }
    }

    case crmInfo.SET_ACTIVITY_FILTERS: {
      return {
        ...state,
        activityFilters: {
          ...state.activityFilters,
          ...action.payload,
        },
      }
    }

    case crmInfo.RESET: {
      return initialState
    }

    case crmInfo.RESET_ALL: {
      return initialState
    }

    default: {
      return state
    }
  }
}
export default reducer
