import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { routes } from './config';

const publicRoute = ({ auth, ...routeProps }) => {
  // if (
  //   auth.user?.id && auth.user?.id !== 'anonymous'
  // ) {
  //   return <Redirect to={routes.index.path} />
  // } else {
    
    return <Route {...routeProps} />
  // }
}

export default connect(({ auth }) => ({ auth }))(publicRoute)
