import { get, getErrorMessage } from 'api'
import ExportJsonToExcel from 'js-export-excel'
import { message } from 'helpers/message'
import { opportunity } from 'store/types'

const api = {
  federal: process.env.REACT_APP_OPPORTUNITIES_URL,
  state: process.env.REACT_APP_SL_OPPORTUNITIES_URL,
}

export const getOpportunities = (tableType, params) => async (dispatch) => {
  try {
    const opportunitiesAPI = api[tableType]
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getOpportunities: true },
    })
    const { data } = await get({ url: opportunitiesAPI, params, withCredentials: false })
    dispatch({
      type: opportunity.SET,
      payload: { opportunities: data?.data, count: data?.total },
    })
  } catch (error) {
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getOpportunities: false },
    })
  }
}

export const getDueOpportunities = ({ tableType = 'federal', params }) => async (dispatch) => {
  try {
    const opportunitiesAPI = api[tableType]
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getDueOpportunities: true },
    })
    const { data } = await get({
      url: `${opportunitiesAPI}/due-soon`,
      withCredentials: false,
      params
    })
    dispatch({
      type: opportunity.SET_DUE_OPP,
      payload: { list: data?.data, count: data?.total },
    })
  } catch (error) {
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getDueOpportunities: false },
    })
  }
}

export const getTopOpportunities = (params) => async (dispatch) => {
  try {
    const opportunitiesAPI = api.federal;
    // if (params.naicscode && params.naicscode[0] !== null) {
    //   console.log('in here')
    //   delete params.naicscode
    // }
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTopOpportunities: true },
    })
    const { data } = await get({
      url: opportunitiesAPI,
      withCredentials: false,
      params: {
        page: 1,
        perPage: 5,
        ...params,
      }
    })
    dispatch({
      type: opportunity.SET_TOP_OPPORTUNITIES,
      payload: {
        list: data.data,
        count: data.total
      }
    })
  } catch (error) {
    message.error('An error occurred while pull the top opportunities.')
    console.log('[Error in getTopOpportunities]', getTopOpportunities)
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTopOpportunities: false },
    })
  }
}

export const getOpportunityForModal = ({ id, type }) => async (dispatch, getState) => {
  try {
    // check state first
    const { opportunities } = getState().opportunity
    const foundMatch = opportunities.find(item => item.id === id)
    if (foundMatch) return foundMatch
    const opportunitiesAPI = api[type]
    const { data } = await get({ url: opportunitiesAPI, params: {
      id: JSON.stringify([id])
    }})
    return data.data[0]
  } catch (error) {
    message.error('An error occurred while retrieving opportunity data.')
  }
}

export const getOpportunity = async ({ id, type }) => {
  try {
    const opportunitiesAPI = api[type]
    const { data } = await get({
      url: opportunitiesAPI,
      withCredentials: false,
      params: {
        id: JSON.stringify([id]),
      },
    })
    return data.data[0]
  } catch (error) {
    message.error('An error occurred while retrieving opportunity data.')
  }
}

export const setParams = (params) => (dispatch) => {
  dispatch({
    type: opportunity.SET_PARAMS,
    payload: params,
  })
}

export const exportToExcel = (tableType) => (dispatch, getState) => {
  const { opportunities } = getState().opportunity
  const hasOpportunities = !!opportunities.length
  if (!hasOpportunities) return

  const option = {
    fileName: 'exported-data',
    datas: [
      {
        sheetData: opportunities,
        sheetHeader: Object.keys(opportunities[0]),
        sheetName: 'Exported data',
      },
    ],
  }
  const toExcel = new ExportJsonToExcel(option)
  toExcel.saveExcel()
}

export const setSelectedRow = (id) => (dispatch) => {
  dispatch({
    type: opportunity.SET_SELECTED,
    payload: { selected: id },
  })
}

export const openFindContractorModal = (data) => (dispatch) => {
  return dispatch({
    type: opportunity.OPEN_FIND_CONTRACTOR_MODAL,
    payload: data
  })
}

export const setOpenBidBoard = (data) => async (dispatch) => {
  if (typeof data === 'object' && data?.id) {
    const success = await checkAddToBidBoardButton({
      solicitationId: data.id,
    })
    if (success) {
      dispatch({
        type: opportunity.OPEN_ADD_TO_BIDBOARD,
        payload: data,
      })
    }
  } else {
    dispatch({
      type: opportunity.OPEN_ADD_TO_BIDBOARD,
      payload: data,
    })
  }
}
export const setTableColumns = (data) => (dispatch) => {
  dispatch({
    type: opportunity.SET_TABLE_COLUMNS,
    payload: data,
  })
}
export const openOpportunityModal = (data) => (dispatch) => {
  return dispatch({
    type: opportunity.OPEN_OPPORTUNITY_MODAL,
    payload: data,
  })
}

export const getActiveOrganizations = (params) => async (dispatch) => {
  try {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getActiveOrganizationsLoading: true },
    })
    const { data } = await get({
      subUrl: '/opportunity/organizations',
      params,
    })
    dispatch({
      type: opportunity.SET_ACTIVE_ORG,
      payload: { organizations: data.data, page: params.page },
    })
    return data?.data?.length || 0
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getActiveOrganizationsLoading: true },
    })
  }
}

export const getTeams = (params) => async (dispatch) => {
  try {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTeamsLoading: true },
    })
    const { data } = await get({ subUrl: '/opportunity/teams', params })
    dispatch({
      type: opportunity.SET_TEAMS,
      payload: data.data,
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTeamsLoading: false },
    })
  }
}
/**
 *
 * @param {{companyIds: number[]; seatch:string}} params
 */
export const getMembers = (params) => async (dispatch) => {
  try {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTeamMembers: true },
    })
    const { data } = await get({
      subUrl: '/opportunity/members',
      params: { ...params, teamIds: JSON.stringify(params.teamIds) },
    })
    dispatch({
      type: opportunity.SET_MEMBERS,
      payload: {
        members: data.data,
        allMemberIds: data.allMemberIds.map((m) => m.id),
      },
    })
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  } finally {
    dispatch({
      type: opportunity.SETLOADING,
      payload: { getTeamMembers: false },
    })
  }
}
/**
 *
 * @param {{solicitationId:number}} params
 */
export const checkAddToBidBoardButton = async (params) => {
  try {
    await get({ subUrl: '/bid/is-available', params })
    return true
  } catch (error) {
    const msg = getErrorMessage(error)
    message.error(msg)
  }
}
