import { resources } from '../types'

const initialState = {
  list: [],
  count: 0,
  fetchMore: true,
  addEditModal: false,
  loading: {},
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resources.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case resources.SET_RESOURCE: {
      const { fetchMore, resources, count, page } = action.payload
      if (page === 1) {
        return {
          ...state,
          fetchMore,
          count,
          list: resources,
        }
      }
      return {
        ...state,
        fetchMore,
        count,
        list: [...state.list, ...resources],
      }
    }

    case resources.ADD_RESOURCE: {
      return {
        ...state,
        list: [action.payload, ...state.list],
        count: state.count + 1,
        addEditModal: false,
      }
    }

    case resources.EDIT_RESOURCE: {
      const { id } = action.payload
      return {
        ...state,
        list: state.list.map((l) => {
          if (l.id === id) {
            return action.payload
          }
          return l
        }),
        addEditModal: false,
      }
    }

    case resources.OPEN_EDIT_MODAL: {
      return {
        ...state,
        addEditModal: action.payload,
      }
    }
    case resources.DELETE_RESOURCE: {
      const { resourceId } = action.payload
      return {
        ...state,
        list: state.list.filter((l) => l.id !== resourceId),
        count: state.list - 1,
      }
    }

    case resources.RESET: {
      return initialState
    }

    default: {
      return state
    }
  }
}
export default reducer
