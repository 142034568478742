import React from "react";
import { Button } from "antd";
import UserRow from "components/Global/UserRow/Followerrow";
import { GET_USER_INFO } from "helpers/common";
import language from "helpers/language";

function SuggestionItem({ users, searchGlobally, lang }) {
  return (
    <div className="SearchDropdownStyle">
      <div className="searchDropdown">
        <div className="searchDropdownBox">
          <div className="searchDropdownRecentItem">
            {users.map((v) => (
              <UserRow
                id={v.id}
                profile_image={v.userImage}
                key={v.id}
                name={`${v.firstname} ${v.lastname}`}
                text={GET_USER_INFO(v)}
              />
            ))}
          </div>
        </div>
        <div className="viewmoreResult">
          <Button type="link" onClick={() => searchGlobally()}>
            {lang.viewAllResults}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default language(["globalSearch"])(SuggestionItem);
