import React from "react";

function ShareIcon() {
  return (
    <span className="anticon ant-share">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
      >
        <path
          fill="#545B66"
          fillRule="nonzero"
          d="M1.2 15.61c.193.082.416.04.567-.104 2.566-2.478 5.94-3.894 9.55-4.019v3.643a.523.523 0 00.891.369l7.304-7.304a.521.521 0 000-.738L12.208.153a.52.52 0 00-.891.369v3.664C5.517 4.459.882 9.264.882 15.13c0 .21.126.399.318.48zM11.839 5.217a.522.522 0 00.522-.521V1.781l6.044 6.045-6.044 6.045v-2.914a.522.522 0 00-.522-.522c-3.64 0-7.087 1.223-9.838 3.467.607-4.888 4.787-8.685 9.838-8.685z"
        ></path>
      </svg> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="17"
        viewBox="0 0 20 17"
      >
        <path
          fill="transparent"
          stroke="#545B66"
          fillRule="nonzero"
          d="M1.151 16.134c.193.082.416.04.566-.105 2.567-2.478 5.941-3.894 9.551-4.019v3.644a.524.524 0 00.522.522.523.523 0 00.368-.153l7.305-7.305a.521.521 0 000-.738L12.158.676a.52.52 0 00-.89.369v3.664C5.468 4.982.833 9.787.833 15.654c0 .209.125.398.318.48z"
        ></path>
      </svg>
    </span>
  );
}

export default ShareIcon;
