import { config } from '../types'
import { routes } from 'routes/config'
import {
  BidBoardLeftSideBar,
  // Iconadminmodule,
  // Iconadmintutorials,
  Iconawards,
  // Iconbidalerts,
  // Iconcross,
  Icondirectory,
  // Iconevents,
  // Iconfavorites,
  Iconhome,
  Iconopportunities,
  Iconsaved,
  IconTeamingPortal,
  Icontrackedopportunities,
  IconArchivedLeftSideBar,
  CRMIcon,
  // Iconuser,
  // IconMessages,
  // CommunityIcon,
  DashboardIcon,
} from 'config/Icons'

const menuDrawerLinks = [
  {
    route: routes.index,
    type: 'internal',
    Icon: Iconhome,
  },
  // {
  //   route: routes.mynetwork,
  //   type: 'internal',
  //   Icon: Iconuser,
  // },
  {
    route: routes.contractordirectory,
    type: 'internal',
    Icon: Icondirectory,
  },
  {
    route: routes.opportunities,
    type: 'internal',
    Icon: Iconopportunities,
  },
  {
    route: routes.awards,
    type: 'internal',
    Icon: Iconawards,
  },
  {
    route: routes.bidboard,
    type: 'internal',
    Icon: BidBoardLeftSideBar,
  },
  // {
  //   route: routes.communityPage,
  //   Icon: CommunityIcon,
  // },
  {
    route: routes.crm,
    type: 'internal',
    Icon: CRMIcon,
  },
  {
    route: routes.dashboard,
    type: 'internal',
    Icon: DashboardIcon,
  },
  {
    route: routes.teams,
    type: 'internal',
    Icon: IconTeamingPortal,
  },
  {
    route: routes.archivedpage,
    type: 'internal',
    Icon: IconArchivedLeftSideBar,
  },
  {
    route: routes.trackedopportunities,
    type: 'internal',
    usePathString: true,
    Icon: Icontrackedopportunities,
  },
  {
    route: routes.saved,
    Icon: Iconsaved,
  },
]

const leftSidebarLinks = [
  {
    route: routes.opportunities,
    Icon: Iconopportunities,
  },
  {
    route: routes.awards,
    Icon: Iconawards,
  },
  {
    route: routes.contractordirectory,
    Icon: Icondirectory,
  },
  // {
  //   route: routes.communityPage,
  //   Icon: CommunityIcon,
  // },
]

const topNavbar = [
  {
    route: routes.index,
    type: 'internal',
    Icon: Iconhome,
  },
  {
    link: `${process.env.REACT_APP_GOVGENIE_BETA_URL}/crm`,
    name: 'CRM',
    type: 'external',
    Icon: CRMIcon,
  },
  {
    link: `${process.env.REACT_APP_GOVGENIE_BETA_URL}/bidboard`,
    name: 'Bid Board',
    type: 'external',
    Icon: BidBoardLeftSideBar,
  },
  {
    route: routes.opportunities,
    type: 'internal',
    Icon: Iconopportunities,
  },
]

const initialState = {
  menuDrawerLinks,
  leftSidebarLinks,
  topNavLinks: topNavbar,
  isColllapse: true,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case config.TOGGLE_SIDEBAR: {
      const value = action?.payload || !state.isColllapse
      return { ...state, isColllapse: value }
    }
    case config.RESET: {
      return initialState
    }
    default: {
      return state
    }
  }
}
export default reducer
