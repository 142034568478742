import { networkType } from "../types";
import { CONSTANTS } from "helpers/const";

const initialAuthState = {
  followers: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  following: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  suggestions: {
    count: 0,
    rows: [],
    totalPages: 0,
  },
  filter: null,
};

const reducer = (state = initialAuthState, action = []) => {
  switch (action.type) {
    case networkType.SET_FOLLOWERS: {
      const followers = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.followers.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      };
      return { ...state, followers };
    }
    case networkType.SET_FOLLOWING: {
      const following = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.following.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      };
      return { ...state, following };
    }
    case networkType.SET_SUGGESTION: {
      const suggestions = {
        count: action?.payload?.count || 0,
        rows:
          action.payload.page === 1
            ? [...action?.payload?.rows]
            : [...state.suggestions.rows, ...action?.payload?.rows],
        totalPages: action?.payload?.totalPages || 0,
      }; 
      return { ...state, suggestions };
    }
    case networkType.ADD_FOLLOWER: {
      if (action.payload.from === CONSTANTS.NETWORK_FROM.following) {
        const obj = Object.assign({}, state.following);
        const p = action.payload;
        return {
          ...state,
          following: {
            ...obj,
            ...{
              rows: obj.rows.map((i) =>
                i.id === p.id ? { ...i, ...{ is_following: true } } : i
              ),
            },
          },
        };
      }
      if (action.payload.from === CONSTANTS.NETWORK_FROM.follower) {
        const obj = Object.assign({}, state.followers);
        const p = action.payload;
        return {
          ...state,
          followers: {
            ...obj,
            ...{
              rows: obj.rows.map((i) =>
                i.id === p.id ? { ...i, ...{ is_following: true } } : i
              ),
            },
          },
        };
      }
      if (action.payload.from === CONSTANTS.NETWORK_FROM.suggestion) {
        const obj = Object.assign({}, state.suggestions);
        const p = action.payload;
        return {
          ...state,
          suggestions: {
            ...obj,
            ...{
              rows: obj.rows.map((i) =>
                i.id === p.id ? { ...i, ...{ is_following: true } } : i
              ),
            },
          },
        };
      }
      break;
    }
    case networkType.REMOVE_FOLLOWER: {
      if (action.payload.from === CONSTANTS.NETWORK_FROM.following)
        return {
          ...state,
          following: {
            ...state.following,
            ...{
              rows: state.following.rows.map((i) =>
                i.id === action.payload.id
                  ? { ...i, ...{ is_following: false } }
                  : i
              ),
            },
          },
        };
      if (action.payload.from === CONSTANTS.NETWORK_FROM.follower)
        return {
          ...state,
          followers: {
            ...state.followers,
            ...{
              rows: state.followers.rows.map((i) =>
                i.id === action.payload.id
                  ? { ...i, ...{ is_following: false } }
                  : i
              ),
            },
          },
        };
      if (action.payload.from === CONSTANTS.NETWORK_FROM.suggestion)
        return {
          ...state,
          suggestions: {
            ...state.suggestions,
            ...{
              rows: state.suggestions.rows.map((i) =>
                i.id === action.payload.id
                  ? { ...i, ...{ is_following: false } }
                  : i
              ),
            },
          },
        };
      break;
    }
    case networkType.SET_FILTER:
      return { ...state, filter: action.payload };
    default: {
      return state;
    }
  }
};

export default reducer;
