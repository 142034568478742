import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ExternalLinkIcon } from 'components/Icon'
import { getProfileImageUrl, GET_USER_INFO } from 'helpers/common'
import { getActiveTask } from 'store/action/crminfo'
import { getBidsAssignedToMe } from 'store/action/bid'
import { routes } from 'routes/config'
import avatarUrl from 'assets/images/userprofile.svg'
import './UserSidebarCard.scss'
const UserSidebarCard = ({ user, getActiveTask, activeTasks, activeBids, getBidsAssignedToMe }) => {
  useEffect(() => {
    getActiveTask()
    getBidsAssignedToMe()
  }, [])
  return (
    <div className='user-sidebar-card'>
      <div className='user-card'>
        <div className='user-card__header'></div>
        <div
          className='user-card__avatar'
          style={{
            backgroundImage: `url(${
              user.userImage ? getProfileImageUrl(user.userImage) : avatarUrl
            })`,
          }}
        ></div>
        <div className='user-card__name'>
          {`${user.firstname} ${user.lastname}`}
        </div>
        <a
          href={routes.companydetail.path_string(user.companyId)}
          target='_blank'
          rel='noreferrer'
        >
          <div className='user-card__company'>
            {user?.company?.companylegalname} <ExternalLinkIcon />
          </div>
        </a>
        <div className='user-card__jobtitle'>{user?.jobTitle}</div>
        <div className='user-card__info'>
          <div className='info-section'>
            <span>Active Task Assigned</span>
            <span>{activeTasks || 0}</span>
          </div>
          <div className='info-section'>
            <span>Bid Opportunities</span>
            <span>{activeBids || 0}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, crmInfo, bid }) => ({
  user: auth.user,
  activeTasks: crmInfo?.activeTasks?.count,
  activeBids: bid?.myBids?.count
})

export default connect(mapStateToProps, { getActiveTask, getBidsAssignedToMe })(UserSidebarCard)
