import firebase from 'firebase/app'
import { firebaseConfig } from '../config/env'
import "firebase/messaging";
import "firebase/auth"

firebase.initializeApp(firebaseConfig)

let _messaging = null;
if (firebase.messaging.isSupported()) {
  _messaging = firebase.messaging();
}
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

export const firebaseAuth = firebase.auth()
export const fireBase = firebase
export const messaging = _messaging
