import React, { useEffect, useState, useCallback } from "react";
import { Input, AutoComplete } from "antd";
import { Iconsearch } from 'config/Icons'
import language from "helpers/language";
import { withRouter } from "react-router-dom";
import { routes } from "routes/config";
import { connect } from "react-redux";
import { setFilter, resetFilter } from "store/action/search";
import { debounce } from "lodash";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import { getSuggestion, getLastSearch } from "store/action/search";

function SearchBox({
  lang,
  searchPage,
  history,
  setFilter,
  resetFilter,
  filter,
  users,
  getSuggestion,
  getLastSearch,
  hints,
}) {
  const [value, setValue] = useState(searchPage ? filter.search : "");
  const [search, setSearch] = useState("");
  const [searchStart, setsearchStart] = useState(false);

  // Get search users
  const getUsers = useCallback(() => {
    if (searchStart) {
      if (search === "") {
        getLastSearch();
      } else {
        getSuggestion(search);
      }
    }
  }, [getLastSearch, getSuggestion, search, searchStart]);

  useEffect(() => getUsers(), [search, searchStart, getUsers]);

  // Set filter values
  useEffect(() => setValue(filter.search), [filter]);

  // Set filter values
  useEffect(() => {
    if (!searchPage) resetFilter();
  }, [resetFilter, searchPage]);

  // Debounce search
  const handler = debounce((e) => setSearch(e.target.value), 500);

  // Set global search state value
  const searchGlobally = (val) => {
    if (val.trim() !== "") {
      resetFilter();
      setFilter({ search: val });
      if (!searchPage) {
        history.push(`${routes.search.path}?search=${val}`);
      } else {
        history.replace(`${routes.search.path}?search=${val}`);
      }
    }
  };

  return (
    <AutoComplete
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      value={value}
      listHeight={500}
      style={{ width: "100%" }}
      getPopupContainer={() => document.getElementById("searchContainer")}
      options={
        (search !== "" && users.length > 0) ||
        (search === "" && hints.length > 0)
          ? [
              {
                label: (
                  <SearchDropdown
                    searchGlobally={searchGlobally}
                    search={search}
                  />
                ),
              },
            ]
          : []
      }
    >
      <Input
        allowClear
        maxLength={1000}
        // size="large"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            searchGlobally(e.target.value);
          }
        }}
        onChange={(e) => {
          handler(e);
          setValue(e.target.value);
        }}
        onFocus={() => {
          setsearchStart(true);
          getUsers();
        }}
        placeholder={lang.search}
        prefix={<Iconsearch />}
      />
    </AutoComplete>
  );
}

const mapStateToProps = ({ search }) => ({
  filter: search.filter,
  users: search.searchDropDownUsers,
  hints: search.searchDropDownRecentSearch,
});

export default connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getSuggestion,
  getLastSearch,
})(language(["globalSearch"])(withRouter(SearchBox)));
