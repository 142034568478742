import { saveSearch } from '../types'

const initialState = {
  searches: [],
  total: 0,
  searchParams: {
    page: 1,
    perPage: 5,
    sortField: '',
    sortBy: '',
  },
  saveSearchModals: {
    inputModal: false,
    tableModal: false
  },
  isSaveSearchModalVisible: false,
  isSavedSearchTableModalVisible: false,
  loading: {},
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case saveSearch.SET_SEARCH: {
      return {
        ...state,
        searches: action?.payload?.savedSearches,
        total: action?.payload?.count,
      }
    }

    case saveSearch.SAVE_SEARCH: {
      return {
        ...state,
        searches: [...state.searches, action.payload],
        total: state.total + 1,
      }
    }

    case saveSearch.TOGGLE_MODAL: {
      return {
        ...state,
        saveSearchModals: {
          ...state.saveSearchModals,
          ...action.payload
        }
      }
    }

    case saveSearch.SET_PARAMS: {
      return {
        ...state,
        searchParams: action.payload,
      }
    }

    case saveSearch.DELETE_SEARCH: {
      return {
        ...state,
        searches: state.searches.filter((s) => s.id !== action.payload),
        total: state.total - 1,
      }
    }

    case saveSearch.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case saveSearch.RESET: {
      return initialState
    }

    case saveSearch.RESET_ALL: {
      return initialState
    }

    default: {
      return state
    }
  }
}
export default reducer
