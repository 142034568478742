import React from "react";

function HideEyeIcon() {
  return (
    <span className="anticon anticon-hideeye">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 19 18"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.779 11.944l-1.157-1.157A17.954 17.954 0 0016.34 8.76a17.47 17.47 0 00-1.97-2.275c-1.713-1.657-3.495-2.634-5.217-2.634-.427 0-.858.06-1.291.176L6.568 2.733a6.935 6.935 0 012.585-.518c2.232 0 4.366 1.17 6.354 3.094a18.944 18.944 0 012.517 3.011l.282.44-.282.441a14.46 14.46 0 01-.684.954 19.3 19.3 0 01-1.561 1.789zM1.549 0l16.364 16.364-1.157 1.157-3.508-3.508c-1.318.827-2.687 1.293-4.095 1.293-2.232 0-4.366-1.17-6.354-3.094a19.05 19.05 0 01-1.833-2.057A14.524 14.524 0 01.282 9.2L0 8.761l.282-.441c.127-.199.358-.531.684-.954.54-.7 1.154-1.401 1.833-2.057.303-.294.61-.57.92-.826L.393 1.157 1.55 0zm2.387 6.485a17.363 17.363 0 00-1.97 2.275 17.566 17.566 0 001.97 2.276c1.713 1.656 3.495 2.633 5.217 2.633.956 0 1.93-.3 2.9-.851l-1.238-1.238a3.273 3.273 0 01-4.482-4.482l-1.45-1.452c-.318.256-.633.536-.947.839zm3.58 2.275a1.637 1.637 0 002.06 1.582L7.572 8.337a1.64 1.64 0 00-.055.423z"
        ></path>
      </svg>
    </span>
  );
}

export default HideEyeIcon;
