import { directory, directoryFilter } from '../types'

const initialState = {
  contractors: [],
  total: 0,
  contactInfo: null,
  selectedContractor: null,
  isAddToCRMModalVisible: false,
  loading: {
    getHistory: false,
  },
}

/**
 *
 * @param {initialState} state
 * @param {{type:string; payload:any}} action
 * @returns {initialState}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case directory.SETLOADING: {
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    }

    case directory.SET_DIRECTORIES: {
      return {
        ...state,
        contractors: action.payload.contacts,
        total: action.payload.count,
      }
    }

    case directory.SET_SELECTED: {
      return {
        ...state,
        selectedContractor: action.payload,
      }
    }

    case directory.ADD_TO_CRM_MODAL: {
      return {
        ...state,
        isAddToCRMModalVisible: action.payload,
      }
    }

    case directory.SET_CONTACT_INFO: {
      return {
        ...state,
        contactInfo: action.payload,
      }
    }

    case directory.RESET: {
      return initialState
    }

    case directory.RESET_ALL: {
      return initialState
    }

    default: {
      return state
    }
  }
}
export default reducer
