import React from "react";

function PlusRoundIcon() {
  return (
    <span className="anticon anticon-plusround">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 22 22"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M16 10.692H6M11 15.692v-10"
        ></path>
        <path
          stroke="currentColor"
          d="M11 20.692c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
          clipRule="evenodd"
        ></path>
      </svg>
    </span>
  );
}

export default PlusRoundIcon;
